import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { Button, NavItem, NavLink, Nav, Container, Row, Col, UncontrolledTooltip } from "reactstrap";

class SimpleFooter extends React.Component {
  render() {
    return (
      <>
        <footer className="footer pt-3 pb-3">
          <Container>
            <Row className=" align-items-center justify-content-md-between">
              <Col md="6">
                <div className=" copyright">
                  © {new Date().getFullYear()}{" "} Kolpo BD
                </div>
              </Col>
              <Col md="6">
                <Nav className=" nav-footer justify-content-end">
                  <NavItem>
                    <Link className="nav-link" to={"/"}>Kolpo BD</Link>
                  </NavItem>
                  <NavItem>
                    <Link className="nav-link" to={"/news"}>Campus News</Link>
                  </NavItem>
                  <NavItem>
                    <Link className="nav-link" to="/events">Events</Link>
                  </NavItem>
                  <NavItem>
                    <Link className="nav-link" to="/questions">Q/A</Link>
                  </NavItem>
                  <NavItem>
                    <Link className="nav-link" to="/policy" className="nav-link">Privacy Policy</Link>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default SimpleFooter;
