import React from "react";
// reactstrap components
import { Container, Row, Col, Card, CardBody, Table } from "reactstrap";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import Moment from 'react-moment';
import Swal from 'sweetalert2';

import StatusImage from "components/Order/StatusImage.jsx";

class UserRequestDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order : '',
      items : []
    };
    this.itemsCall = this.itemsCall.bind(this);
    this.cancelOrder = this.cancelOrder.bind(this);
  }
  componentDidMount(){
    if(!localStorage.getItem('user_email')){
      this.props.history.push('/');
    }else{
      this.itemsCall();
    }
  }
  itemsCall(){
    this.props.loading(true);
    var user_id = localStorage.getItem('user_id');
    fetch('https://admin.kolpobd.com/api/user-request/request/'+this.props.match.params.request+'/'+user_id)
    .then(res => res.json())
    .then(
      (result) => {
        if(result==0){
          // this.props.history.push('/');
        }else{
          this.setState({
            order: result.order
          });
          this.props.loading(false);
        }
      },
      (error) => {
        // this.props.history.push('/');
      }
    );
  }
  getImageUrl(category_id){
    return 'https://admin.kolpobd.com/'+category_id;
  }
  getItemTotal(index){
    var total = this.state.items[index].selling_price * this.state.items[index].quantity;
    return '৳ '+total;
  }
  cancelOrder(id,e){
    var user_id = localStorage.getItem('user_id');
    Swal.fire({
      title: 'Are you sure?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2dce89',
      cancelButtonColor: '#ec0c38',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        fetch('https://admin.kolpobd.com/api/custom-order/cancel/'+id+'/'+user_id)
        .then(res => res.json())
        .then(
          (result) => {
            if(result==1){
              Swal.fire({
                type: 'success',
                title: 'Order Cancelled',
                showConfirmButton: false,
                timer: 1500
              });
              this.itemsCall();
            }else{
              Swal.fire({
                type: 'error',
                title: 'Something is wrong',
                text: 'Please try again',
                showConfirmButton: false,
                timer: 1500
              });
            }
          },
          (error) => {
            Swal.fire({
              type: 'error',
              title: 'Something is wrong',
              text: 'Please try again',
              showConfirmButton: false,
              timer: 1500
            });
          }
        );
      }
    });
  }
  makePayment(id,e){

  }
  render() {
    return (
      <>
        <section className="cart">
          <Container>
            <Card className="shadow">
              <CardBody>
                <Row>
                  {/* <Col md="12">
                    <StatusImage status={this.state.order.status} cancel={this.state.order.cancel} />
                  </Col> */}
                  <Col md="12">
                    <div className="order mb-3">
                      {this.state.order.approved==0 && <button className="btn btn-sm btn-danger pull-right" onClick={this.cancelOrder.bind(this,this.state.order.id)}>Cancel</button>}
                      <Link className="btn btn-sm btn-primary pull-right" to={'/products'}>Order more products</Link>
                      <Link className="btn btn-sm btn-primary pull-right" to={'/user-request'}>Request Another Product</Link>
                      <div className="order-details mt-2">
                        <span><b>Product Name: </b>{this.state.order.product_name}</span>
                        {this.state.order.description && <span><b>Description: </b>{this.state.order.description}</span>}
                        <span><b>Delivery To: </b>{this.state.order.name}</span>
                        <span><b>Contact: </b>{this.state.order.contact}</span>
                        <span><b>Address: </b>{this.state.order.address}, {this.state.order.division_name}, {this.state.order.district_name}{this.state.order.upazila_name && ', '+this.state.order.upazila_name}{this.state.order.union_name && ', '+this.state.order.union_name}</span>
                        {this.state.order.preferable_time_from && <span><b>Preferable Delivery Time: </b>{this.state.order.preferable_time_from} {this.state.order.preferable_time_to && 'to '+this.state.order.preferable_time_to}</span>}
                        <span><b>Price: </b>{this.state.order.price ? this.state.order.price : 'Pending'}</span>
                        <span><b>Delivery Charge: </b>{this.state.order.delivery_charge ? this.state.order.delivery_charge : 'Pending'}</span>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </section>
      </>
    );
  }
}

export default withRouter(UserRequestDetails);