import React from "react";
import Moment from 'react-moment';
// reactstrap components
import { Media } from "reactstrap";

class EventComments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }
  componentDidMount(){
    
  }
  getImageUrl(image){
    return 'https://admin.kolpobd.com'+image;
  }
  render() {
    return (
      <>
        <div className="comments">
          <div className="comment">
            {this.props.comments.map((comment,index) =>
              <Media key={index}>
                <Media left href="#">
                  <Media object src={this.getImageUrl(comment.image)} alt="User Image" />
                </Media>
                <Media body>
                  <Media heading>
                    {comment.created_by} <small className="pull-right"><Moment format="D MMM, YYYY" date={comment.created_at} /></small>
                  </Media>
                  {comment.comment}
                  {comment.replies.map((reply,index) =>
                    <Media key={index}>
                      <Media left href="#">
                        <Media object src={this.getImageUrl(reply.image)} alt="User Image" />
                      </Media>
                      <Media body>
                        <Media heading>
                          {reply.created_by} <small className="pull-right"><Moment format="D MMM, YYYY" date={reply.created_at} /></small>
                        </Media>
                        {reply.reply}
                      </Media>
                    </Media>
                  )}
                </Media>
              </Media>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default EventComments;