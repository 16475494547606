import React from "react";
// reactstrap components
import { Row, Col } from "reactstrap";
import { withRouter } from "react-router";

import ProductCard from "components/UserProducts/ProductCard.jsx";

class RelatedProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prevPath : this.props.location.pathname,
      products: ''
    };
  }
  componentDidMount(){
    this.itemsCall();
  }
  componentDidUpdate(){
    if(this.state.prevPath!==this.props.location.pathname){
      this.setState({
        prevPath: this.props.location.pathname
      });
      this.itemsCall();
    }
  }
  itemsCall(){
    fetch('https://admin.kolpobd.com/api/user-product/related/'+this.props.match.params.product)
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          products: result
        });
      },
      (error) => {
        
      }
    );
  }
  render() {
    return (
      <>
        {Array.from(this.state.products).map((product,index) =>
          <ProductCard item={product} key={index} />
        )}
      </>
    );
  }
}

export default withRouter(RelatedProducts);