import React from "react";
import BestSellingProductCard from "./BestSellingProductCard";
import { Link } from "react-router-dom";
import * as product from "./../../assets/js/product.js";

class BestSellingProductCards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      category: 'All Categories'
    };
  }
  componentDidMount(){
    
  }
  changeCategory(e){
    this.setState({ category: e.target.value });
  }
  getUrl(){
    if(this.state.category=='All Categories'){
      return '/best-selling/products';
    }
    return '/best-selling/products/'+product.makeSlug(this.state.category);
  }
  render() {
    return (
      <>
        <div className="best-selling-card best-selling-products">
          <h4 className="header">Best Selling Products</h4>
          <select className="form-control" onChange={this.changeCategory.bind(this)}>
            {Object.entries(this.props.bestSellings).map((category,index) =>
              <option key={index}>{category[0]}</option>
            )}
          </select>
          <div className="item-lists">
            {Object.entries(this.props.bestSellings).map((category,key) =>
              <div className={category[0]==this.state.category ? 'db' : 'd-n'} key={key}>
                {category[0]==this.state.category &&
                  <div className="items">
                    {category[1].map((item,index) =>
                      <BestSellingProductCard item={item} key={index} />
                    )}
                  </div>
                }
              </div>
            )}
            <div className="best-selling-see-more">
              <Link className="btn btn-default btn-sm" to={this.getUrl()}>See More</Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default BestSellingProductCards;