import React from "react";
import Moment from 'react-moment';
import $ from 'jquery';
import Swal from 'sweetalert2';
// reactstrap components
import { Media } from "reactstrap";

class Answer extends React.Component {
  constructor(props) {
    super(props);
  }
  getImageUrl(image){
    return 'https://admin.kolpobd.com'+image;
  }
  deleteReply(e){
    const element = e.target;
    Swal.fire({
      title: 'Are you sure?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2dce89',
      cancelButtonColor: '#ec0c38',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        fetch('https://admin.kolpobd.com/api/questions/answer/delete/'+this.props.answer.id)
        .then(res => res.json())
        .then(
          (result) => {
            if(result==1){
              Swal.fire({
                text: 'Answer Deleted',
                type: 'success',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 1500
              });
              $(element).parent().parent().parent().hide();
            }else{
              Swal.fire({
                text: 'Something is wrong',
                type: 'error',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 1500
              });
            }
          },
          (error) => {
            Swal.fire({
              text: 'Something is wrong',
              type: 'error',
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              showCancelButton: false,
              timer: 1500
            });
          }
        );
      }
    });
  }
  render() {
    return (
      <>
        <Media>
          <Media left href="#">
            <Media object src={this.getImageUrl(this.props.answer.image)} alt="User Image" />
          </Media>
          <Media body>
            <Media heading>
              {this.props.answer.name} <small className="pull-right"><Moment format="D MMM, YYYY" date={this.props.answer.created_at} /></small>
            </Media>
            {this.props.answer.answer}
            {this.props.answer.images &&
              <div className="comment-images">
                {Array.from(JSON.parse(this.props.answer.images)).map((image) =>
                  <a href={this.getImageUrl(image)} target="_blank"><img src={this.getImageUrl(image)} className="comment-image" /></a>
                )}
              </div>
            }
            <div className="actions">
              {localStorage.getItem('user_id')==this.props.answer.created_by ? 
              <a href="javascript:void(0)" onClick={this.deleteReply.bind(this)}>Delete</a> : ''}
            </div>
          </Media>
        </Media>
      </>
    );
  }
}

export default Answer;