import React from "react";
import classnames from "classnames";
// reactstrap components
import { Card, CardBody, NavItem, NavLink, Nav, TabContent, TabPane, Badge, Row, Col } from "reactstrap";
import * as product from "./../../assets/js/product.js";
import ProductComment from "components/Products/ProductComment.jsx";
import ProductReview from "components/Products/ProductReview.jsx";
import ProductReviewForm from "components/Products/ProductReviewForm.jsx";
import ProductCommentForm from "components/Products/ProductCommentForm.jsx";
import ProductCard from "components/Products/ProductCard.jsx";
import { Link } from "react-router-dom";

class ProductDetailsNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: 1,
      newReview: null,
      newComment: null
    };
    this.newReview = this.newReview.bind(this);
    this.newComment = this.newComment.bind(this);
  }
  componentDidUpdate(){
    
  }
  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };
  getFeature(feature,index){
    if(index>0){
      var previous = index-1;
      if(feature===this.props.features[previous].feature){
        return '';
      }
      return feature+': ';
    }
    return feature+': ';
  }
  newReview(result){
    if(result){
      this.setState({
        newReview: result
      });
      return <ProductReview review={result} />;
    }
    return null;
  }
  newComment(result){
    if(result){
      this.setState({
        newComment: result
      });
      return <ProductComment comment={result} />;
    }
    return null;
  }
  getDescription(){
    return {__html: this.props.description};
  }
  render() {
    return (
      <>
        <div className="nav-wrapper">
          <Nav className="nav-fill flex-column flex-md-row" id="tabs-icons-text" pills role="tablist" >
            <NavItem>
              <NavLink aria-selected={this.state.tabs === 1} className={classnames("mb-sm-3 mb-md-0", { active: this.state.tabs === 1 })} onClick={e => this.toggleNavs(e, "tabs", 1)} href="#Description" role="tab" ><i className="ni ni-align-left-2 mr-2" />Description</NavLink>
            </NavItem>
            <NavItem>
              <NavLink aria-selected={this.state.tabs === 2} className={classnames("mb-sm-3 mb-md-0", { active: this.state.tabs === 2 })} onClick={e => this.toggleNavs(e, "tabs", 2)} href="#Reviews" role="tab"><i className="ni ni-chart-bar-32 mr-2" />Reviews <Badge color="primary" pill>{this.props.reviews.length}</Badge></NavLink>
            </NavItem>
            <NavItem>
              <NavLink aria-selected={this.state.tabs === 3} className={classnames("mb-sm-3 mb-md-0", { active: this.state.tabs === 3 })} onClick={e => this.toggleNavs(e, "tabs", 3)} href="#Comments" role="tab"><i className="ni ni-chat-round mr-2" />Comments <Badge color="primary" pill>{this.props.comments.length}</Badge></NavLink>
            </NavItem>
          </Nav>
        </div>
        <Card className="shadow">
          <CardBody>
            <TabContent activeTab={"tabs" + this.state.tabs}>
              <TabPane tabId="tabs1">
                {this.props.package_items && <h6>Included Items:</h6>}
                <Row>
                  {this.props.package_items && this.props.package_items.map((item,index) =>
                    <Col md="4" lg="3" key={item.id}>
                      <ProductCard item={item} />
                    </Col>
                  )}
                </Row>
                {this.props.description && <div className="description" dangerouslySetInnerHTML={this.getDescription()}></div>}
                <table className="feature-table">
                  <tbody>
                      {Array.from(this.props.features).map((feature,index) =>
                        <tr key={index}>
                          <th className="pr-3">{this.getFeature(feature.feature,index)}</th>
                          <td><Link to={product.makeFeatureUrl(feature.feature,feature.feature_value)}>{feature.feature_value}</Link></td>
                        </tr>
                      )}
                  </tbody>
                </table>
              </TabPane>
              <TabPane tabId="tabs2">
                <div className="comments">
                  {Array.from(this.props.reviews).map((review,index) =>
                    <ProductReview review={review} key={index} />
                  )}
                  {this.state.newReview && <ProductReview review={this.state.newReview} />}
                  <ProductReviewForm product_id={this.props.product_id} newReview={this.newReview.bind()} updateRating={this.props.updateRating} />
                </div>
              </TabPane>
              <TabPane tabId="tabs3">
                <div className="comments">
                {Array.from(this.props.comments).map((comment,index) =>
                  <ProductComment comment={comment} key={index} />
                )}
                  {this.state.newComment && <ProductComment comment={this.state.newComment} />}
                  <ProductCommentForm product_id={this.props.product_id} newComment={this.newComment.bind()} />
                </div>
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </>
    );
  }
}

export default ProductDetailsNav;