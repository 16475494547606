import React from "react";
// reactstrap components
import { Row, Col } from "reactstrap";
import { withRouter } from "react-router";

import EventGallery from "components/Events/EventGallery.jsx";
import EventRead from "components/Events/EventRead.jsx";
import EventComments from "components/Events/EventComments.jsx";

class EventView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prevPath : this.props.location.pathname,
      event: '',
      images: '',
      comments: [],
    };
  }
  componentDidMount(){
    this.itemCall();
  }
  componentDidUpdate(){
    if(this.state.prevPath!==this.props.location.pathname){
      this.props.loading(true);
      this.setState({
        prevPath: this.props.location.pathname
      });
      this.itemCall();
    }
  }
  itemCall(){
    fetch('https://admin.kolpobd.com/api/events/details/'+this.props.match.params.event)
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          event: result,
          images: JSON.parse(result.images)
        });
        this.props.loading(false);
        this.commentsCall();
      },
      (error) => {
        
      }
    );
  }
  commentsCall(){
    fetch('https://admin.kolpobd.com/api/events/comments/'+this.props.match.params.event)
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          comments: result
        });
      },
      (error) => {
        
      }
    );
  }
  render() {
    return (
      <>
        <section className="product">
          <EventGallery images={this.state.images} />
          <EventRead event={this.state.event} />
          <div className="division"></div>
          <EventComments comments={this.state.comments} />
        </section>
      </>
    );
  }
}

export default withRouter(EventView);