import React from "react";
// reactstrap components
import { Container, Row, Col, Form } from "reactstrap";
import { withRouter } from "react-router";
import Swal from 'sweetalert2';
import $ from "jquery";
import * as product from "./../../../assets/js/product.js";

import CartItems from "components/Cart/CartItems.jsx";
import BillSummary from "components/Cart/BillSummary.jsx";
import DeliveryAddress from "components/Cart/DeliveryAddress.jsx";

class Items extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items : [],
      divisions : [],
      total: 0,
      charges: [],
      charge: 0,
      charge_id: null,
      store_credit: 0,
      coupon_code: '',
      coupon_discount: 0
    };
    this.itemsCall = this.itemsCall.bind(this);
    this.updateItems = this.updateItems.bind(this);
    this.getTotal = this.getTotal.bind(this);
    this.getDeliveryCharge = this.getDeliveryCharge.bind(this);
    this.setStoreCredit = this.setStoreCredit.bind(this);
    this.setCouponCode = this.setCouponCode.bind(this);
    this.removeCouponCode = this.removeCouponCode.bind(this);
    this.collapse = this.collapse.bind(this);
  }
  componentDidMount(){
    this.itemsCall();
    $('.stepper ul li:first-child .fa').click();
  }
  itemsCall(){
    this.props.loading(true);
    var cart = product.checkCart();
    fetch('https://admin.kolpobd.com/api/cart/items/'+cart)
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          items: result.items,
          divisions: result.divisions
        });
        this.getTotal();
        this.props.loading(false);
      },
      (error) => {
        
      }
    );
  }
  updateItems(items){
    this.setState({
      items: items
    });
    this.getTotal();
  }
  getTotal(){
    var total = 0;
    if(this.state.items.length>0){
      this.state.items.map((item,index) => {
        total = total + parseInt(product.getDiscountedPrice(item.max_price,item.discount)) * parseInt(item.quantity);
      });
    }
    if(total){
      this.setState({
        total: total
      });
    }else{
      this.setState({
        total: 0
      });
    }
    this.getDeliveryCharges();
  }
  getDeliveryCharges(){
    let charges = [];
    let chargesIds = [];
    if(this.state.items.length>0){
      this.state.items.map((item,index) => {
        if($('#district').val()=='Select District'){
        }else if($('#district').val()==18){
          if(item.delivery_charges && item.delivery_charges['Inside Dhaka']){
            Array.from(item.delivery_charges['Inside Dhaka']).map(function(charge,index){
              if(!chargesIds.includes(charge.id)){
                charges.push(charge);
                chargesIds.push(charge.id);
              }
            });
          }
        }else{
          if(item.delivery_charges && item.delivery_charges['Outside Dhaka']){
            Array.from(item.delivery_charges['Outside Dhaka']).map(function(charge,index){
              if(!chargesIds.includes(charge.id)){
                charges.push(charge);
                chargesIds.push(charge.id);
              }
            });
          }
        }
      });
    }
    this.setState({
      charges: charges
    },()=>{
      this.getDeliveryCharge();
    });
  }
  getDeliveryCharge(){
    let charge = 0;
    let charge_id = null;
    if($('input[name="charge"]:checked').val()){
      charge = $('input[name="charge"]:checked').val();
      charge_id = $('input[name="charge"]:checked').closest('tr').find('.delivery-charge-id').html();
    }
    this.setState({
      charge: charge,
      charge_id: charge_id
    });
  }
  validate(){
    var valid = 1;
    if($('#name').val()){
      $('#name').removeClass('is-invalid');
      $('#name').next().hide();
    }else{
      $('#name').addClass('is-invalid');
      $('#name').next().show();
      $('#name').focus();
      valid = 0;
    }
    if($('#contact').val()){
      $('#contact').removeClass('is-invalid');
      $('#contact').next().hide();
    }else{
      $('#contact').addClass('is-invalid');
      $('#contact').next().show();
      $('#contact').focus();
      valid = 0;
    }
    if($('#division').val()!="Select Division"){
      $('#division').removeClass('is-invalid');
      $('#division').next().hide();
    }else{
      $('#division').addClass('is-invalid');
      $('#division').next().show();
      $('#division').focus();
      valid = 0;
    }
    if($('#district').val()!="Select District"){
      $('#district').removeClass('is-invalid');
      $('#district').next().hide();
    }else{
      $('#district').addClass('is-invalid');
      $('#district').next().show();
      $('#district').focus();
      valid = 0;
    }
    if($('#address').val()){
      $('#address').removeClass('is-invalid');
      $('#address').next().hide();
    }else{
      $('#address').addClass('is-invalid');
      $('#address').next().show();
      $('#address').focus();
      valid = 0;
    }
    if(valid==0){
      $('.fa-truck').click();
    }
    return valid;
  }
  setStoreCredit(amount){
    this.setState({
      store_credit: amount
    });
  }
  setCouponCode(code){
    var user_id = localStorage.getItem('user_id');
    if(user_id){
      fetch('https://admin.kolpobd.com/api/cart/check-coupon/'+localStorage.getItem('cart')+'/'+code+'/'+user_id)
      .then(res => res.json())
      .then(
        (result) => {
          if(result==0){
            this.setState({
              coupon_code: ''
            },()=>{
              this.getCouponDiscount();
            });
            $('#couponCode').addClass('is-invalid');
            $('#couponCode').removeClass('is-valid');
            $('#invalid-span').removeClass('d-n');
            $('#max-span').addClass('d-n');
          }else if(result==2){
            this.setState({
              coupon_code: ''
            },()=>{
              this.getCouponDiscount();
            });
            $('#couponCode').addClass('is-invalid');
            $('#couponCode').removeClass('is-valid');
            $('#invalid-span').addClass('d-n');
            $('#max-span').removeClass('d-n');
          }else{
            this.setState({
              coupon_code: result
            },()=>{
              this.getCouponDiscount();
            });
            $('#couponCode').addClass('is-valid');
            $('#couponCode').removeClass('is-invalid');
          }
        },
        (error) => {
          
        }
      );
    }else{
      $('#loginModalBtn').click();
    }
  }
  removeCouponCode(){
    this.setState({
      coupon_code: '',
      coupon_discount: 0
    },()=>{
      $('#couponCode').val('');
      $('#couponCode').removeClass('is-invalid');
      $('#couponCode').removeClass('is-valid');
    });
  }
  getCouponDiscount(){
    if(this.state.coupon_code!=''){
      if(this.state.coupon_code.type=='Fixed'){
        this.setState({
          coupon_discount: Math.round(this.state.coupon_code.ammount)
        });
      }else{
        let coupon_discount = Math.round(this.state.coupon_code.ammount/100*this.state.total);
        if(this.state.coupon_code.upto>0 && coupon_discount > this.state.coupon_code.upto){
          coupon_discount = this.state.coupon_code.upto;
        }
        this.setState({
          coupon_discount: coupon_discount
        });
      }
    }else{
      this.setState({
        coupon_discount: 0
      });
    }
  }
  submit(e){
    e.preventDefault();
    var valid = this.validate();
    var cart = product.checkCart();
    var user_id = localStorage.getItem("user_id");
    if(user_id>0){
      const data = new FormData(e.target);
      data.append('cart',cart);
      data.append('user_id',user_id);
      data.append('delivery_charge',this.state.charge);
      data.append('delivery_charge_id',this.state.charge_id);
      data.append('store_credit',this.state.store_credit);
      data.append('coupon_code_discount',this.state.coupon_discount);
      if(this.state.coupon_code!=''){
        data.append('coupon_code',this.state.coupon_code.name);
        data.append('coupon_code_id',this.state.coupon_code.id);
      }else{
        data.append('coupon_code','');
        data.append('coupon_code_id','');
      }
      if(valid>0){
        if($('input[name="charge"]:checked').length>0){
          $('.delivery-charge-table').removeClass('table-danger');
          $('.select-delivery-feedback').addClass('d-none');
          fetch('https://admin.kolpobd.com/api/order/add', {
            method: 'POST',
            body: data,
          }).then(res => res.json())
          .then(
            (result) => {
              Swal.fire({
                text: 'Order Placed Successfully',
                type: 'success',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 1500,
                customClass: {
                  container: 'toast-container'
                }
              });
              localStorage.setItem('user_store_credit',result.store_credit);
              $.get('https://admin.kolpobd.com/mail/customerOrder/'+result.order_id,function(data){
                console.log(data);
              }).fail(function(error){
                console.log(error);
              });
              this.props.history.push('/order/'+result.order_id);
            },
            (error) => {
              alert('error');
            }
          );
        }else{
          $('.delivery-charge-table').addClass('table-danger');
          $('.select-delivery-feedback').removeClass('d-none');
          window.scrollTo(0,0);
        }
      }
    }else{
      $('#loginModalBtn').click();
    }
  }
  collapse(item,e){
    if(!$(e.target).closest('li').hasClass('active')){
      $(e.target).closest('li').removeClass('complete').addClass('active');
      $(e.target).closest('li').prevAll('li').removeClass('active').addClass('complete');
      $(e.target).closest('li').nextAll('li').removeClass('active').removeClass('complete');

      $('.step-item').hide();
      $(item).show();
      window.scrollTo(0,0);
    }
  }
  render() {
    return (
      <>
        <section className="cart">
          <Container>
            <Form onSubmit={this.submit.bind(this)}>
              <Row>
                <Col md="12">
                  {/* <h5 className="text-center"><b><u>My Cart</u></b></h5> */}
                  <div className="stepper">
                    <ul>
                      <li>
                        <i className="fa fa-list" onClick={this.collapse.bind(this,'#itemsTable')}></i>
                        <p>Cart Items</p>
                      </li>
                      <li>
                        <i className="fa fa-truck" onClick={this.collapse.bind(this,'#deliveryAddressDiv')}></i>
                        <p>Delivery Address</p>
                      </li>
                      <li>
                        <i className="fa fa-dollar" onClick={this.collapse.bind(this,'#billSummaryDiv')}></i>
                        <p>Bill Summary</p>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col md="12">
                  <CartItems items={this.state.items} updateItems={this.updateItems} collapse={this.collapse} />
                </Col>
                <Col md="12">
                  {this.state.items.length>0 && <DeliveryAddress divisions={this.state.divisions} getTotal={this.getTotal} collapse={this.collapse} />}
                </Col>
                <Col md="12">
                  <BillSummary total={this.state.total} charge={this.state.charge} length={this.state.items.length} setStoreCredit={this.setStoreCredit} setCouponCode={this.setCouponCode} removeCouponCode={this.removeCouponCode} store_credit={this.state.store_credit} coupon_code={this.state.coupon_code} coupon_discount={this.state.coupon_discount} collapse={this.collapse} charges={this.state.charges} getTotal={this.getTotal} />
                </Col>
              </Row>
            </Form> 
          </Container>
        </section>
      </>
    );
  }
}

export default withRouter(Items);