import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/argon-design-system-react.scss";
import "assets/css/custom.css";
import "assets/css/searchbar.css";
import "assets/css/ribbon.css";
import "assets/css/mega_menu.css";
import "assets/css/product-card.css";

import Index from "views/index/Index.jsx";
import Category from "views/category/Category.jsx";
import Feature from "views/feature/Feature.jsx";
import Product from "views/product/Product.jsx";
import Cart from "views/cart/Cart.jsx";
import Order from "views/order/Order.jsx";
import UserRequest from "views/userrequest/UserRequest.jsx";
import UserRequestItem from "views/userrequestitem/UserRequestItem.jsx";
import CustomOrder from "views/customorder/CustomOrder.jsx";
import CustomOrderItem from "views/customorderitem/CustomOrderItem.jsx";
import Sell from "views/sell/Sell.jsx";
import UserProducts from "views/userproducts/UserProducts.jsx";
import UserProduct from "views/userproduct/Product.jsx";
import Profile from "views/user/Profile.jsx";
import Orders from "views/user/Orders.jsx";
import News from "views/news/News.jsx";
import NewsDetails from "views/news/NewsDetails.jsx";
import Events from "views/events/Events.jsx";
import EventDetails from "views/events/EventDetails.jsx";
import Questions from "views/questions/Questions.jsx";
import Blog from "views/blog/Blog.jsx";
import Policy from "views/policy/Policy.jsx";
import Search from "views/search/Search.jsx";
import ResetPassword from "views/user/ResetPassword.jsx";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/" exact component={Index} />

      <Route path="/products" exact component={Category} />} />
      <Route path="/products/:category" exact component={Category} />} />
      <Route path="/products/:category/:sub_category" exact component={Category} />} />
      <Route path="/products/:category/:sub_category/:sub_sub_category" exact component={Category} />} />
      <Route path="/products/:category/:sub_category/:sub_sub_category/:sub_sub_sub_category" exact component={Category} />} />
      <Route path="/products/:category/:sub_category/:sub_sub_category/:sub_sub_sub_category/:sub_sub_sub_sub_category" exact component={Category} />} />
      <Route path="/products/:category/:sub_category/:sub_sub_category/:sub_sub_sub_category/:sub_sub_sub_sub_category/:sub_sub_sub_sub_sub_category" exact component={Category} />} />
      
      <Route path="/product/:product" exact component={Product} />} />

      <Route path="/feature/:feature/:feature_value" exact component={Category} />} />
      <Route path="/feature/:feature" exact component={Feature} />} />

      <Route path="/best-selling/:type" exact component={Category} />} />
      <Route path="/best-selling/:type/:category" exact component={Category} />} />
      <Route path="/best-selling-feature/:feature" exact component={Feature} />} />

      <Route path="/cart" exact component={Cart} />} />
      <Route path="/order/:order" exact component={Order} />} />
      
      <Route path="/user-request" exact component={UserRequest} />} />
      <Route path="/user-request/:request" exact component={UserRequestItem} />} />

      <Route path="/custom-order" exact component={CustomOrder} />} />
      <Route path="/custom-order/:order" exact component={CustomOrderItem} />} />

      <Route path="/sell" exact component={Sell} />} />
      <Route path="/old-stuffs" exact component={UserProducts} />} />
      <Route path="/old-stuffs/:category" exact component={UserProducts} />} />
      <Route path="/old-stuffs/:category/:sub_category" exact component={UserProducts} />} />

      <Route path="/old-stuff/:product" exact component={UserProduct} />} />

      <Route path="/profile" exact component={Profile} />} />
      <Route path="/payments" exact component={Orders} />} />
      <Route path="/order/:id" exact component={Index} />} />

      <Route path="/news" exact component={News} />} />
      <Route path="/news/:news" exact component={NewsDetails} />} />

      <Route path="/events" exact component={Events} />} />
      <Route path="/event/:event" exact component={EventDetails} />} />

      <Route path="/questions" exact component={Questions} />} />
      
      <Route path="/blog" exact component={Blog} />} />

      <Route path="/policy" exact component={Policy} />} />

      <Route path="/reset-password/:token" exact component={ResetPassword} />} />

      <Route path="/search/:query" exact component={Search} />} />

      <Redirect to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
