import React from "react";

class Loading extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }
  componentDidMount(){
    
  }
  render() {
    return (
      <>
        <div className="loading">
          <div className="loading-overlay">
          </div>
          <img className="loading-img heartbeat" src={require("assets/img/brand/favicon.png")} alt="Loading" />
        </div>
      </>
    );
  }
}

export default Loading;