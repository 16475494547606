import React from "react";
import axios from "axios";
// reactstrap components
import { Row, Col, Form, Button, Input, Label, FormGroup } from "reactstrap";
import { withRouter } from "react-router";
import $ from 'jquery';
import Swal from 'sweetalert2';

class SellForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      subCategories: [],
      filteredSubCategories: [],
      campuses: [],
      divisions: [],
      districts: [],
      upazilas: [],
      unions: [],
      campus_id: 0,
      division_id: 0,
      district_id: 0,
      upazila_id: 0,
      union_id: 0,
      name: '',
      category_id: 0,
      sub_category_id: 0,
      price: '',
      contact: '',
      nameHasDanger: false,
      categoryHasDanger: false,
      priceHasDanger: false,
      contactHasDanger: false,
      filesHasDanger: false,
      divisionHasDanger: false,
      districtHasDanger: false,
      files: []
    };
    this.itemCall = this.itemCall.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount(){
    this.itemCall();
  }
  itemCall(){
    fetch('https://admin.kolpobd.com/api/user-products/categories')
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          categories: result.categories,
          subCategories: result.sub_categories,
          campuses: result.campuses,
          divisions: result.divisions,
        });
        this.props.loading(false);
      },
      (error) => {
        
      }
    );
  }
  district(e){
    this.setState({
      division_id: e.target.value
    });
    fetch('https://admin.kolpobd.com/api/geo/district/'+e.target.value)
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          districts: result,
          district_id: 0,
          upazila_id: 0,
          union_id: 0
        });
      },
      (error) => {
        
      }
    );
  }
  upazila(e){
    this.setState({
      district_id: e.target.value
    });
    fetch('https://admin.kolpobd.com/api/geo/upazila/'+e.target.value)
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          upazilas: result,
          upazila_id: 0,
          union_id: 0
        });
      },
      (error) => {
        
      }
    );
  }
  union(e){
    this.setState({
      upazila_id: e.target.value
    });
    fetch('https://admin.kolpobd.com/api/geo/union/'+e.target.value)
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          unions: result,
          union_id: 0
        });
      },
      (error) => {
        
      }
    );
  }
  handleImages= e => { this.setState({ files: e.target.files }); }
  handleName= e => { this.setState({ name: e.target.value }); }
  handleCategory= e => {
    var category_id = e.target.value;
    var subCategories = [];
    if(this.state.subCategories.length>0){
      Array.from(this.state.subCategories).map(function(sub_category,index){
        if(sub_category.category_id==category_id){
          subCategories.push(sub_category);
        }
      });
    }
    this.setState({ category_id: category_id, filteredSubCategories: subCategories });
  }
  handlePrice= e => { this.setState({ price: e.target.value }); }
  handleContact= e => { this.setState({ contact: e.target.value }); }
  isValid(){
    if(this.state.name!='' && this.state.price!='' && this.state.contact!='' && this.state.category_id>0 && this.state.files.length>0 && this.state.division_id>0 && this.state.district_id>0){
      return true;
    }else{
      if(this.state.name==''){ this.setState({ nameHasDanger: true }); }else{ this.setState({ nameHasDanger: false }); }
      if(this.state.category_id<1){ this.setState({ categoryHasDanger: true }); }else{ this.setState({ categoryHasDanger: false }); }
      if(this.state.price==''){ this.setState({ priceHasDanger: true }); }else{ this.setState({ priceHasDanger: false }); }
      if(this.state.contact==''){ this.setState({ contactHasDanger: true }); }else{ this.setState({ contactHasDanger: false }); }
      if(this.state.files.length==0){ this.setState({ filesHasDanger: true }); }else{ this.setState({ filesHasDanger: false }); }
      if(this.state.division_id<1){ this.setState({ divisionHasDanger: true }); }else{ this.setState({ divisionHasDanger: false }); }
      if(this.state.district_id<1){ this.setState({ districtHasDanger: true }); }else{ this.setState({ districtHasDanger: false }); }
    }
    return false;
  }
  handleSubmit(e){
    e.preventDefault();
    if(this.isValid()){
      var user_id = localStorage.getItem("user_id");
      if(user_id>0){
        this.props.loading(true);
        const data = new FormData(e.target);
        Array.from(this.state.files).map((file) => {
          data.append('images[]', file);
        });
        axios.post("https://admin.kolpobd.com/api/user-products/store", data, {
        }).then(res => {
          Swal.fire({
            type: 'success',
            title: 'Product added succesfully. Please wait for approval',
            showConfirmButton: true
          });
          this.setState({
            districts: [],
            upazilas: [],
            unions: [],
            filteredSubCategories: [],
            campus_id: 0,
            division_id: 0,
            district_id: 0,
            upazila_id: 0,
            union_id: 0,
            name: '',
            category_id: 0,
            sub_category_id: 0,
            price: '',
            contact: '',
            files: [],
            nameHasDanger: false,
            categoryHasDanger: false,
            priceHasDanger: false,
            contactHasDanger: false,
            filesHasDanger: false,
            divisionHasDanger: false,
            districtHasDanger: false,
          });
          $('#addForm').trigger('reset');
          this.props.loading(false);
        });
      }else{
        $('#loginModalBtn').click();
      }
    }
  }
  render() {
    return (
      <>
        <div className="sell">
          <h6 className="heading">Have anything spare that you don't need anymore? Sell it here</h6>
          <Form onSubmit={this.handleSubmit} className="form-with-label" id="addForm">
            <Row>
              <Col sm="12" lg="12">
                <FormGroup className={''+(this.state.nameHasDanger && 'has-danger')}>
                  <Label for="name">Name</Label>
                  <Input name="name" id="name" placeholder="Name" type="text" onChange={this.handleName} className={''+(this.state.nameHasDanger && 'is-invalid')} />
                </FormGroup>
              </Col>
              <Col sm="12" lg="12">
                <FormGroup>
                  <Label for="description">Description</Label>
                  <Input type="textarea" className="form-control" name="description" id="description" placeholder="Description"></Input>
                </FormGroup>
              </Col>
              <Col sm="6" lg="3">
                <FormGroup className={''+(this.state.priceHasDanger && 'has-danger')}>
                  <Label for="price">Price</Label>
                  <Input name="price" id="price" placeholder="Price" type="number" min="0" className={''+(this.state.priceHasDanger && 'is-invalid')} onChange={this.handlePrice} />
                </FormGroup>
              </Col>
              <Col sm="6" lg="3">
                <FormGroup className={''+(this.state.contactHasDanger && 'has-danger')}>
                  <Label for="contact">Contact</Label>
                  <Input name="contact" id="contact" placeholder="Contact" type="text" className={''+(this.state.contactHasDanger && 'is-invalid')} onChange={this.handleContact} />
                </FormGroup>
              </Col>
              <Col sm="6" lg="3">
                <FormGroup className={''+(this.state.categoryHasDanger && 'has-danger')}>
                  <Label for="category">Category</Label>
                  <Input type="select" name="category_id" id="category" defaultValue={this.state.category_id} onChange={this.handleCategory} className={''+(this.state.categoryHasDanger && 'is-invalid')} >
                    <option value="0">Select Category</option>
                    {this.state.categories && Array.from(this.state.categories).map((category,index) =>
                      <option value={category.id} key={index}>{category.name}</option>
                    )}
                  </Input>
                </FormGroup>
              </Col>
              {this.state.filteredSubCategories.length>0 &&
              <Col sm="6" lg="3">
                <FormGroup>
                  <Label for="sub_category_id">Sub-Category (If Applicable)</Label>
                  <Input type="select" name="sub_category_id" id="sub_category_id" defaultValue={this.state.sub_category_id} >
                    <option value="0">Select Sub-Category</option>
                    {Array.from(this.state.filteredSubCategories).map((category,index) =>
                      <option value={category.id} key={index}>{category.name}</option>
                    )}
                  </Input>
                </FormGroup>
              </Col>
              }
              <Col sm="6" lg="6">
                <FormGroup className={''+(this.state.filesHasDanger && 'has-danger')}>
                  <Label for="images">Images</Label>
                  <Input type="file" onChange={this.handleImages} className={'form-control '+(this.state.filesHasDanger && 'is-invalid')} multiple />
                </FormGroup>
              </Col>
              <Col sm="6" lg="6">
                <FormGroup>
                  <Label for="campus_id">Campus</Label>
                  <Input type="select" name="campus_id" id="campus_id" defaultValue={this.state.campus_id}>
                    <option value="0">Select Campus</option>
                    {this.state.campuses && Array.from(this.state.campuses).map((item,index) =>
                      <option value={item.id} key={index}>{item.name}</option>
                    )}
                  </Input>
                </FormGroup>
              </Col>
              <Col sm="6" lg="3">
                <FormGroup className={''+(this.state.divisionHasDanger && 'has-danger')}>
                  <Label for="division">Division</Label>
                  <Input type="select" name="division" id="division" className={''+(this.state.divisionHasDanger && 'is-invalid')} onChange={this.district.bind(this)} defaultValue={this.state.division_id}>
                    <option value="0">Select Division</option>
                    {Array.from(this.state.divisions).map((item,index) =>
                      <option value={item.id} key={index}>{item.name_eng}</option>
                    )}
                  </Input>
                </FormGroup>
              </Col>
              <Col sm="6" lg="3">
                <FormGroup className={''+(this.state.districtHasDanger && 'has-danger')}>
                  <Label for="district">District</Label>
                  <Input type="select" name="district" id="district" className={''+(this.state.districtHasDanger && 'is-invalid')} onChange={this.upazila.bind(this)} defaultValue={this.state.district_id}>
                    <option value="0">Select District</option>
                    {Array.from(this.state.districts).map((item,index) =>
                      <option value={item.id} key={index}>{item.name_eng}</option>
                    )}
                  </Input>
                </FormGroup>
              </Col>
              <Col sm="6" lg="3">
                <FormGroup>
                  <Label for="upazila">Upazila</Label>
                  <Input type="select" name="upazila" id="upazila" onChange={this.union.bind(this)} defaultValue={this.state.upazila_id}>
                    <option value="0">Select Upazila</option>
                    {Array.from(this.state.upazilas).map((item,index) =>
                      <option value={item.id} key={index}>{item.name_eng}</option>
                    )}
                  </Input>
                </FormGroup>
              </Col>
              <Col sm="6" lg="3">
                <FormGroup>
                  <Label for="union">Union</Label>
                  <Input type="select" name="union" id="union" defaultValue={this.state.union_id}>
                    <option value="0">Select Union</option>
                    {Array.from(this.state.unions).map((item,index) =>
                      <option value={item.id} key={index}>{item.name_eng}</option>
                    )}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <div className="text-center">
              <Input type="hidden" name="id" value={localStorage.getItem('user_id')} />
              <Button className="my-4" color="primary" type="submit">Submit</Button>
            </div>
          </Form>
        </div>
      </>
    );
  }
}

export default withRouter(SellForm);