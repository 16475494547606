import React from "react";
import { withRouter } from "react-router";

class ShippingCostText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }
  check(item){
    console.log(item);
  }
  render() {
    return (
      <>
        <div className="products related mb-5">
          <div className="products-heading">
            <span>Shipping Cost</span>
          </div>
          <div className="products-shipping-cost">
            {this.props.deliveryCharges && Object.entries(this.props.deliveryCharges).map((deliveryCharge,index)=>
              <div key={index}>
                <span className="location pb-1">{deliveryCharge[0]}</span>
                {Array.from(deliveryCharge[1]).map((charge,key)=>
                  <span className={"charge "+(key==deliveryCharge[1].length-1 ? "pb-3" : "pb-1")} key={key}><i className="fa fa-truck pr-4"></i>{charge.name} ({charge.time})<span className="amount">৳ {charge.ammount}</span></span>
                )}
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(ShippingCostText);