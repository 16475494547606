import React from "react";
// reactstrap components
import { Container, Row, Col, Card, CardBody, Form, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Input, Button, Alert } from "reactstrap";
import classnames from "classnames";
import DotLoader from 'react-spinners/DotLoader';
// core components
import Navbar from "components/Navbars/Navbar.jsx";
import Loading from "components/Navbars/Loading.jsx";
import Footer from "components/Footers/SimpleFooter.jsx";
import $ from 'jquery';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      spinning: false,
      valid: false,
      resetSuccess: false
    };
    this.checkValidation = this.checkValidation.bind(this);
    this.handleForgotPassword = this.handleForgotPassword.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }
  isLoading = (loadingVal) => {
    this.setState({
      loading: loadingVal
    });
  }
  componentDidMount(){
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
    this.checkValidation();
  }
  checkValidation(){
    fetch('https://admin.kolpobd.com/api/checkResetToken/'+this.props.match.params.token)
    .then(res => res.json())
    .then(
      (result) => {
        if(result==1){
            this.setState({
                valid: true,
            },()=>{
                this.isLoading(false);
            });
        }else{
            this.setState({
                valid: false,
            },()=>{
                this.isLoading(false);
            });
        }
      },
      (error) => {
        this.setState({
            valid: false,
        },()=>{
            this.isLoading(false);
        });
      }
    );
  }
  handleForgotPassword(e){
    e.preventDefault();
    this.setState({
        spinning: true
    });
    const data = new FormData(e.target);
    fetch('https://admin.kolpobd.com/api/sendPasswordLink', {
      method: 'POST',
      body: data,
    }).then(res => res.json())
    .then(
      (result) => {
        if(result==1){
          alert('A link has sent to your email. Please check to reset your password');
        }else if(result='not exist'){
          alert('Email is not registered. Please register');
          $('#loginModalBtn').click();
        }else{
          alert('Something is wrong. Please Send Reset Link Again');
        }
        this.setState({
            spinning: false
        });
      },
      (error) => {
        alert('Something is wrong. Please Send Reset Link Again');
        this.setState({
            spinning: false
        });
      }
    );
  }
  handleReset(e){
    e.preventDefault();
    if($('#password').val()==$('#confirm_password').val()){
        this.setState({
            spinning: true
        });
        const data = new FormData(e.target);
        fetch('https://admin.kolpobd.com/api/resetPassword', {
          method: 'POST',
          body: data,
        }).then(res => res.json())
        .then(
          (result) => {
            if(result=='expired'){
                this.setState({
                    spinning: false,
                    valid: false
                });
            } if(result=='not exist'){
                this.setState({
                    spinning: false
                });
                alert('Email is not registered. Please register');
                $('#loginModalBtn').click();
            }else{
                $.get('https://admin.kolpobd.com/mail/passwordResetSuccess/'+result,function(data){
                    console.log(data);
                }).fail(function(error){
                    console.log(error);
                });
                this.setState({
                    resetSuccess: true,
                    spinning: false
                },()=>{
                    $('#loginModalBtn').click();
                    $('#resetForm').trigger('reset');
                });
            }
          },
          (error) => {
            alert('Something is wrong. Please Try Again');
            this.setState({
                spinning: false
            });
          }
        );
    }else{
        alert('Confirm Password Mismatch');
    }
    
  }
  render() {
    return (
      <>
        <Navbar />
        <main ref="main">
            <Container>
                <Row>
                    <Col md="4"></Col>
                    <Col md="4">
                        <Card className="bg-secondary shadow border-0 mt-5">
                            {this.state.spinning && 
                            <div className='sweet-loading'>
                                <DotLoader css={{display: 'block',margin: '100px auto 0 auto'}} sizeUnit={"px"} size={50} color={'#f0b52e'} loading={this.state.spinning} />
                            </div>}
                            <CardBody className="">
                                <div className="text-center text-muted mb-3">
                                <small>Reset Your Password</small>
                                </div>
                                {this.state.valid ?
                                    <Form id="resetForm" onSubmit={this.handleReset}>
                                        {this.state.resetSuccess &&
                                            <Alert color="success">Password Resetted Successfully</Alert>
                                        }
                                        <FormGroup className={classnames("mb-3", { focused: this.state.emailFocused })} >
                                            <InputGroup className="input-group-alternative">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                <i className="ni ni-lock-circle-open" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input name="password" id="password" placeholder="Password" type="password" onFocus={e => this.setState({ emailFocused: true })} onBlur={e => this.setState({ emailFocused: false })} />
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup className={classnames("mb-3", { focused: this.state.passwordFocused })} >
                                            <InputGroup className="input-group-alternative">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                <i className="ni ni-lock-circle-open" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input name="confirm_password" id="confirm_password" placeholder="Confirm Password" type="password" onFocus={e => this.setState({ passwordFocused: true })} onBlur={e => this.setState({ emailFocused: false })} />
                                            </InputGroup>
                                        </FormGroup>
                                        <input type="hidden" name="token" defaultValue={this.props.match.params.token}/>
                                        <div className="text-center">
                                            <Button className="mt-4 mb-1" color="primary" type="submit">Submit</Button>
                                        </div>
                                    </Form>
                                :
                                <Form onSubmit={this.handleForgotPassword}>
                                    <Alert color="danger">Password Reset Token Expired</Alert>
                                    <FormGroup className={classnames("mb-3", { focused: this.state.emailFocused })} >
                                        <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                            <i className="ni ni-email-83" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input name="email" placeholder="Email" type="email" onFocus={e => this.setState({ emailFocused: true })} onBlur={e => this.setState({ emailFocused: false })} />
                                        </InputGroup>
                                    </FormGroup>
                                    <div className="text-center">
                                        <Button className="mt-4 mb-1" color="primary" type="submit">Resend</Button>
                                    </div>
                                </Form>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="4"></Col>
                </Row>
            </Container>
        </main>
        <Footer />
        {this.state.loading && <Loading /> }
      </>
    );
  }
}

export default ResetPassword;