import React from "react";
import $ from 'jquery';
// reactstrap components
import { Table, Button } from "reactstrap";

class BillSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }
  setStoreCredit(e){
    if($('#storeCredit').val() && $('#storeCredit').val()>0){
      var user_id = localStorage.getItem('user_id');
      if(user_id){
        if($('#storeCredit').val()<=parseInt(localStorage.getItem('user_store_credit'))){
          this.props.setStoreCredit($('#storeCredit').val());
          $('#storeCredit').removeClass('is-invalid');
        }else{
          $('#storeCredit').addClass('is-invalid');
        }
      }else{
        $('#loginModalBtn').click();
      }
    }
  }
  removeStoreCredit(e){
    $('#storeCredit').val('');
    this.props.setStoreCredit(0);
  }
  setCouponCode(e){
    if($('#couponCode').val()){
      this.props.setCouponCode($('#couponCode').val());
    }
  }
  removeCouponCode(e){
    this.props.removeCouponCode();
  }
  collapse(item){
    $(item).click();
  }
  getDeliveryCharge(e){
    this.props.getTotal();
  }
  render() {
    return (
      <>
        <div className="cart-summary step-item" id="billSummaryDiv">
          <div className="row">
            <div className="col-lg-3">
            </div>
            <div className="col-lg-6 col-md-12">
              {(this.props.charges && this.props.charges.length>0) ?
                <div>
                  <Table responsive bordered className="delivery-charge-table">
                    {Array.from(this.props.charges).map((charge,index)=>
                      <tr key={index}>
                        <td>
                          <input className="form-control charge-input" type="radio" name="charge" value={charge.ammount} onChange={this.getDeliveryCharge.bind(this)} />
                        </td>
                        <td className="text-left"><b>{charge.name} ({charge.time})</b></td>
                        <td className="text-left"><b>৳ {charge.ammount}</b></td>
                        <td className="d-none delivery-charge-id">{charge.id}</td>
                      </tr>
                    )}
                  </Table>
                  <small className="text-danger select-delivery-feedback d-none"><b>*Please select one delivery option</b></small>
                </div>
              :
                <small className="text-danger"><b>*Please select your location from delivery address</b></small>
              }
            </div>
            <div className="col-lg-3">
            </div>
            <div className="col-lg-3">
            </div>
            <div className="col-lg-6">
              <Table responsive>
                <thead>
                  <tr>
                    <th>Subtotal</th>
                    <th>৳ {parseInt(this.props.total)}</th>
                  </tr>
                </thead>
                <thead>
                  <tr>
                    <th>Shipping</th>
                    <th>৳ {parseInt(this.props.charge)}</th>
                  </tr>
                </thead>
                {this.props.store_credit>0 &&
                  <thead>
                    <tr>
                      <th>Store Credit</th>
                      <th>- ৳ {parseInt(this.props.store_credit)}</th>
                    </tr>
                  </thead>
                }
                {this.props.coupon_discount>0 &&
                  <thead>
                    <tr>
                      <th>Coupon</th>
                      <th>- ৳ {parseInt(this.props.coupon_discount)}</th>
                    </tr>
                  </thead>
                }
                <thead>
                  <tr>
                    <th>Total</th>
                    <th>৳ {parseInt(this.props.total) + parseInt(this.props.charge) - parseInt(this.props.store_credit) - parseInt(this.props.coupon_discount)}</th>
                  </tr>
                </thead>
              </Table>
              {this.props.length>0 &&
                <div className="custom-control custom-checkbox mb-3">
                  <input className="custom-control-input" name="cash_on_delivery" id="customCheck1" type="checkbox" />
                  <label className="custom-control-label" htmlFor="customCheck1">Cash On Delivery</label>
                </div>
              }
              {this.props.length>0 &&
              <div className="input-group input-group-store-credit mb-2 mr-sm-2">
                <div className="input-group-prepend">
                  <div className="input-group-text">Redeem Store Credit</div>
                </div>
                <input type="number" max={localStorage.getItem('user_store_credit')} min="0" autoComplete="off" className="form-control" id="storeCredit" placeholder="Store Credit (৳)" onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} />
                <div className="input-group-append">
                  <div className="input-group-text p-0">
                    <Button className="btn-icon m-0" color="success" type="button" onClick={this.setStoreCredit.bind(this)}><i className="fa fa-check"></i></Button>
                    <Button className="btn-icon m-0" color="danger" type="button" onClick={this.removeStoreCredit.bind(this)}><i className="fa fa-times"></i></Button>
                  </div>
                </div>
                <div className="invalid-feedback">
                  Not Enough Store Credit
                </div>
              </div>
              }
              {this.props.length>0 &&
              <div className="input-group input-group-coupon mb-2 mr-sm-2">
                <div className="input-group-prepend">
                  <div className="input-group-text">Apply Coupon</div>
                </div>
                <input type="text" className="form-control" id="couponCode" placeholder="Coupon Code" onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}/>
                <div className="input-group-append">
                  <div className="input-group-text p-0">
                    <Button className="btn-icon m-0" color="success" type="button" onClick={this.setCouponCode.bind(this)}><i className="fa fa-check"></i></Button>
                    <Button className="btn-icon m-0" color="danger" type="button" onClick={this.removeCouponCode.bind(this)}><i className="fa fa-times"></i></Button>
                  </div>
                </div>
                <div className="valid-feedback">
                  Coupon Applied
                </div>
                <div className="invalid-feedback">
                  <span id="invalid-span" className="d-n">Invalid Coupon Code</span>
                  <span id="max-span" className="d-n">Coupon Code Limit Exceeded</span>
                </div>
              </div>
              }
            </div>
          </div>
          {this.props.length>0 &&
            <div className="buttons">
              <Button className="btn-icon mt-2 pull-right" color="success" type="submit"><span className="btn-inner--text">Checkout</span></Button>
              <button type="button" className="btn btn-primary mt-2 mr-2 pull-right" onClick={this.collapse.bind(this,'.fa-truck')}><i className="fa fa-arrow-left"></i> Back</button>
            </div>
          }
        </div>
      </>
    );
  }
}

export default BillSummary;