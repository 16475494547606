import React from "react";
// reactstrap components
import { Card, CardBody, Row, Col, Button } from "reactstrap";
import Swal from 'sweetalert2';
import ProductCard from "components/Products/ProductCard.jsx";

class Wishlist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      spinning: false,
    };
    this.doneEdit = this.doneEdit.bind(this);
  }
  componentDidMount(){

  }
  doneEdit(){
    this.props.doneEdit();
  }
  render() {
    return (
      <>
        <Card className="bg-secondary shadow border-0">
          <CardBody className="pb-1">
            <Button className="btn btn-default btn-sm mr-3" title="Back" onClick={this.doneEdit.bind()}><i className="fa fa-arrow-left"></i> Back</Button>
            <h6 className="d-i"><b>Your Wishlist</b></h6>
            <div className="products m-0 mt-2">
              <Row>
                {this.props.wishlist && this.props.wishlist.map((item) =>
                  <Col xs="6" md="4" lg="3" key={item.id}>
                    <ProductCard item={item} discounts={this.props.discounts} />
                  </Col>
                )}
              </Row>
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}

export default Wishlist;