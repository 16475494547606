import React from "react";
import { Link } from "react-router-dom";
// Owl Carousel
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// import 'animate.css';

class Slider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }
  componentDidMount(){
    
  }
  getImageUrl(image){
    return 'https://admin.kolpobd.com'+image;
  }
  render() {
    return (
      <>
        <div className="slider-item">
          <OwlCarousel className="owl-theme" items={this.props.items} nav={true} dots={true} loop={true} autoplay>
            {this.props.banners && this.props.banners.map((banner,index) =>
              <div className="item" key={index}><Link to={banner.url}><img src={this.getImageUrl(banner.image)} alt="" /></Link></div>
            )}
          </OwlCarousel>
        </div>
      </>
    );
  }
}

export default Slider;