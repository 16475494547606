import React from "react";
import $ from "jquery";
import Swal from 'sweetalert2';
import { withRouter } from "react-router";
import { FacebookShareButton } from 'react-share';
import * as product from "./../../assets/js/product.js";
// reactstrap components
import { Form, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Input, Button, UncontrolledTooltip, Modal, Card, CardHeader, CardBody, Label, Row, Col, FormFeedback } from "reactstrap";
import { Link } from "react-router-dom";
import DotLoader from 'react-spinners/DotLoader';
import ReactDatetime from "react-datetime";

class ProductShop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      wishlist: 0,
      valid: false,
      spinning: false,
      districts: '',
      store_credit: 0,
      coupon_code: '',
      coupon_discount: 0
    };
  }
  componentDidMount(){

  }
  getRating(rating){
    var star = [];
    for(var i=0;i<5;i++){
      if(i<rating){
        if(i===0){
          star[i] = <i className="fa fa-star star-rating ml-3" />;
        }else{
          star[i] = <i className="fa fa-star star-rating" />;
        }
      }else{
        if(i===0){
          star[i] = <i className="fa fa-star ml-3" />;
        }else{
          star[i] = <i className="fa fa-star" />;
        }
      }
    }
    return star;
  }
  minus(index,e){
    const input = $(e.target).parent().parent().parent().find('.form-control');
    if($(input).val()>0){
      $(input).val(+$(input).val()-1);
      if(index==null){
        var price = null;
      }else{
        var price = parseInt(this.props.prices[index].price);
      }
      this.setState({
        total: this.state.total - price,
      });
    }
  }
  plus(index,e){
    if(index==null){
      var price = null;
    }else{
      var price = parseInt(this.props.prices[index].price);
    }
    this.setState({
      total: this.state.total + price,
    });
    const input = $(e.target).parent().parent().parent().find('.form-control');
    $(input).val(+$(input).val()+1);
  }
  quantity(){
    var total = 0;
    $('.shop input[type=number]').each(function(){
      var count = $(this).val();
      total = total + parseInt(count);
    });
    if(total>0){
      $('.shop input[type=number]').closest('.form-group').removeClass('is-invalid');
      $('.shop input[type=number]').closest('.form-group').find('.invalid-feedback').hide();
    }else{
      console.log($('.shop input[type=number]').closest('.form-group'))
      $('.shop input[type=number]').closest('.form-group').addClass('is-invalid');
      $('.shop input[type=number]').closest('.form-group').find('.invalid-feedback').show();
      $('.shop input[type=number]').focus();
    }
    return total;
  }
  wishlist(e){
    this.props.wishlisted();
  }
  getFeatures(feature,index){
    if(index==0){
      return <span><b>{feature.feature}:</b> <Link to={product.makeFeatureUrl(feature.feature,feature.feature_value)}>{feature.feature_value}</Link></span>;
    }else{
      if(feature.feature===this.props.features[index-1].feature){
        return <span>, <Link to={product.makeFeatureUrl(feature.feature,feature.feature_value)}>{feature.feature_value}</Link></span>;
      }else{
        return <span className="db"><b>{feature.feature}:</b> <Link to={product.makeFeatureUrl(feature.feature,feature.feature_value)}>{feature.feature_value}</Link></span>;
      }
    }
  }
  submit(e){
    e.preventDefault();
    var valid = this.quantity();
    if(valid>0){
      var cart = product.checkCart();
      var user_id = localStorage.getItem("user_id");
      const data = new FormData(e.target);
      data.append('cart',cart);
      data.append('user_id',user_id);
      this.order(data,user_id);
    }
  }
  order(data,user_id){
    if(this.props.product.pre_order==1){
      var preOrderValid = this.validate();
      if(user_id>0){
        if(preOrderValid>0){
          this.setState({ spinning: true });
          let delivery_charge = 0;
          if($('#district').val()==18){
            delivery_charge = this.props.product.delivery_charges.inside_dhaka;
          }else{
            delivery_charge = this.props.product.delivery_charges.outside_dhaka;
          }
          if(this.props.product.discount){
            data.append('discount_id',this.props.product.discount.id);
          }
          data.append('delivery_charge',delivery_charge);
          data.append('pre_order',1);
          data.append('store_credit',this.state.store_credit);
          data.append('coupon_code_discount',this.state.coupon_discount);
          if(this.state.coupon_code!=''){
            data.append('coupon_code',this.state.coupon_code.name);
            data.append('coupon_code_id',this.state.coupon_code.id);
          }else{
            data.append('coupon_code','');
            data.append('coupon_code_id','');
          }
          fetch('https://admin.kolpobd.com/api/order/preOrder', {
            method: 'POST',
            body: data,
          }).then(res => res.json())
          .then(
            (result) => {
              Swal.fire({
                type: 'success',
                title: 'Pre Order Placed Successfully',
                showConfirmButton: false,
                timer: 1500
              });
              this.setState({ spinning: false });
              this.toggleModal('preOrderModal');
              $.get('https://admin.kolpobd.com/mail/customerOrder/'+result.order_id,function(data){
                console.log(data);
              }).fail(function(error){
                console.log(error);
              });
              this.props.history.push('/order/'+result.order_id);
            },
            (error) => {
              alert('error');
              this.setState({ spinning: false });
            }
          );
        }
      }else{
        $('#loginModalBtn').click();
      }
    }else{
      fetch('https://admin.kolpobd.com/api/cart/add', {
        method: 'POST',
        body: data,
      }).then(res => res.json())
      .then(
        (result) => {
          $('#cartItemCount').html(result);
          $('#cartItemCountFloating').html(result);
          Swal.fire({
            type: 'success',
            title: 'Added to cart',
            showConfirmButton: false,
            timer: 1500
          });
        },
        (error) => {
          alert('error');
        }
      );
    }
  }
  toggleModal = state => {
    if(this.quantity()>0){
      this.setState({
        [state]: !this.state[state]
      });
    }
  };
  district(e){
    fetch('https://admin.kolpobd.com/api/geo/district/'+e.target.value)
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          districts: result
        });
        $('#district').val('Select District');
      },
      (error) => {
        
      }
    );
  }
  validate(){
    var valid = 1;
    if($('#name').val()){
      $('#name').removeClass('is-invalid');
      $('#name').next().hide();
    }else{
      $('#name').addClass('is-invalid');
      $('#name').next().show();
      $('#name').focus();
      valid = 0;
    }
    if($('#contact').val()){
      $('#contact').removeClass('is-invalid');
      $('#contact').next().hide();
    }else{
      $('#contact').addClass('is-invalid');
      $('#contact').next().show();
      $('#contact').focus();
      valid = 0;
    }
    if($('#division').val()!="Select Division"){
      $('#division').removeClass('is-invalid');
      $('#division').next().hide();
    }else{
      $('#division').addClass('is-invalid');
      $('#division').next().show();
      $('#division').focus();
      valid = 0;
    }
    if($('#district').val()!="Select District"){
      $('#district').removeClass('is-invalid');
      $('#district').next().hide();
    }else{
      $('#district').addClass('is-invalid');
      $('#district').next().show();
      $('#district').focus();
      valid = 0;
    }
    if($('#address').val()){
      $('#address').removeClass('is-invalid');
      $('#address').next().hide();
    }else{
      $('#address').addClass('is-invalid');
      $('#address').next().show();
      $('#address').focus();
      valid = 0;
    }
    return valid;
  }
  preOrder(e){
    e.preventDefault();
    var valid = this.quantity();
    if(valid>0){
      var cart = product.checkCart();
      var user_id = localStorage.getItem("user_id");
      const data = new FormData(document.getElementById('form'));
      data.append('cart',cart);
      data.append('user_id',user_id);
      data.append('name',$('#name').val());
      data.append('contact',$('#contact').val());
      data.append('division',$('#division').val());
      data.append('district',$('#district').val());
      data.append('address',$('#address').val());
      data.append('institute',$('#institute').val());
      data.append('preferable_time_from',$('#preferable_time_from').val());
      data.append('preferable_time_to',$('#preferable_time_to').val());
      data.append('note',$('#note').val());
      this.order(data,user_id);
    }else{
      // alert('Select atleast one product');
    }
  }
  setStoreCredit(e){
    if($('#storeCredit').val() && $('#storeCredit').val()>0){
      var user_id = localStorage.getItem('user_id');
      if(user_id){
        if($('#storeCredit').val()<=parseInt(localStorage.getItem('user_store_credit'))){
          this.setState({
            store_credit: $('#storeCredit').val()
          });
          $('#storeCredit').removeClass('is-invalid');
          $('#storeCredit').addClass('is-valid');
        }else{
          $('#storeCredit').addClass('is-invalid');
          $('#storeCredit').removeClass('is-valid');
        }
      }else{
        $('#loginModalBtn').click();
      }
    }
  }
  removeStoreCredit(e){
    $('#storeCredit').val('');
    this.setState({
      store_credit: 0
    });
    $('#storeCredit').removeClass('is-invalid');
    $('#storeCredit').removeClass('is-valid');
  }
  setCouponCode(e){
    if($('#couponCode').val()){
      var user_id = localStorage.getItem('user_id');
      if(user_id){
        fetch('https://admin.kolpobd.com/api/cart/check-coupon-for-pre-order/'+this.props.product.id+'/'+$('#couponCode').val()+'/'+user_id)
        .then(res => res.json())
        .then(
          (result) => {
            if(result==0){
              this.setState({
                coupon_code: ''
              },()=>{
                this.getCouponDiscount();
              });
              $('#couponCode').addClass('is-invalid');
              $('#couponCode').removeClass('is-valid');
              $('#invalid-span').removeClass('d-n');
              $('#max-span').addClass('d-n');
            }else if(result==2){
              this.setState({
                coupon_code: ''
              },()=>{
                this.getCouponDiscount();
              });
              $('#couponCode').addClass('is-invalid');
              $('#couponCode').removeClass('is-valid');
              $('#invalid-span').addClass('d-n');
              $('#max-span').removeClass('d-n');
            }else{
              this.setState({
                coupon_code: result
              },()=>{
                this.getCouponDiscount();
              });
              $('#couponCode').addClass('is-valid');
              $('#couponCode').removeClass('is-invalid');
            }
          },
          (error) => {
            
          }
        );
      }else{
        $('#loginModalBtn').click();
      }
    }
  }
  removeCouponCode(){
    this.setState({
      coupon_code: '',
      coupon_discount: 0
    },()=>{
      $('#couponCode').val('');
      $('#couponCode').removeClass('is-invalid');
      $('#couponCode').removeClass('is-valid');
    });
  }
  getCouponDiscount(){
    if(this.state.coupon_code!=''){
      if(this.state.coupon_code.type=='Fixed'){
        this.setState({
          coupon_discount: Math.round(this.state.coupon_code.ammount)
        });
      }else{
        this.setState({
          coupon_discount: Math.round(this.state.coupon_code.ammount/100*this.state.total)
        });
      }
    }else{
      this.setState({
        coupon_discount: 0
      });
    }
  }
  render() {
    return (
      <>
        <Form onSubmit={this.submit.bind(this)} id="form">
          <div className="shop">
            <h3>
              {this.props.product.name}
              <small>
                {this.props.rating>0 ? Array.from(this.getRating(this.props.rating)).map((star,index) =>
                  <span className="star-rating" key={index}>{star}</span>
                ) : ''}
                {this.props.count>0 ? <span className="ml-2">({this.props.count})</span> : ''}
              </small>
              {this.props.product.discount && <span className="ribbon3 discount">
                <span>SAVE {product.getDiscountString(this.props.product.discount)}</span>
              </span>}
            </h3>
            {this.props.features &&
              <div className="featured-features">
                {Array.from(this.props.features).map((feature,index)=>
                  <span key={index}>{this.getFeatures(feature,index)}</span>
                )}
              </div>
            }
            <table className="table table-borderless">
              <tbody>
                {this.props.prices[0] ? (
                  Array.from(this.props.prices).map((price,index) =>
                    <tr key={index}>
                      {price.feature_value && <td className="p-0 pt-2 pr-4">{price.feature_value}</td>}
                      <td className={"p-0 pt-2" + (this.props.product.discount ? ' pr-2 text-line-through' : ' pr-4')}>৳ {parseInt(price.price)}</td>
                      {this.props.product.discount && <td className="p-0 pt-2 pr-4 discount-price">৳ {product.getDiscountedPrice(price.price,this.props.product.discount)}</td>}
                      {price.feature=='Quantity' ?
                      <td className="p-0">
                        {this.props.prices.length-1==index &&
                          <FormGroup>
                            <InputGroup>
                              <InputGroupAddon addonType="prepend" onClick={this.minus.bind(this,index)} >
                                <InputGroupText>
                                  <i className="fa fa-minus" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input type="number" className="quantity-input" name={"quantity["+index+"]"} defaultValue="0" />
                              <Input type="hidden" name={"feature["+index+"]"} defaultValue={price.feature} />
                              <Input type="hidden" name={"feature_value["+index+"]"} defaultValue={price.feature_value} />
                              <InputGroupAddon addonType="append" onClick={this.plus.bind(this,index)} >
                                <InputGroupText>
                                  <i className="fa fa-plus" />
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                            <FormFeedback>Select atleast one product</FormFeedback>
                          </FormGroup>
                        }
                      </td>
                      :
                      <td className="p-0" width="1000px">
                        <FormGroup>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend" onClick={this.minus.bind(this,index)} >
                              <InputGroupText>
                                <i className="fa fa-minus" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input type="number" className="quantity-input" name={"quantity["+index+"]"} defaultValue="0" />
                            <Input type="hidden" name={"feature["+index+"]"} defaultValue={price.feature} />
                            <Input type="hidden" name={"feature_value["+index+"]"} defaultValue={price.feature_value} />
                            <InputGroupAddon addonType="append" onClick={this.plus.bind(this,index)} >
                              <InputGroupText>
                                <i className="fa fa-plus" />
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                          <FormFeedback>Select atleast one product</FormFeedback>
                        </FormGroup>
                      </td>
                      }
                    </tr>
                  )) : (
                    <tr>
                      <td className="p-0 pt-2 pr-4">Price</td>
                      <td className="p-0 pt-2 pr-4">Ask for price</td>
                      <td className="p-0">
                        <FormGroup>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend" onClick={this.minus.bind(this,null)} >
                              <InputGroupText>
                                <i className="fa fa-minus" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input type="number" className="quantity-input" name="quantity[]" defaultValue="0" />
                            <InputGroupAddon addonType="append" onClick={this.plus.bind(this,null)}>
                              <InputGroupText>
                                <i className="fa fa-plus" />
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                          <FormFeedback>Select atleast one product</FormFeedback>
                        </FormGroup>
                      </td>
                    </tr>
                  )
                }
              </tbody>
            </table>
            <div className="buttons">
              <Input type="hidden" name="product_id" defaultValue={this.props.product.id} />
              {this.props.product.sold_out==1 ?
                <Button className="btn-icon" color="danger" type="button"><span className="btn-inner--icon"><i className="fa fa-cart-plus" /></span><span className="btn-inner--text">Sold Out</span></Button>
              :
                <div className="d-i mr-2">
                  {this.props.product.pre_order==1 ?
                    <Button className="btn-icon" color="primary" type="button" onClick={() => this.toggleModal("preOrderModal")}><span className="btn-inner--icon"><i className="fa fa-cart-plus" /></span><span className="btn-inner--text">Pre Order</span></Button>
                  :
                    <Button className="btn-icon" color="primary" type="submit"><span className="btn-inner--icon"><i className="fa fa-cart-plus" /></span><span className="btn-inner--text">Add to cart</span></Button>
                  }
                </div>
              }
              <FacebookShareButton url={'https://kolpobd.com/product/'+this.props.product.id} quote={this.props.product.name} className="btn btn-icon btn-share-facebook" id="facebookShareBtn">
                <span className="btn-inner--icon"><i className="fa fa-facebook" /></span>
              </FacebookShareButton>
              <UncontrolledTooltip delay={0} placement="bottom" target="facebookShareBtn">Share on Facebook</UncontrolledTooltip>
              {this.props.wishlist==0 ?
              <div className="d-i">
                <Button className="btn-icon" id="wishBtn" color="warning" outline type="button" onClick={this.wishlist.bind(this)}><span className="btn-inner--icon"><i className="fa fa-heart" /></span></Button>
              </div>
              :
              <div className="d-i">
                <Button className="btn-icon" id="wishBtn" color="warning" type="button" onClick={this.wishlist.bind(this)}><span className="btn-inner--icon"><i className="fa fa-heart" /></span></Button>
              </div>
              }
              <UncontrolledTooltip delay={0} placement="bottom" target="wishBtn">{this.props.wishlistBtnTxt}</UncontrolledTooltip>
            </div>
          </div>
          <Modal className="modal-dialog-centered login-modal" size="lg" isOpen={this.state.preOrderModal} toggle={() => this.toggleModal("preOrderModal")}>
            {this.state.spinning && 
            <div className='sweet-loading'>
              <DotLoader css={{display: 'block',margin: '30% auto 0 auto'}} sizeUnit={"px"} size={50} color={'#f0b52e'} loading={this.state.spinning} />
            </div>}
            <div className="modal-body p-0">
              <Card className="bg-secondary shadow border-0 profile-card">
                <CardBody className="pt-4 pre-order">
                  <h6 className="text-center text-muted mb-3">Pre Order Now</h6>
                  <Row>
                    <Col md="12" lg="6">
                      <FormGroup>
                        <Label for="name">Name</Label>
                        <Input type="text" id="name" name="name" placeholder="Name" onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}/>
                        <FormFeedback>Please provide name</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md="12" lg="6">
                      <FormGroup>
                        <Label for="contact">Contact</Label>
                        <Input type="text" id="contact" name="contact" placeholder="Contact" onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}/>
                        <FormFeedback>Please provide contact number</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md="6" lg="3">
                      <FormGroup>
                        <Label for="division">Division</Label>
                        <Input type="select" id="division" name="division" onChange={this.district.bind(this)}>
                          <option>Select Division</option>
                          {Array.from(this.props.divisions).map((item,index) =>
                            <option value={item.id} key={index}>{item.name_eng}</option>
                          )}
                        </Input>
                        <FormFeedback>Please provide division</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md="6" lg="3">
                      <FormGroup>
                        <Label for="district">District</Label>
                        <Input type="select" id="district" name="district">
                          <option>Select District</option>
                          {Array.from(this.state.districts).map((item,index) =>
                            <option value={item.id} key={index}>{item.name_eng}</option>
                          )}
                        </Input>
                        <FormFeedback>Please provide district</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md="12" lg="6">
                      <FormGroup>
                        <Label for="address">Address</Label>
                        <Input type="text" id="address" name="address" placeholder="Address" onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}/>
                        <FormFeedback>Please provide delivery address</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md="6" lg="6">
                      <FormGroup>
                        <Label for="preferable_time_from">Preferable delivery time</Label>
                        <ReactDatetime inputProps={{ placeholder: "From", name: "preferable_time_from", id: "preferable_time_from" }} id="preferable_time_from" name="preferable_time_from" timeFormat={true} dateFormat={false} />
                      </FormGroup>
                    </Col>
                    <Col md="6" lg="6">
                      <FormGroup>
                        <Label for="preferable_time_to"><span>&nbsp;</span></Label>
                        <ReactDatetime inputProps={{ placeholder: "To", name: "preferable_time_to", id: "preferable_time_to" }} id="preferable_time_to" name="preferable_time_to" timeFormat={true} dateFormat={false} />
                      </FormGroup>
                    </Col>
                    <Col md="12" lg="12">
                      <FormGroup>
                        <Label for="institute">Institute</Label>
                        <Input type="text" id="institute" name="institute" placeholder="Institute" onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}/>
                        <FormFeedback>Please provide institute</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md="12" lg="12">
                      <FormGroup>
                        <Label for="address">Additional Note</Label>
                        <Input type="text" id="note" name="note" placeholder="Note" onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}/>
                        <FormFeedback>Please provide delivery note</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md="6" lg="6">
                      <div className="input-group input-group-store-credit mb-2 mr-sm-2">
                        <div className="input-group-prepend">
                          <div className="input-group-text">Redeem Store Credit</div>
                        </div>
                        <input type="number" max={localStorage.getItem('user_store_credit')} min="0" autoComplete="off" className="form-control" id="storeCredit" placeholder="Store Credit (৳)" onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} />
                        <div className="input-group-append">
                          <div className="input-group-text p-0">
                            <Button className="btn-icon m-0" color="success" type="button" onClick={this.setStoreCredit.bind(this)}><i className="fa fa-check"></i></Button>
                            <Button className="btn-icon m-0" color="danger" type="button" onClick={this.removeStoreCredit.bind(this)}><i className="fa fa-times"></i></Button>
                          </div>
                        </div>
                        <div className="valid-feedback">
                          Store Credit Redeemed
                        </div>
                        <div className="invalid-feedback">
                          Not Enough Store Credit
                        </div>
                      </div>
                    </Col>
                    <Col md="6" lg="6">
                    <div className="input-group input-group-coupon mb-2 mr-sm-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">Apply Coupon</div>
                      </div>
                      <input type="text" className="form-control" id="couponCode" placeholder="Coupon Code" onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}/>
                      <div className="input-group-append">
                        <div className="input-group-text p-0">
                          <Button className="btn-icon m-0" color="success" type="button" onClick={this.setCouponCode.bind(this)}><i className="fa fa-check"></i></Button>
                          <Button className="btn-icon m-0" color="danger" type="button" onClick={this.removeCouponCode.bind(this)}><i className="fa fa-times"></i></Button>
                        </div>
                      </div>
                      <div className="valid-feedback">
                        Coupon Applied
                      </div>
                      <div className="invalid-feedback">
                        <span id="invalid-span" className="d-n">Invalid Coupon Code</span>
                        <span id="max-span" className="d-n">Coupon Code Limit Exceeded</span>
                      </div>
                    </div>
                    </Col>
                    <Col>
                      <Button className="btn-icon" color="primary" type="button" onClick={this.preOrder.bind(this)}><span className="btn-inner--icon"><i className="fa fa-cart-plus" /></span><span className="btn-inner--text">Submit Order</span></Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Modal>
        </Form>
      </>
    );
  }
}

export default withRouter(ProductShop);