import React from "react";
import axios from "axios";
// reactstrap components
import { Media, Form, Button, Input} from "reactstrap";
import { withRouter, Redirect } from "react-router";
import $ from 'jquery';
import Swal from 'sweetalert2';
import ReactDOM from 'react-dom';
import { WithContext as ReactTags } from 'react-tag-input';

const KeyCodes = {
  comma: 188,
  enter: 13,
};
 
const delimiters = [KeyCodes.comma, KeyCodes.enter];

class QuestionForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: null,
      user_name: null,
      user_image: null,
      tags: [],
      suggestions: [
        { id: 'USA', text: 'USA' },
        { id: 'Germany', text: 'Germany' },
        { id: 'Austria', text: 'Austria' },
        { id: 'Costa Rica', text: 'Costa Rica' },
        { id: 'Sri Lanka', text: 'Sri Lanka' },
        { id: 'Thailand', text: 'Thailand' }
       ],
       setSuggestions: 0
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
    this.getSuggestions = this.getSuggestions.bind(this);
  }
  componentDidMount(){
    this.setState({
      user_id: localStorage.getItem('user_id'),
      user_name: localStorage.getItem('user_name'),
      user_image: localStorage.getItem('user_image'),
      suggestions: this.props.suggestions
    });
  }
  getSuggestions(){
    if(this.state.setSuggestions==0){
      this.setState({
        suggestions: this.props.suggestions,
        setSuggestions: 1
      });
    }
  }
  getImageUrl(image){
    return 'https://admin.kolpobd.com'+image;
  }
  handleDelete(i) {
    const { tags } = this.state;
    this.setState({
     tags: tags.filter((tag, index) => index !== i),
    });
  }
  handleAddition(tag) {
    this.setState(state => ({ tags: [...state.tags, tag] }));
  }
  handleDrag(tag, currPos, newPos) {
    const tags = [...this.state.tags];
    const newTags = tags.slice();
    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);
    // re-render
    this.setState({ tags: newTags });
  }
  validate(question){
    if(question){
      var question = 1;
    }else{
      var question = 0;
      alert('Please write your question');
      return 0;
    }
    if(this.state.user_id){
      var user_id = 1;
    }else{
      var user_id = 0;
      $('#loginModalBtn').click();
    }
    if(question==1 && user_id==1){
      return 1;
    }
    return 0;
  }
  handleSubmit(e){
    e.preventDefault();
    const data = new FormData(e.target);
    var valid = this.validate(e.target.question.value);
    if(valid==1){
      data.append('created_by',localStorage.getItem('user_id'));
      Array.from(this.state.tags).map((tag) => {
        data.append('tags[]', tag.text);
      });
      fetch('https://admin.kolpobd.com/api/questions/ask', {
        method: 'POST',
        body: data,
      }).then(res => res.json())
      .then(
        (result) => {
          if(result==0){
            Swal.fire({
              text: 'Something is wrong',
              type: 'error',
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              showCancelButton: false,
              timer: 1500
            });
          }else{
            Swal.fire({
              text: 'Question asked successfully',
              type: 'success',
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              showCancelButton: false,
              timer: 1500
            });
            this.props.newQuestion(result);
            $('#addForm').trigger('reset');
            this.setState({
              tags: []
            });
          }
        },
        (error) => {
          Swal.fire({
            text: 'Something is wrong',
            type: 'error',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            showCancelButton: false,
            timer: 1500
          });
        }
      );
    }
  }
  render() {
    const { tags, suggestions } = this.state;
    return (
      <>
        <div className="comment">
          <Form onSubmit={this.handleSubmit.bind(this)} id="addForm">
            <Media>
              <Media left href="#">
                {this.state.user_image && <Media object src={this.getImageUrl(this.state.user_image)} alt="User Image" />}
              </Media>
              <Media body>
                <Media heading>
                  {this.state.user_name && this.state.user_name}
                </Media>
                <Input type="textarea" name="question" placeholder="Ask Your Question Here" />
                <ReactTags tags={tags} suggestions={suggestions} handleDelete={this.handleDelete} handleAddition={this.handleAddition} handleDrag={this.handleDrag} delimiters={delimiters} placeholder="Add Your Topic Here" autofocus={false} autocomplete={true} handleInputFocus={this.getSuggestions} minQueryLength={1} />
                <Button className="btn-sm pull-right mt-1" color="primary" type="submit"><span className="btn-inner--text">Ask</span></Button>
              </Media>
            </Media>
          </Form>
        </div>
      </>
    );
  }
}

export default withRouter(QuestionForm);