import React from "react";
import { Link } from "react-router-dom";
import * as product from "./../../assets/js/product.js";
// reactstrap components
import {  } from "reactstrap";

class ProductCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }
  render() {
    return (
      <>
        <Link className="product-card-link" to={"/product/"+this.props.item.id} title={this.props.item.name}>
          <div className="product-card">
            {this.props.item.discount && <span className="ribbon4">
              <span>SAVE {product.getDiscountString(this.props.item.discount)}</span>
            </span>}
            <div className="product-tumb">
              <img src={product.getFirstImage(JSON.parse(this.props.item.images),this.props.item.category_id)} alt="" />
            </div>
            <div className="product-details">
              <div className="product-top-details">
                <h4>{this.props.item.name}</h4>
                {this.props.item.author && <p>By {this.props.item.author}</p>}
                {this.props.item.model && <p>Model: {this.props.item.model}</p>}
              </div>
              <div className={this.props.item.discount ? "product-bottom-details py-1" : "product-bottom-details py-2"}>
                <div className={"product-price " + (this.props.item.discount && 'has-discount text-line-through')}>
                  <span>{product.getPrice(this.props.item.min_price,this.props.item.max_price)}</span>
                </div>
                <div className="product-price discount-price">
                  {this.props.item.discount &&
                    <span>{product.getDiscountedPriceString(this.props.item.min_price,this.props.item.max_price,this.props.item.discount)}</span>
                  }
                </div>
              </div>
            </div>
            {this.props.item.sold_out==1 && <div className="overlay"><span>SOLD OUT</span></div>}
          </div>
        </Link>
      </>
    );
  }
}

export default ProductCard;