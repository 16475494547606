import React from "react";
// reactstrap components
import { Row, Col } from "reactstrap";
import { withRouter } from "react-router";

class ProductDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }
  componentDidMount(){
    
  }
  render() {
    return (
      <>
        <section className="details">
          <Row>
            <Col lg="12">
              <div className="user-product-description">
                <h6>Product Description</h6>
                <p>{this.props.description}</p>
              </div>
            </Col>
          </Row>
        </section>
      </>
    );
  }
}

export default withRouter(ProductDetails);