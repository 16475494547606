import React from "react";
// reactstrap components
import { Row, Col } from "reactstrap";
// Owl Carousel
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

class BloodAlert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }
  componentDidMount(){

  }
  render() {
    return (
      <>
        <div className="">
          <div className="blood-alert">
            <OwlCarousel className="owl-theme" items="1" dots={false} loop={true} autoplay>
              <div className="item">
                <div className="media">
                  <img className="align-self-center mr-0" src={require("assets/img/icons/blood.png")} alt="Generic placeholder image" />
                  <div className="media-body">
                    <h6 className="mt-0">A+ Blood Needed</h6>
                    <p>A+ blood needed on Firmgate, Dhaka</p>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </>
    );
  }
}

export default BloodAlert;