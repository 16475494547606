import React from "react";
// reactstrap components
// import { Container, Row } from "reactstrap";
// core components
import Navbar from "components/Navbars/Navbar.jsx";
import Loading from "components/Navbars/Loading.jsx";
import Footer from "components/Footers/SimpleFooter.jsx";
import Banner from "views/index/sections/Banner.jsx";
import Collections from "views/index/sections/Collections.jsx";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }
  isLoading = (loadingVal) => {
    this.setState({
      loading: loadingVal
    });
  }
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Navbar/>
        <main ref="main">
          <Banner loading={this.isLoading} />
          <Collections />
        </main>
        <Footer />
        {this.state.loading && <Loading /> }
      </>
    );
  }
}

export default Index;