import React from "react";
import { Link } from "react-router-dom";
import * as product from "./../../assets/js/product.js";
// reactstrap components
import { Row, Col } from "reactstrap";

class BestAuthorCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      feature: 'Author'
    };
  }
  componentDidMount(){

  }
  changeFeature(e){
    this.setState({ feature: e.target.value });
  }
  getImageUrl(image1,image2){
    if(image1){
      return 'https://admin.kolpobd.com'+image1;
    }else{
      return 'https://admin.kolpobd.com'+image2;
    }
  }
  getUrl(){
    return '/best-selling-feature/'+product.makeSlug(this.state.feature);
  }
  check(item){
    console.log(item)
  }
  render() {
    return (
      <>
        <div className="best-selling-card best-authors">
          <h4 className="header">Best Selling Features</h4>
          <select className="form-control" onChange={this.changeFeature.bind(this)}>
            {this.props.bestSellingFeatures && Object.entries(this.props.bestSellingFeatures).map((feature,index) =>
              <option key={index}>{feature[0]}</option>
            )}
          </select>
          <div className="item-lists">
            <div className="items">
              {this.props.bestSellingFeatures && Object.entries(this.props.bestSellingFeatures).map((feature,key) =>
                <div className={feature[0]==this.state.feature ? 'db' : 'd-n'} key={key}>
                  {feature[0]==this.state.feature &&
                    <div className="items">
                      {feature[1].map((item,index) =>
                        <Link to={"/feature/"+product.makeSlug(item.feature)+'/'+product.makeSlug(item.feature_value)} key={index}>
                          <div className="media">
                            <img className="align-self-center mr-2" src={this.getImageUrl(item.feature_value_image,item.feature_image)} />
                            <div className="media-body">
                              <span className="headline mt-2">{item.feature_value}</span>
                            </div>
                          </div>
                        </Link>
                      )}
                    </div>
                  }
                </div>
              )}
              <div className="best-selling-see-more">
                <Link className="btn btn-default btn-sm" to={this.getUrl()}>See More</Link>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default BestAuthorCard;