import React from "react";
// reactstrap components
import {  } from "reactstrap";
import ProductCard from "components/Products/ProductCard.jsx";
// Owl Carousel
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

class Collection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      responsive:{
        0: {
          items: 2,
        },
        450: {
          items: 2,
        },
        600: {
          items: 4,
        },
        1000: {
          items: 6,
        },
      },
    };
  }
  componentDidMount(){
    
  }
  getImageUrl(image){
    return 'https://admin.kolpobd.com'+image;
  }
  render() {
    return (
      <>
        <div className="collection">
          <div className="collection-header">
            <h6>{this.props.collection.collection.name}</h6>
          </div>
          <OwlCarousel className="owl-theme" responsive={this.state.responsive} nav={true} dots={false} loop={false}>
              {Array.from(this.props.collection.products).map((product,index) =>
                <div className="item mr-3" key={index}><ProductCard item={product} discounts={this.props.discounts}/></div>
              )}
          </OwlCarousel>
        </div>
      </>
    );
  }
}

export default Collection;