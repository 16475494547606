import React from "react";
import Swal from 'sweetalert2';
import { FacebookShareButton } from 'react-share';
// reactstrap components
import { Button, Badge, UncontrolledTooltip } from "reactstrap";

class ProductShop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonLabel: 'Contact With Seller',
    };
    this.getContact = this.getContact.bind(this);
  }
  componentDidMount(){
    
  }
  getContact(){
    this.setState({
      buttonLabel: this.props.product.contact
    });console.log(this.props.product);
  }
  soldOut(){
    Swal.fire({
      title: 'Are you sure?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2dce89',
      cancelButtonColor: '#ec0c38',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        fetch('https://admin.kolpobd.com/api/user-product/sold/'+this.props.product.id)
        .then(res => res.json())
        .then(
          (result) => {
            if(result==1){
              Swal.fire({
                type: 'success',
                title: 'Product Sold',
                showConfirmButton: false,
                timer: 1500
              });
              this.props.sold();
            }else{
              Swal.fire({
                type: 'error',
                title: 'Something is wrong',
                text: 'Please try again',
                showConfirmButton: false,
                timer: 1500
              });
            }
          },
          (error) => {
            Swal.fire({
              type: 'error',
              title: 'Something is wrong',
              text: 'Please try again',
              showConfirmButton: false,
              timer: 1500
            });
          }
        );
      }
    });
  }
  render() {
    return (
      <>
        <div className="shop">
          <Badge className="tags" color="default">{this.props.product.category_name}</Badge>
          {this.props.product.sub_category_name && <i className="fa fa-arrow-right ml-2 mr-2"></i>}
          {this.props.product.sub_category_name && <Badge className="tags" color="default">{this.props.product.sub_category_name}</Badge>}
          <h3>
            {this.props.product.name}
            {this.props.isSold==1 && <span className="ribbon3 discount">
              <span>SOLD</span>
            </span>}
            {this.props.product.featured==1 && <span className="ribbon3 discount">
              <span>FEATURED</span>
            </span>}
            <p className="user-product-location mb-0">
              <i className="fa fa-map-marker mr-2"></i>
              {this.props.product.union_name && <span>{this.props.product.union_name}, </span>}
              {this.props.product.upazila_name && <span>{this.props.product.upazila_name}, </span>}
              {this.props.product.district_name && <span>{this.props.product.district_name}, </span>}
              {this.props.product.division_name && <span>{this.props.product.division_name}</span>}
            </p>
            {this.props.product.campus_name && <p className="user-product-location mb-0">
              <i className="fa fa-graduation-cap mr-2"></i>
              <span>{this.props.product.campus_name}</span>
            </p>}
          </h3>
          <div>
            {this.props.product.tags && Array.from(this.props.product.tags.split(',')).map((tag,index) =>
              <Badge className="tags" color="default" key={index}>{tag}</Badge>
            )}
          </div>
          <a className="btn btn-primary btn-icon mt-2" href={'tel:'+this.props.product.contact} onClick={this.getContact}><span className="btn-inner--icon"><i className="fa fa-phone" /></span><span className="btn-inner--text" id="copyText">{this.state.buttonLabel}</span></a>
          {this.props.isSold==0 && <Button className="btn-success btn-icon mt-2" onClick={this.soldOut.bind(this)}><span className="btn-inner--icon"><i className="fa fa-shopping-cart" /></span><span className="btn-inner--text" id="copyText">Sold Out</span></Button>}
          <FacebookShareButton url={'https://kolpobd.com/old-stuff/'+this.props.product.slug} quote={this.props.product.name} className="btn btn-icon btn-share-facebook mt-2" id="facebookShareBtn">
            <span className="btn-inner--icon"><i className="fa fa-facebook" /></span>
          </FacebookShareButton>
          <UncontrolledTooltip delay={0} placement="bottom" target="facebookShareBtn">Share on Facebook</UncontrolledTooltip>
        </div>
      </>
    );
  }
}

export default ProductShop;