import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { Button } from "reactstrap";

class ProductCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }
  componentDidMount(){
  }
  getFirstImage(images){
    if(images!=null){
      return 'https://admin.kolpobd.com'+images[0];
    }else{
      return 'https://admin.kolpobd.com/images/categories/'+this.props.item.category_id+'.png';
    }
  }
  render() {
    return (
      <>
        <Link className="product-card-link" to={"/old-stuff/"+this.props.item.slug} onClick={this.checkSoldOut}>
          <div className="product-card user-product-card">
            {this.props.item.featured==1 && <span className="ribbon1"><span>Featured</span></span>}
            <div className="product-tumb">
              <img src={this.getFirstImage(JSON.parse(this.props.item.images))} alt="" />
            </div>
            <div className="product-details">
              <div className="product-top-details">
                <span className="product-category">{this.props.item.category_name}</span>
                <h4 className="mb-2">{this.props.item.name}</h4>
                <p className="user-product-location mb-2">
                  <i className="fa fa-map-marker mr-1"></i>
                  {this.props.item.district_name && <span>{this.props.item.district_name}, </span>}
                  {this.props.item.division_name && <span>{this.props.item.division_name}</span>}
                </p>
                {this.props.item.campus_name &&
                <p className="user-product-location mb-1">
                  <i className="fa fa-graduation-cap mr-1"></i>
                  <span>{this.props.item.campus_name}</span>
                </p>
                }
              </div>
              <div className="product-bottom-details">
                <div className="product-price pb-1">
                  <span>৳ {parseInt(this.props.item.price)}</span>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </>
    );
  }
}

export default ProductCard;