export function getFirstImage(images,category_id){
    if(images!=null){
      return 'https://admin.kolpobd.com'+images[0];
    }else{
      return 'https://admin.kolpobd.com/images/categories/'+category_id+'.png';
    }
}

export function getPrice(min_price,max_price){
  if(min_price==null){
    return 'Ask for price';
  }else if(min_price===0){
    return 'Ask for price';
  }else{
    if(max_price==null || max_price===0 || max_price==min_price){
      return '৳ '+parseInt(min_price);
    }else{
      return 'From ৳ '+parseInt(min_price)+' to ৳ '+parseInt(max_price);
    }
  }
}

export function getDiscountedPrice(price,discount){
    if(discount && discount!=''){
      if(discount.type=='Fixed'){
        price = price - discount.ammount;
      }else{
        if(discount.type=='Percentage'){
          let discountAmount = (discount.ammount/100) * price;
          if(discount.upto>0 && discountAmount>discount.upto){
            discountAmount = discount.upto;
          }
          price = price - discountAmount;
        }
      }
    }

    return Math.round(price);
}

export function getDiscountString(discount){
    if(discount!=''){
      if(discount.type=='Fixed'){
        return parseInt(discount.ammount)+' BDT';
      }else{
        if(discount.type=='Percentage'){
          return parseInt(discount.ammount)+' %';
        }
      }
    }
}

export function getDiscountedPriceString(min_price,max_price,discount){
    var discountedMinPrice = min_price;
    var discountedMaxPrice = max_price;
    if(discount!=''){
      if(discount.type=='Fixed'){
        discountedMinPrice = discountedMinPrice - discount.ammount;
        discountedMaxPrice = discountedMaxPrice - discount.ammount;
      }else{
        if(discount.type=='Percentage'){
          let discountMinAmount = (discount.ammount/100) * discountedMinPrice;
          let discountMaxAmount = (discount.ammount/100) * discountedMaxPrice;
          if(discount.upto>0 && discountMinAmount>discount.upto){
            discountMinAmount = discount.upto;
          }
          if(discount.upto>0 && discountMaxAmount>discount.upto){
            discountMaxAmount = discount.upto;
          }
          discountedMinPrice = discountedMinPrice - discountMinAmount;
          discountedMaxPrice = discountedMaxPrice - discountMaxAmount;
        }
      }
    }
    if(min_price==null){
      return 'Free';
    }else if(min_price===0){
      return 'Free';
    }else{
      if(max_price==null || max_price===0 || max_price==min_price){
        return '৳ '+Math.round(discountedMinPrice);
      }else{
        return 'From ৳ '+Math.round(discountedMinPrice)+' to ৳ '+Math.round(discountedMaxPrice);
      }
    }
}

export function checkSoldOut(e,sold_out){
  if(sold_out==1){
    e.preventDefault();
  }
}

export function makeImageUrl(second_image='',image=''){
  if(image){
    return 'https://admin.kolpobd.com'+image; 
  }
  return 'https://admin.kolpobd.com'+second_image;
}

export function makeFeatureUrl(feature,feature_value){
  if(feature && feature_value){
    return '/feature/'+makeSlug(feature)+'/'+makeSlug(feature_value);
  }
}

export function makeSlug(string){
  if(string){
    return string.toLowerCase().split('/').join('!').split(' ').join('_');
  }
  // return string.toLowerCase().split('/').join('!').split(' ').join('_');
}

export function removeSlug(string){
  if(string){
    string = string.toLowerCase().split('!').join('/').split('_').join(' ');
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  // return string.toLowerCase().split('!').join('/').split('_').join(' ');
}

export function check(item){
  console.log(item);
}

export function checkCart(){
  var cart = localStorage.getItem('cart');
  if (cart) {
    return cart;
  } else {
    var d = new Date();
    cart = d.getTime()+Math.floor(Math.random() * 1000);
    localStorage.setItem('cart', cart);
    return cart;
  }
}

export function setCookie(cname, cvalue, exdays){
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires="+d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;SameSite=None;";
}

export function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for(var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function checkCookie(cname) {
  var cart = this.checkCart();
  if (cart) {
    return cart;
  } else {
    var d = new Date();
    cart = d.getTime()+Math.floor(Math.random() * 1000);
    localStorage.setItem('cart', cart);
    return cart;
  }
}

export function removeCookie(cname){
  document.cookie = cname+"=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}