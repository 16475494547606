import React from "react";
// reactstrap components
import {  } from "reactstrap";

class CalendarButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }
  componentDidMount(){
    
  }
  render() {
    return (
      <>
        <div className="side-btn-group">
          <button type="button" className="btn btn-warning btn-sm side-btn"><i className="fa fa-calendar"></i></button>
        </div>
      </>
    );
  }
}

export default CalendarButton;