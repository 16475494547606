import React from "react";
// reactstrap components
import { Container, Row, Col, Card, CardBody, Table, Button, Modal, CardHeader, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import Moment from 'react-moment';
import Swal from 'sweetalert2';
import DotLoader from 'react-spinners/DotLoader';

import StatusImage from "components/Order/StatusImage.jsx";

class Items extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order : '',
      items : [],
      due : 0
    };
    this.itemsCall = this.itemsCall.bind(this);
    this.cancelOrder = this.cancelOrder.bind(this);
  }
  componentDidMount(){
    if(!localStorage.getItem('user_email')){
      this.props.history.push('/');
    }else{
      this.itemsCall();
    }
  }
  itemsCall(){
    this.props.loading(true);
    var user_id = localStorage.getItem('user_id');
    fetch('https://admin.kolpobd.com/api/custom-order/'+this.props.match.params.order+'/'+user_id)
    .then(res => res.json())
    .then(
      (result) => {
        if(result==0){
          this.props.history.push('/');
        }else{
          this.setState({
            order: result.order
          },function(){
            console.log(parseInt(this.state.order.paid))
            let due = this.getTotal();
            if(this.state.order.paid > 0){
              due = due - this.state.order.paid;
            }
            this.setState({
              due : due
            });
          });
          this.props.loading(false);
        }
      },
      (error) => {
        this.props.history.push('/');
      }
    );
  }
  getImageUrl(category_id){
    return 'https://admin.kolpobd.com/images/custom-order-categories/'+category_id+'.png';
  }
  getItemTotal(index){
    var total = this.state.items[index].selling_price * this.state.items[index].quantity;
    return '৳ '+total;
  }
  cancelOrder(id,e){
    var user_id = localStorage.getItem('user_id');
    Swal.fire({
      title: 'Are you sure?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2dce89',
      cancelButtonColor: '#ec0c38',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        fetch('https://admin.kolpobd.com/api/custom-order/cancel/'+id+'/'+user_id)
        .then(res => res.json())
        .then(
          (result) => {
            if(result==1){
              Swal.fire({
                type: 'success',
                title: 'Order Cancelled',
                showConfirmButton: false,
                timer: 1500
              });
              this.itemsCall();
            }else{
              Swal.fire({
                type: 'error',
                title: 'Something is wrong',
                text: 'Please try again',
                showConfirmButton: false,
                timer: 1500
              });
            }
          },
          (error) => {
            Swal.fire({
              type: 'error',
              title: 'Something is wrong',
              text: 'Please try again',
              showConfirmButton: false,
              timer: 1500
            });
          }
        );
      }
    });
  }
  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };
  getTotal(){
    return (parseInt(this.state.order.ammount)+parseInt(this.state.order.delivery_charge));
  }
  render() {
    return (
      <>
        <section className="cart">
          <Container>
            <Card className="shadow">
              <CardBody>
                <Row>
                  <Col md="12">
                    <StatusImage status={this.state.order.status} cancel={this.state.order.cancel} />
                  </Col>
                  <Col md="12">
                    <div className="order mb-3">
                      {this.state.order.user_request==1 ?
                        <h6 className="d-i">Product Request #UR{this.state.order.id}</h6>
                      :
                        <h6 className="d-i">{this.state.order.type} Order #{this.state.order.initial}{this.state.order.id}</h6>
                      }
                      {this.state.order.cancel==0 && this.state.order.status<3 && <button className="btn btn-sm btn-danger pull-right" onClick={this.cancelOrder.bind(this,this.state.order.id)}>Cancel</button>}
                      {this.state.order.price && this.state.order.paid<this.getTotal() && 
                        <button className="btn btn-sm btn-success pull-right" type="button" onClick={() => this.toggleModal("paymentModal")}>Make Payment</button>
                      }
                      {this.state.order.price && this.state.order.paid>=this.getTotal() &&
                        <button className="btn btn-sm btn-success pull-right">Paid</button>
                      }
                      <Link className="btn btn-sm btn-primary pull-right" to={'/custom-order'}>Order more products</Link>
                      <Link className="btn btn-sm btn-primary pull-right" to={'/profile'}>Your Profile</Link>
                      <div className="order-details mt-2">
                        {/* 
                        <span><b>Quantity: </b>{this.state.order.quantity}</span> */}
                        <span><b>Delivery To: </b>{this.state.order.name}</span>
                        <span><b>Contact: </b>{this.state.order.contact}</span>
                        <span><b>Address: </b>{this.state.order.address}</span>
                        <span><b>Location: </b>{this.state.order.district_name}</span>
                        {this.state.order.preferable_time_from && <span><b>Preferable Delivery Time: </b>{this.state.order.preferable_time_from} {this.state.order.preferable_time_to && 'to '+this.state.order.preferable_time_to}</span>}
                        {this.state.order.description && <span><b>Description: </b>{this.state.order.description}</span>}
                        {this.state.order.additional_request && <span><b>Additional Request: </b>{this.state.order.additional_request}</span>}
                        <span><b>Attachments: </b></span>{this.state.order.files && Array.from(JSON.parse(this.state.order.files)).map((file,index)=>
                          <a className="btn btn-success btn-sm mb-1" href={'https://admin.kolpobd.com'+file} target="_blank" key={index}>{ file.split('/')[file.split('/').length-1] }</a>
                        )}
                        {this.state.order.file_url && <a className="btn btn-success btn-sm mb-1" href={ this.state.order.file_url } target="_blank">Drive Link</a>}
                      </div>
                      <div className="order-items cart mt-3">
                        <Table responsive>
                          <thead>
                            <tr>
                              <th>Order</th>
                              <th>Price</th>
                              <th>Quantity</th>
                              <th>Price</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                                <td className="table-user">
                                  {this.state.order.user_request==1 ?
                                    <Link to={"/user-request"}>
                                      <b>#UR{this.state.order.id}</b>
                                      <span className="d-block"><b>{this.state.order.product_name}</b></span>
                                    </Link>
                                  :
                                    <Link to={"/custom-order"}>
                                      <b>{this.state.order.initial}{this.state.order.id}</b>
                                      {this.state.order.features && Object.entries(JSON.parse(this.state.order.features)).map(([index,feature]) =>
                                        <span key={index} className="d-block"><b>{index}: </b>{feature}</span>
                                      )}
                                    </Link>
                                  }
                                </td>
                                <td>{parseInt(this.state.order.price)}</td>
                                <td>{this.state.order.quantity}</td>
                                <td>৳ {parseInt(this.state.order.ammount)}</td>
                            </tr>
                          </tbody>
                          <thead>
                            <tr>
                              <th className="total">Shipping</th>
                              <th></th>
                              <th></th>
                                <th id="total" className="total">৳ {parseInt(this.state.order.delivery_charge)}</th>
                            </tr>
                          </thead>
                          <thead>
                            <tr>
                              <th className="total">Total</th>
                              <th></th>
                              <th></th>
                              <th id="total" className="total">৳ {(parseInt(this.state.order.ammount)+parseInt(this.state.order.delivery_charge)-parseInt(this.state.order.store_credit)-parseInt(this.state.order.coupon_code_discount))}</th>
                            </tr>
                          </thead>
                          <thead>
                            <tr>
                              <th className="total">Paid</th>
                              <th></th>
                              <th></th>
                              <th className="total">{this.state.order.paid>0 ? <span>{'৳ '+parseInt(this.state.order.paid)}</span> : 'Pending'}</th>
                            </tr>
                          </thead>
                          <thead>
                            <tr>
                              <th className="total">Due</th>
                              <th></th>
                              <th></th>
                              <th className="total">{this.state.order.paid>0 ? <span>৳ {parseInt(this.state.order.ammount)+parseInt(this.state.order.delivery_charge)-parseInt(this.state.order.store_credit)-parseInt(this.state.order.coupon_code_discount)-parseInt(this.state.order.paid)}</span> : 'Pending'}</th>
                            </tr>
                          </thead>
                        </Table>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </section>
        <Modal className="modal-dialog-centered login-modal" size="sm" isOpen={this.state.paymentModal} toggle={() => this.toggleModal("paymentModal")}>
          {this.state.spinning && 
          <div className='sweet-loading'>
            <DotLoader css={{display: 'block',margin: '50% auto 0 auto'}} sizeUnit={"px"} size={50} color={'#f0b52e'} loading={this.state.spinning} />
          </div>}
          <div className="modal-body p-0">
            <Card className="bg-secondary shadow border-0">
              <CardHeader className="bg-white">
                <div className="text-muted text-center mb-2">
                  <small>Make Payment</small>
                </div>
              </CardHeader>
              <CardBody className="">
                <Form method="post" action="https://admin.kolpobd.com/pay" >
                  <FormGroup className="mb-3" >
                    <InputGroup className="input-group-alternative">
                      <Input name="amount" placeholder="Amount" type="number" min="10" max={this.state.due} defaultValue={this.state.due} required />
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    <input type="hidden" name="id" value={this.state.order.id} />
                    <input type="hidden" name="order_table" value="custom_orders" />
                    {/* <input type="hidden" name="order_table_id" value={this.state.order.id} /> */}
                    <input type="hidden" name="order_payment_table" value="custom_order_payments" />
                    <input type="hidden" name="user_id" value={localStorage.getItem('user_id')} />
                    <Button className="mt-4 mb-1" color="primary" type="submit">Pay</Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>
      </>
    );
  }
}

export default withRouter(Items);