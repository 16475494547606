import React from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import Swal from 'sweetalert2';
import * as product from "./../../assets/js/product.js";
// reactstrap components
import { FormGroup, InputGroup, InputGroupAddon, InputGroupText, Input, Table, Button } from "reactstrap";

class CartItems extends React.Component {
  constructor(props) {
    super(props);
  }
  getItemPrice(price,quantity){
    var itemPrice = price*quantity;
    if(itemPrice){
      return itemPrice;
    }
    return 0;
  }
  getItemTotal(){
    var total = 0;
    this.props.items.map((item,index) => {
      if(item.max_price>0){
        total = total + product.getDiscountedPrice(item.max_price,item.discount) * item.quantity;
      }
    });
    if(total){
       return '৳ '+total;
    }
    return '৳ 0';
  }
  minus(index){
    if(this.props.items[index].quantity>1){
      var items = this.props.items;
      items[index].quantity = parseInt(items[index].quantity) - 1;
      if(items[index].feature=='Quantity'){
        items[index] = this.getQuantityPrice(items[index]);
      }
      this.props.updateItems(items);
    }
  }
  plus(index){
    var items = this.props.items;
    items[index].quantity = parseInt(items[index].quantity) + 1;
    if(items[index].feature=='Quantity'){
      items[index] = this.getQuantityPrice(items[index]);
    }
    this.props.updateItems(items);
  }
  onBlur(index,e){
    if(e.target.value<1){
      var items = this.props.items;
      items[index].quantity = 1;
      if(items[index].feature=='Quantity'){
        items[index] = this.getQuantityPrice(items[index]);
      }
      this.props.updateItems(items);
    }
  }
  onChange(index,e){
    var items = this.props.items;
    items[index].quantity = parseInt(e.target.value);
    if(items[index].feature=='Quantity'){
      items[index] = this.getQuantityPrice(items[index]);
    }
    this.props.updateItems(items);
  }
  getQuantityPrice(item){
    if(item.quantities){
      item.quantities.map((quantity,index) => {
        if(quantity.feature_value.includes('-')!==false){
          var selling_features = quantity.feature_value.split('-');
          if("1" in selling_features){
            if(item.quantity>=selling_features[0] && item.quantity<=selling_features[1]){
              item.feature_value = quantity.feature_value;
              item.max_price = quantity.price;
              return item;
            }
          }
        }else if(quantity.feature_value.includes('+')!==false){
          var selling_features = quantity.feature_value.split('+');
          if("0" in selling_features){
            if(item.quantity>=selling_features[0]){
              item.feature_value = quantity.feature_value;
              item.max_price = quantity.price;
              return item;
            }
          }
        }
      })
    }
    return item;
  }
  remove(id){
    Swal.fire({
      title: 'Are you sure?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2dce89',
      cancelButtonColor: '#ec0c38',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        fetch('https://admin.kolpobd.com/api/cart/remove/'+id)
        .then(res => res.json())
        .then(
          (result) => {
            if(result==1){
              Swal.fire({
                type: 'success',
                title: 'Item Removed',
                showConfirmButton: false,
                timer: 1500
              });
              var items = this.props.items;
              items.map((item,index) =>{
                if(item.cart_id==id){ 
                  items.splice(index,1);
                }
              });
              this.props.updateItems(items);
            }else{
              Swal.fire({
                type: 'error',
                title: 'Something is wrong',
                text: 'Please try again',
                showConfirmButton: false,
                timer: 1500
              });
            }
          },
          (error) => {
            Swal.fire({
              type: 'error',
              title: 'Something is wrong',
              text: 'Please try again',
              showConfirmButton: false,
              timer: 1500
            });
          }
        );
      }
    });
  }
  collapse(){
    $('.fa-truck').click();
  }
  render() {
    return (
      <>
        <div className="cart-items step-item" id="itemsTable">
          <Table>
            <thead>
              <tr>
                <th width="5%">#</th>
                <th width="35%">Product</th>
                <th width="20%">Price</th>
                <th width="25%">Quantity</th>
                <th width="10%">Total</th>
                <th width="5%"></th>
              </tr>
            </thead>
            <tbody>
            {this.props.items.length>0 ? this.props.items.map((item,index) =>
              <tr key={index}>
                <td>{index+1}</td>
                <td className="table-user">
                  <Link to={"/product/"+item.id} target="_blank">
                    <div className="media">
                      <img className="avatar align-self-center mr-3" src={product.getFirstImage(JSON.parse(item.images),item.category_id)} />
                      <div className="media-body">
                        <b>{item.name}</b> {item.feature_value && '('+item.feature_value+')'}
                        {item.author && <small><br/>By {item.author}</small>}
                        {item.model && <small><br/>Model: {item.model}</small>}
                      </div>
                    </div>
                  </Link>
                </td>
                <td>
                  {item.max_price>0 ? <span className={item.discount && 'text-line-through'}>৳ {parseInt(item.max_price)}</span> : 0}
                  {item.discount && <span className="discount-price">৳ {product.getDiscountedPrice(item.max_price,item.discount)}</span>}
                </td>
                <td>
                  <FormGroup>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend" onClick={this.minus.bind(this,index)} >
                        <InputGroupText>
                          <i className="fa fa-minus" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input type="number" min="1" name={"quantity["+index+"]"} value={item.quantity} onChange={this.onChange.bind(this,index)} onBlur={this.onBlur.bind(this,index)} />
                      <Input type="hidden" name={"cart_id["+index+"]"} defaultValue={item.cart_id} />
                      <Input type="hidden" name={"product_id["+index+"]"} defaultValue={item.id} />
                      <Input type="hidden" name={"feature["+index+"]"} defaultValue={item.feature} />
                      <Input type="hidden" name={"feature_value["+index+"]"} defaultValue={item.feature_value} />
                      <Input type="hidden" name={"selling_price["+index+"]"} defaultValue={item.max_price>0 ? item.max_price : 0} />
                      <Input type="hidden" name={"discount["+index+"]"} defaultValue={item.discount ? item.max_price-product.getDiscountedPrice(item.max_price,item.discount) : 0} />
                      <Input type="hidden" name={"discount_id["+index+"]"} defaultValue={item.discount ? item.discount.id : null} />
                      <InputGroupAddon addonType="append" onClick={this.plus.bind(this,index)} >
                        <InputGroupText>
                          <i className="fa fa-plus" />
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                </td>
                <td>{this.getItemPrice(product.getDiscountedPrice(item.max_price,item.discount),item.quantity)}</td>
                <td><Button className="btn-sm" color="danger" type="button" onClick={this.remove.bind(this,item.cart_id)}><i className="fa fa-trash" /></Button></td>
              </tr>
            ) : <tr className="text-center"><td></td><td></td><td>Your cart is empty</td><td></td><td></td></tr>}
            </tbody>
            <thead>
              <tr>
                <th></th>
                <th className="total">Total</th>
                <th></th>
                <th></th>
                <th id="total" className="total">{this.getItemTotal()}</th>
                <th></th>
              </tr>
            </thead>
          </Table>
          {this.props.items.length>0 &&
            <div>
              <button type="button" className="btn btn-success pull-right" onClick={this.collapse.bind()}>Next <i className="fa fa-arrow-right"></i></button>
            </div>
          }
        </div>
      </>
    );
  }
}

export default CartItems;