import React from "react";
import $ from "jquery";
import { Link } from "react-router-dom";
// reactstrap components
import { Row, Col, Card, CardHeader, CardBody, Button } from "reactstrap";
import Moment from 'react-moment';

class ProfileCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
    this.edit = this.edit.bind(this);
    this.wishlist = this.wishlist.bind(this);
  }
  componentDidMount(){

  }
  getImageUrl(images){
    return 'https://admin.kolpobd.com'+images;
  }
  edit(){
    this.props.edit();
  }
  wishlist(){
    this.props.wishlist();
  }
  render() {
    return (
      <>
        <Card className="bg-secondary shadow border-0 profile-card mb-3">
          <CardHeader className="bg-white">
            <img className="cover" src={this.getImageUrl('/images/banner.jpg')} alt="Product Gallery" />
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="card-profile-image">
                  <img src={this.getImageUrl(this.props.user.image)} className="rounded-circle" />
                </div>
              </div>
            </div>
          </CardHeader>
          <CardBody className="">
            <h4><b>{this.props.user.name}</b></h4>
            <span className="db"><i className="fa fa-envelope mr-2" />{this.props.user.email}</span>
            {this.props.user.contact && <span className="db"><i className="fa fa-phone-square mr-2" />{this.props.user.contact}</span>}
            {this.props.user.dob && <span className="db"><i className="fa fa-birthday-cake mr-2" /><Moment format="D MMM, YYYY" date={this.props.user.dob} /></span>}
            {this.props.user.blood && <span className="db blood-group"><i className="fa fa-heart mr-2" />{this.props.user.blood}</span>}
            <span className="db store-credit"><img src={require("assets/img/icons/coin.png")} /> {this.props.user.store_credit}</span>
            <Button className="btn-icon btn-3 profile-btn" size="sm" color="primary" outline type="button" onClick={this.edit}>
              <span className="btn-inner--icon"><i className="fa fa-edit" /></span>
              <span className="btn-inner--text">Edit</span>
            </Button>
            <Button className="btn-icon btn-3 profile-btn" size="sm" color="primary" outline type="button" onClick={this.wishlist}>
              <span className="btn-inner--icon"><i className="fa fa-star" /></span>
              <span className="btn-inner--text">Wishlist</span>
            </Button>
            <Link to="/sell" className="btn btn-outline-primary btn-sm btn-icon btn-3 profile-btn">Sell Old Stuff</Link>
          </CardBody>
        </Card>
      </>
    );
  }
}

export default ProfileCard;