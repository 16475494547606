import React from "react";
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css';
import './../../assets/css/custom_swiper.css';

class SampleSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }
  componentDidMount(){
    var galleryThumbs = new Swiper('.gallery-thumbs', {
        direction: 'vertical',
        spaceBetween: 10,
        slidesPerView: 4,
        loop: false,
        freeMode: true,
        // loopedSlides: Array.from(JSON.parse(this.props.samples)).length, //looped slides should be the same
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
    });
    // this.swiper = new Swiper('.gallery-top', {
    //     spaceBetween: 10,
    //     loop: false,
    //     // loopedSlides: Array.from(JSON.parse(this.props.samples)).length, //looped slides should be the same
    //     navigation: {
    //       nextEl: '.swiper-button-next',
    //       prevEl: '.swiper-button-prev',
    //     },
    //     thumbs: {
    //       swiper: galleryThumbs,
    //     },
    // });
  }
  getImageUrl(image){
    return 'https://admin.kolpobd.com'+image;
  }
  render() {
    return (
      <>
        <div className="swiper-container gallery-top">
            <div className="swiper-wrapper">
                {Array.from(JSON.parse(this.props.samples)).map((sample,sampleIndex) =>
                    <div key={sampleIndex} className="swiper-slide" style={{backgroundImage: "url("+this.getImageUrl(sample)+")"}}></div>
                )}
            </div>
            <div className="swiper-button-next swiper-button-white"></div>
            <div className="swiper-button-prev swiper-button-white"></div>
        </div>
        <div className="swiper-container gallery-thumbs">
            <div className="swiper-wrapper">
                {Array.from(JSON.parse(this.props.samples)).map((sample,sampleIndex) =>
                    <div key={sampleIndex} className="swiper-slide" style={{backgroundImage: "url("+this.getImageUrl(sample)+")"}}></div>
                )}
            </div>
        </div>
      </>
    );
  }
}

export default SampleSlider;