import React from "react";
import $ from "jquery";
import { withRouter } from "react-router";
// reactstrap components
import { Button, Modal } from "reactstrap";
import DotLoader from 'react-spinners/DotLoader';
// Owl Carousel
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

class ProductGallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prevPath : this.props.location.pathname,
      iframeSrc: '',
      currentImageIndex: 0,
      isOpen: false
    };
    this.setImageUrl = this.setImageUrl.bind(this);
  }
  componentDidUpdate(){
    if(this.props.prevPath && this.props.prevPath!==this.props.location.pathname){
      this.setState({
        currentImageIndex: 0,
        prevPath: this.props.location.pathname,
      });
    }
  }
  getFirstImage(images,category_id){
    if(images || category_id){
      if(images.length>0){
        return images[this.state.currentImageIndex];
      }else{
        return 'https://admin.kolpobd.com/images/categories/'+category_id+'.png';
      }
    }
  }
  getImageUrl(image){
    return 'https://admin.kolpobd.com'+image;
  }
  setImageUrl(index){
    this.setState({
      currentImageIndex: index
    });
  }
  toggleModal = state => {
    this.setState({
      [state]: !this.state[state],
      iframeSrc: 'https://admin.kolpobd.com'+this.props.product.sample
    });
  };
  render() {
    return (
      <>
        <div className="gallery">
          <div className="preview-div">
            <img className="preview c-p" src={this.getFirstImage(this.props.images,this.props.product.category_id)} onClick={() => this.setState({ isOpen: true })} />
          </div>
          {this.props.images && this.props.images.length>1 &&
          <div>
            <OwlCarousel className="owl-theme" items={3} autoplay>
              {this.props.images.map((image,index) =>
                <div className="item" key={index} onClick={this.setImageUrl.bind(this,index)}><img src={image} alt={image} /></div>
              )}
            </OwlCarousel>
          </div>
          }
          {this.props.product.sample &&
            <Button className="btn-sample" onClick={() => this.toggleModal("iframeModal")}>Read Sample</Button>
          }
        </div>
        <Modal className="modal-dialog-centered iframe-modal" size="lg" isOpen={this.state.iframeModal} toggle={() => this.toggleModal("iframeModal")}>
          {this.state.spinning && 
          <div className='sweet-loading'>
            <DotLoader css={{display: 'block',margin: '50% auto 0 auto'}} sizeUnit={"px"} size={50} color={'#f0b52e'} loading={this.state.spinning} />
          </div>}
          <div className="modal-body p-0">
            <iframe src={this.state.iframeSrc}></iframe>
          </div>
        </Modal>
        {this.state.isOpen && (
          <Lightbox
            mainSrc={this.props.images.length>0 ? (this.props.images[this.state.currentImageIndex]) : 'https://admin.kolpobd.com/images/categories/'+this.props.product.category_id+'.png'}
            nextSrc={this.props.images.length>0 ? (this.props.images[(this.state.currentImageIndex + 1) % this.props.images.length]) : 'https://admin.kolpobd.com/images/categories/'+this.props.product.category_id+'.png'}
            prevSrc={this.props.images.length>0 ? (this.props.images[(this.state.currentImageIndex + this.props.images.length - 1) % this.props.images.length]) : 'https://admin.kolpobd.com/images/categories/'+this.props.product.category_id+'.png'}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                currentImageIndex: (this.state.currentImageIndex + this.props.images.length - 1) % this.props.images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                currentImageIndex: (this.state.currentImageIndex + 1) % this.props.images.length,
              })
            }
          />
        )}
      </>
    );
  }
}

export default withRouter(ProductGallery);