import React from "react";
import { Link } from "react-router-dom";
import * as product from "./../../assets/js/product.js";
// reactstrap components
import { Row, Col } from "reactstrap";

class BestPublisherCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      category: 'All Categories'
    };
  }
  componentDidMount(){
    
  }
  getImageUrl(image1,image2){
    if(image1){
      return 'https://admin.kolpobd.com'+image1;
    }else{
      return 'https://admin.kolpobd.com'+image2;
    }
  }
  getDiscountText(discount){
    var string = '';
    if(discount){
      if(discount.type=='Fixed'){
        string = parseInt(discount.ammount)+' BDT';
      }else{
        if(discount.type=='Percentage'){
          string = parseInt(discount.ammount)+' %';
        }
      }
      if(discount.product_id>0){
        string = string + ' discount on ' + discount.product_name;
      }else if(discount.sub_sub_sub_sub_sub_category_id>0){
        string = string + ' discount on all ' + discount.sub_sub_sub_sub_sub_category_name;
      }else if(discount.sub_sub_sub_sub_category_id>0){
        string = string + ' discount on all ' + discount.sub_sub_sub_sub_category_name;
      }else if(discount.sub_sub_sub_category_id>0){
        string = string + ' discount on all ' + discount.sub_sub_sub_category_name;
      }else if(discount.sub_sub_category_id>0){
        string = string + ' discount on all ' + discount.sub_sub_category_name;
      }else if(discount.sub_category_id>0){
        string = string + ' discount on all ' + discount.sub_category_name;
      }else if(discount.category_id>0){
        string = string + ' discount on all ' + discount.category_name;
      }else if(discount.feature && discount.feature_value && discount.feature!='' && discount.feature_value!=''){
        string = string + ' discount on all ' + discount.feature_value + "'s products";
      }else if(discount.vendor_id>0){
        string = string + ' discount on all ' + discount.vendor_name + "'s products";
      }
    }
    return string;
  }
  getDiscountUrl(discount){
    var string = '';
    if(discount){
      if(discount.product_id>0){
        string = string + '/product/' + discount.product_id;
      }else if(discount.sub_sub_sub_sub_sub_category_id>0){
        string = string + '/products/' + product.makeSlug(discount.category_name) + '/' + product.makeSlug(discount.sub_category_name) + '/' + product.makeSlug(discount.sub_sub_category_name) + '/' + product.makeSlug(discount.sub_sub_sub_category_name) + '/' + product.makeSlug(discount.sub_sub_sub_sub_category_name) + '/' + product.makeSlug(discount.sub_sub_sub_sub_sub_category_name);
      }else if(discount.sub_sub_sub_sub_category_id>0){
        string = string + '/products/' + product.makeSlug(discount.category_name) + '/' + product.makeSlug(discount.sub_category_name) + '/' + product.makeSlug(discount.sub_sub_category_name) + '/' + product.makeSlug(discount.sub_sub_sub_category_name) + '/' + product.makeSlug(discount.sub_sub_sub_sub_category_name);
      }else if(discount.sub_sub_sub_category_id>0){
        string = string + '/products/' + product.makeSlug(discount.category_name) + '/' + product.makeSlug(discount.sub_category_name) + '/' + product.makeSlug(discount.sub_sub_category_name) + '/' + product.makeSlug(discount.sub_sub_sub_category_name);
      }else if(discount.sub_sub_category_id>0){
        string = string + '/products/' + product.makeSlug(discount.category_name) + '/' + product.makeSlug(discount.sub_category_name) + '/' + product.makeSlug(discount.sub_sub_category_name);
      }else if(discount.sub_category_id>0){
        string = string + '/products/' + product.makeSlug(discount.category_name) + '/' + product.makeSlug(discount.sub_category_name);
      }else if(discount.category_id>0){
        string = string + '/products/' + product.makeSlug(discount.category_name);
      }else if(discount.feature && discount.feature_value && discount.feature!='' && discount.feature_value!=''){
        string = string + '/feature/' + product.makeSlug(discount.feature) + '/' + product.makeSlug(discount.feature_value);
      }else if(discount.vendor_id>0){
        string = string;
      }
    }
    return string;
  }
  render() {
    return (
      <>
        <div className="best-selling-card best-publishers">
          <h4 className="header">Campaigns</h4>
          <div className="item-lists">
            <div className="items">
              {Array.from(this.props.discounts).length>0 ? Array.from(this.props.discounts).map((item,index) =>
                <Link to={this.getDiscountUrl(item)} key={index}>
                  <div className="media">
                    <div className="media-body text-center">
                      <span className="headline mt-2">{item.name}</span>
                      <span className="subtitle color-danger">{this.getDiscountText(item)}</span>
                    </div>
                  </div>
                </Link>
              )
              :
              <div className="media">
                <div className="media-body">
                  <span className="headline mt-2 text-center">No Current Campaign</span>
                </div>
              </div>
              }
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default BestPublisherCard;