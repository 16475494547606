import React from "react";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
// core components
import Navbar from "components/Navbars/Navbar.jsx";
import Loading from "components/Navbars/Loading.jsx";
import Footer from "components/Footers/SimpleFooter.jsx";
// product page sections
import ProductView from "views/product/sections/ProductView.jsx";
import ProductDetails from "views/product/sections/ProductDetails.jsx";
import RelatedProducts from "views/product/sections/RelatedProducts.jsx";
import ShippingCostText from "views/product/sections/ShippingCostText.jsx";
// react redux
// import LoadingBar from 'react-redux-loading-bar';

class Product extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      deliveryCharges: '',
      rating: 0,
      count: 0
    };
    this.setDeliveryCharges = this.setDeliveryCharges.bind(this);
    this.updateRating = this.updateRating.bind(this);
  }
  isLoading = (loadingVal) => {
    this.setState({
      loading: loadingVal
    });
  }
  componentDidMount(){
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  componentDidUpdate(){
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  setDeliveryCharges(deliveryCharges){
    this.setState({
      deliveryCharges: deliveryCharges
    });
  }
  updateRating(rating,count){
    this.setState({
      rating: rating,
      count: count
    });
  }
  render() {
    return (
      <>
        <Navbar />
        <main ref="main">
          <Container>
            <Row>
              <Col lg="12">
                <Row>
                  <Col lg="9">
                    <Row>
                      <Col lg="12">
                        <ProductView loading={this.isLoading} setDeliveryCharges={this.setDeliveryCharges} rating={this.state.rating} count={this.state.count} updateRating={this.updateRating} />
                      </Col>
                      <Col lg="12">
                        <ProductDetails updateRating={this.updateRating} />
                      </Col>
                    </Row>
                  </Col>
                  <Col lg="3">
                    <ShippingCostText deliveryCharges={this.state.deliveryCharges} />
                  </Col>
                </Row>
              </Col>
              <Col lg="9">
                <RelatedProducts />
              </Col>
            </Row>
          </Container>
        </main>
        <Footer />
        {this.state.loading && <Loading /> }
      </>
    );
  }
}

export default Product;