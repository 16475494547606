import React from "react";
// reactstrap components
import { Row, Col } from "reactstrap";
import { withRouter } from "react-router";
import $ from 'jquery';
import Slider from "components/Index/Slider.jsx";
import NewsCollection from "components/Index/NewsCollection.jsx";
import EventCollection from "components/Index/EventCollection.jsx";
import BloodAlert from "components/Index/BloodAlert.jsx";
import CustomOrderCard from "components/Index/CustomOrderCard.jsx";
import BestSellingProductCards from "components/Index/BestSellingProductCards.jsx";
import BestFeatureCard from "components/Index/BestFeatureCard.jsx";
import DiscountCards from "components/Index/DiscountCards.jsx";
import BestUserCard from "components/Index/BestUserCard.jsx";

class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      banners: '',
      customOrderCategories: '',
      categories: '',
      bestSellings: '',
      discounts: '',
      bestSellingFeatures: '',
      users: ''
    };
  }
  componentDidMount(){
    this.itemsCall();
  }
  itemsCall(){
    fetch('https://admin.kolpobd.com/api/site/banners')
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          banners: result.banners,
          news: result.news,
          events: result.events,
          customOrderCategories: result.custom_order_categories,
          categories: result.categories,
          bestSellings: result.best_sellings,
          discounts: result.discounts,
          bestSellingFeatures: result.best_selling_features,
          users: result.users
        },
        ()=>{
          if($(window).width()>768){
            let maxheight = 0;
            $('.best-selling-col .best-selling-card .item-lists').each(function () {
                maxheight = ($(this).height() > maxheight ? $(this).height() : maxheight); 
            });
            $('.best-selling-col .best-selling-card .item-lists').height(maxheight);
            $('.best-selling-col .best-selling-card.best-publishers .item-lists').height(maxheight+36);
            $('.best-selling-col .best-selling-card.best-users .item-lists').height(maxheight+36);
          }
        });
        this.props.loading(false);
      },
      (error) => {
        
      }
    );
  }
  render() {
    return (
      <>
        {/* <section className="banners container">
          <Row>
            <div className="col-lg-9 col-md-12 banner-col main-banner">
              {this.state.banners[1] &&
                <div className="banner-item">
                  <Slider items="1" banners={this.state.banners[1]} />
                </div>
              }
            </div>
            <div className="col-lg-3 col-md-12 banner-col side-banner">
              <Row>
                <div className="col-lg-12 col-md-6 col-6 inside-side-banner">
                  {this.state.banners[2] &&
                    <div className="banner-item">
                      <Slider items="1" banners={this.state.banners[2]} />
                    </div>
                  }
                </div>
                <div className="col-lg-12 col-md-6 col-6 inside-side-banner">
                  {this.state.banners[3] &&
                    <div className="banner-item">
                      <Slider items="1" banners={this.state.banners[3]} />
                    </div>
                  }
                </div>
              </Row>
            </div>
          </Row>
        </section> */}
        <section className='banners'>
          {this.state.banners[1] &&
            <div className="banner-item">
              <Slider items="1" banners={this.state.banners[1]} />
            </div>
          }
        </section>

        <section className="best-selling-cards container">
          <Row>
            <div className="col-lg-4 col-md-6 col-sm-12 best-selling-col">
              <BestSellingProductCards categories={this.state.categories} bestSellings={this.state.bestSellings} />
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 best-selling-col">
              <BestFeatureCard bestSellingFeatures={this.state.bestSellingFeatures} />
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 best-selling-col">
              <DiscountCards discounts={this.state.discounts} />
            </div>
            {/* <div className="col-lg-3 col-md-6 col-sm-12 best-selling-col">
              <BestUserCard users={this.state.users} />
            </div> */}
          </Row>
        </section>

        <section className="custom-order-cards container">
          <div className="section-header">
            <span>Custom Your Order</span>
          </div>
          {/* <h6 className="section-header">Custom Your Order</h6> */}
          <Row>
            {Array.from(this.state.customOrderCategories).map((category,index) =>
              <Col lg="2" md="3" xs="4" className="centered" key={index}>
                <CustomOrderCard category={category} />
              </Col>
            )}
          </Row>
        </section>

        <section className="collections container">
          <Row>
            <Col lg="12">
              {this.state.news && <NewsCollection collection={this.state.news} />}
            </Col>
            <Col lg="12">
              {this.state.events && <EventCollection collection={this.state.events} />}
            </Col>
          </Row>
        </section>
      </>
    );
  }
}

export default withRouter(Banner);