import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { Row, Col } from "reactstrap";

class BestUserCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      category: 'All Categories'
    };
  }
  componentDidMount(){
    
  }
  getImageUrl(image){
    if(image){
      return 'https://admin.kolpobd.com'+image;
    }else{
      return 'https://admin.kolpobd.com'+image;
    }
  }
  render() {
    return (
      <>
        <div className="best-selling-card best-users">
          <h4 className="header">Top Users</h4>
          <div className="item-lists">
            <div className="items">
              {Array.from(this.props.users).map((item,index) =>
                <Link to={'#'} key={index}>
                  <div className="media">
                    <img className="align-self-center mr-2" src={this.getImageUrl(item.image)} />
                    <div className="media-body">
                      <span className="headline mt-2">
                        {item.name}
                        <span className="price"><img className="align-self-center mr-0" src={require("assets/img/icons/coin.png")} />{item.store_credit}</span>
                      </span>
                      {/* <span className="subtitle">{item.email}</span> */}
                    </div>
                  </div>
                </Link>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default BestUserCard;