import React from "react";
// reactstrap components
import { Row, Col, Form, FormGroup, Input, Button, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import { withRouter } from "react-router";
import ReactDatetime from "react-datetime";
import Moment from 'react-moment';
import $ from 'jquery';

import NewsCard from "components/News/NewsCard.jsx";

class NewsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prevPath : this.props.location.pathname,
      items : [],
      campuses: [],
      keyword: '',
      campus_id: 0,
      from: '',
      to: ''
    };
    this.filter = this.filter.bind(this);
  }
  componentDidMount(){
    this.itemsCall();
  }
  componentDidUpdate(){
    if(this.state.prevPath!==this.props.location.pathname){
      this.props.loading(true);
      this.setState({
        prevPath: this.props.location.pathname
      });
      this.itemsCall();
    }
  }
  itemsCall(){
    this.props.loading(true);
    let filter = {
      keyword: this.state.keyword,
      campus_id: this.state.campus_id,
      from: this.state.from,
      to: this.state.to
    }
    fetch('https://admin.kolpobd.com/api/news/'+JSON.stringify(filter))
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          items: result.news.data,
          campuses: result.campuses
        });
        this.props.loading(false);
      },
      (error) => {
        
      }
    );
  }
  filter(e){
    e.preventDefault();
    this.itemsCall();
  }
  reset(e){
    this.itemsCall();
    $('.filter').trigger('reset');
  }
  render() {
    return (
      <>
        <section className="products">
          <Row>
            <Col lg="2">
              <Form onSubmit={this.filter} className="filter" ref="filterForm">
                <div className="products-heading">
                  <span>Filter</span>
                </div>
                <FormGroup>
                  <label>Filter By Title</label>
                  <Input name="keyword" className="form-control-alternative" placeholder="Keyword" type="text" value={this.state.keyword} onChange={e => this.setState({keyword: e.target.value})} />
                </FormGroup>
                <FormGroup>
                  <label>Campus</label>
                  <Input type="select" name="keyword" defaultValue="0" className="form-control-alternative" bsSize="sm" onChange={e => this.setState({campus_id: e.target.value})}>
                    <option value="0">Select Campus</option>
                    {Array.from(this.state.campuses).map((campus)=>
                      <option value={campus.id} key={campus.id}>{campus.name}</option>
                    )}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <label>From</label>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-calendar-grid-58" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <ReactDatetime inputProps={{ placeholder: "Published After" }} timeFormat={false} dateFormat="YYYY-MM-DD" onChange={e => this.setState({from: e.format('YYYY-MM-DD')})} />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <label>To</label>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-calendar-grid-58" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <ReactDatetime inputProps={{ placeholder: "Published Before" }} timeFormat={false} dateFormat="YYYY-MM-DD" onChange={e => this.setState({to: e.format('YYYY-MM-DD')})} />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <Button color="danger" className="pull-right" outline type="button" size="sm" onClick={()=>{this.reset()}}>Reset</Button>
                  <Button color="primary" className="pull-right mr-1" outline type="submit" size="sm">Apply</Button>
                </FormGroup>
              </Form>
            </Col>
            <Col lg="10">
              <Row>
                {this.state.items.map((item) =>
                  <Col md="4" key={item.id}>
                    <NewsCard item={item} />
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </section>
      </>
    );
  }
}

export default withRouter(NewsList);