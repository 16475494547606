import React from "react";
import axios from "axios";
import classnames from "classnames";
// reactstrap components
import { Row, Col, Form, Button, Input, Label, FormGroup, NavItem, NavLink, Nav, Card, CardBody, TabContent, TabPane } from "reactstrap";
import { withRouter, Redirect } from "react-router";
import $ from 'jquery';
import Swal from 'sweetalert2';
import ReactDatetime from "react-datetime";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import SampleSlider from "components/Index/SampleSlider.jsx";
import * as product from "./../../../assets/js/product.js";

class CustomOrderForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: 0,
      categories: [],
      divisions: [],
      districts: [],
      upazilas: [],
      unions: [],
      division_id: 0,
      district_id: 0,
      upazila_id: 0,
      union_id: 0,
      name: '',
      contact: '',
      files: [],
      features: '',
      quantity: 0,
      nameHasDanger: false,
      contactHasDanger: false,
      filesHasDanger: false,
      divisionHasDanger: false,
      districtHasDanger: false,
      featureHasDanger: false,
      quantityHasDanger: false,
      photoIndex: 0,
      categoryIndex: 0,
      isOpen: false,
      samples: [],
      responsive:{
        0: {
          items: 2,
        },
        450: {
          items: 2,
        },
        600: {
          items: 3,
        },
        1000: {
          items: 4,
        },
      },
    };
    this.itemCall = this.itemCall.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount(){
    this.itemCall();
  }
  itemCall(){
    fetch('https://admin.kolpobd.com/api/custom-orders/categories')
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          categories: result.categories,
          divisions: result.divisions,
        });
        var samples = [];
        if(this.state.categories){
          Array.from(this.state.categories).map((category,index) =>{
            samples[index] = [];
            if(category.samples){
              JSON.parse(category.samples).map((sample,sampleIndex) => {
                samples[index][sampleIndex] = 'https://admin.kolpobd.com'+sample;
              });
            }
          });
        }
        this.setState({
          samples: samples
        });
        this.props.loading(false);
      },
      (error) => {
        
      }
    );
  }
  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index,
      division_id: 0,
      district_id: 0,
      name: '',
      contact: '',
      files: [],
      features: '',
      quantity: 0,
      nameHasDanger: false,
      contactHasDanger: false,
      filesHasDanger: false,
      divisionHasDanger: false,
      districtHasDanger: false,
      featureHasDanger: false,
      quantityHasDanger: false
    });
    Array.from(this.state.categories).map((category,index)=>{
      $('#addForm'+index).trigger('reset');
    });
  };
  district(e){
    this.setState({
      division_id: e.target.value
    });
    fetch('https://admin.kolpobd.com/api/geo/district/'+e.target.value)
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          districts: result,
          district_id: 0,
          upazila_id: 0,
          union_id: 0
        });
      },
      (error) => {
        
      }
    );
  }
  upazila(e){
    this.setState({
      district_id: e.target.value
    });
    fetch('https://admin.kolpobd.com/api/geo/upazila/'+e.target.value)
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          upazilas: result,
          upazila_id: 0,
          union_id: 0
        });
      },
      (error) => {
        
      }
    );
  }
  union(e){
    this.setState({
      upazila_id: e.target.value
    });
    fetch('https://admin.kolpobd.com/api/geo/union/'+e.target.value)
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          unions: result,
          union_id: 0
        });
      },
      (error) => {
        
      }
    );
  }
  handleImages= e => { this.setState({ files: e.target.files }); }
  handleName= e => { this.setState({ name: e.target.value }); }
  handleContact= e => { this.setState({ contact: e.target.value }); }
  handleFeatures= e => { this.setState({ features: e.target.value }); }
  handleQuantity= e => { this.setState({ quantity: e.target.value }); }
  isValid(){
    if(this.state.name!='' && this.state.contact!='' && this.state.quantity>0 && this.state.division_id>0 && this.state.district_id>0){
      return true;
    }else{
      if(this.state.name==''){ this.setState({ nameHasDanger: true }); }else{ this.setState({ nameHasDanger: false }); }
      if(this.state.contact==''){ this.setState({ contactHasDanger: true }); }else{ this.setState({ contactHasDanger: false }); }
      if(this.state.features==''){ this.setState({ featureHasDanger: true }); }else{ this.setState({ featureHasDanger: false }); }
      if(this.state.quantity==''){ this.setState({ quantityHasDanger: true }); }else{ this.setState({ quantityHasDanger: false }); }
      // if(this.state.files.length==0){ this.setState({ filesHasDanger: true }); }else{ this.setState({ filesHasDanger: false }); }
      if(this.state.division_id<1){ this.setState({ divisionHasDanger: true }); }else{ this.setState({ divisionHasDanger: false }); }
      if(this.state.district_id<1){ this.setState({ districtHasDanger: true }); }else{ this.setState({ districtHasDanger: false }); }
    }
    return false;
  }
  handleSubmit(e){
    e.preventDefault();
    if(this.isValid()){
      var user_id = localStorage.getItem("user_id");
      if(user_id>0){
        this.props.loading(true);
        const data = new FormData(e.target);
        Array.from(this.state.files).map((file) => {
          data.append('images[]', file);
        });
        data.append('id',user_id);
        axios.post("https://admin.kolpobd.com/api/custom-orders/store", data, {
        }).then(res => {
          if(res.data!=0){
            Swal.fire({
              type: 'success',
              title: 'Order submitted succesfully. Please wait for confirmation',
              showConfirmButton: true
            });
            this.props.loading(false);
            this.props.history.push('/custom-order/'+res.data);
          }else{
            this.props.loading(false);
            alert('Something is wrong. Please try again');
          }
        }).catch(error => {
          this.props.loading(false);
          alert('Something is wrong. Please try again');
        });
      }else{
        $('#loginModalBtn').click();
      }
    }
  }
  getImageUrl(image){
    return 'https://admin.kolpobd.com'+image;
  }
  check(item){
    console.log(item);
  }
  getDescription(description){
    return {__html: description};
  }
  render() {
    return (
      <>
        <div className="sell">
          <Row>
            <Col lg="12">
              <h6 className="heading">Couldn't find what you're looking for? Custom your order</h6>
              <div className="nav-wrapper">
                <Nav className="nav-fill flex-column flex-md-row" id="tabs-icons-text" pills role="tablist" >
                  {this.state.categories && Array.from(this.state.categories).map((category,index) =>
                    <NavItem key={index}>
                      <NavLink aria-selected={this.state.tabs === index} className={classnames("mb-sm-3 mb-md-0", { active: this.state.tabs === index })} onClick={e => this.toggleNavs(e, "tabs", index)} href="#Description" role="tab" >{category.name}</NavLink>
                    </NavItem>
                  )}
                </Nav>
              </div>
              <TabContent activeTab={"tabs" + this.state.tabs}>
                {this.state.categories && Array.from(this.state.categories).map((category,index) =>
                  <TabPane tabId={'tabs'+index} key={index}>
                    <Card className="shadow">
                      <CardBody>
                        <div className="description" dangerouslySetInnerHTML={this.getDescription(category.description)}></div>
                        <Row className="pt-4">
                          {category.samples &&
                            <Col lg="12">
                              <h6 className="heading mb-3">Our Samples</h6>
                              <div className="samples">
                                {/* <OwlCarousel className="owl-theme" responsive={this.state.responsive} autoplay loop>
                                  {Array.from(JSON.parse(category.samples)).map((sample,sampleIndex) =>
                                    <div className="item" key={sampleIndex} onClick={() => this.setState({ isOpen: true, categoryIndex: index, photoIndex: sampleIndex })}><img src={this.getImageUrl(sample)} /></div>
                                  )}
                                </OwlCarousel> */}
                                <SampleSlider samples={category.samples} />
                              </div>
                            </Col>
                          }
                        </Row>
                      </CardBody>
                    </Card>
                    <Card className="shadow mt-3">
                      <CardBody>
                        <h6 className="heading">Place An Order</h6>
                        <Row>
                          <Col lg="12">
                            <Form onSubmit={this.handleSubmit} className="form-with-label" id={'addForm'+index}>
                              <Row>
                                <Col sm="12" lg="12">
                                  <FormGroup>
                                    <Label for="images">Upload Files (pdf/jpg/png) [Up to 5MB] [For larger file please provide Google Drive/OneDrive/Dropbox link below]</Label>
                                    <Input type="file" id="images" onChange={this.handleImages} className={'form-control '+(this.state.filesHasDanger && 'is-invalid')} multiple />
                                  </FormGroup>
                                </Col>
                                <Col sm="12" lg="12">
                                  <FormGroup>
                                    <Label for="file_url">Google Drive/OneDrive/Dropbox link</Label>
                                    <Input name="file_url" id="file_url" placeholder="Google Drive/OneDrive/Dropbox File URL" type="text" />
                                  </FormGroup>
                                </Col>
                                {category.features && Object.entries(JSON.parse(category.features)).map(([index,feature]) =>
                                <Col sm="6" lg="3" key={index}>
                                  <FormGroup>
                                    <Label for="features">{index}</Label>
                                    <Input type="select" name="features[]" className={''+(this.state.featureHasDanger && 'is-invalid')} onChange={this.handleFeatures}>
                                      <option>Select {index}</option>
                                      {Array.from(feature).map((feature_value,index2) =>
                                      <option value={index+':'+feature_value} key={index2}>{feature_value}</option>
                                      )}
                                    </Input>
                                  </FormGroup>
                                </Col>
                                )}
                                <Col sm="6" lg="3">
                                  <FormGroup>
                                    <Label for="quantity">Quantity</Label>
                                    <Input type="number" className="form-control" name="quantity" id="quantity" placeholder="Quantity" className={''+(this.state.quantityHasDanger && 'is-invalid')} onChange={this.handleQuantity} min="1" />
                                  </FormGroup>
                                </Col>
                                <Col sm="12" lg="12">
                                  <FormGroup>
                                    <Label for="description">Description</Label>
                                    <Input type="textarea" className="form-control" name="description" id="description" placeholder="Description"></Input>
                                  </FormGroup>
                                </Col>
                                <Col sm="12" lg="12">
                                  <FormGroup>
                                    <Label for="additional_request">Additional Request</Label>
                                    <Input type="text" className="form-control" name="additional_request" id="additional_request" placeholder="Additional Request"></Input>
                                  </FormGroup>
                                </Col>
                                <Col sm="6" lg="3">
                                  <FormGroup>
                                    <Label for="name">Name</Label>
                                    <Input name="name" id="name" placeholder="Name" type="text" onChange={this.handleName} className={''+(this.state.nameHasDanger && 'is-invalid')} />
                                  </FormGroup>
                                </Col>
                                <Col sm="6" lg="3">
                                  <FormGroup>
                                    <Label for="contact">Contact Number</Label>
                                    <Input name="contact" id="contact" placeholder="Contact" type="text" className={''+(this.state.contactHasDanger && 'is-invalid')} onChange={this.handleContact} />
                                  </FormGroup>
                                </Col>
                                <Col sm="6" lg="3">
                                  <FormGroup>
                                    <Label for="preferable_time_from">Preferable Delivery Time</Label>
                                    <ReactDatetime inputProps={{ placeholder: "From", name: "preferable_time_from", id: "preferable_time_from" }} timeFormat={true} dateFormat={false} />
                                  </FormGroup>
                                </Col>
                                <Col sm="6" lg="3">
                                  <FormGroup>
                                    <Label for="preferable_time_to">&nbsp;</Label>
                                    <ReactDatetime inputProps={{ placeholder: "To", name: "preferable_time_to", id: "preferable_time_to" }} timeFormat={true} dateFormat={false} />
                                  </FormGroup>
                                </Col>
                                <Col sm="6" lg="3">
                                  <FormGroup>
                                    <Label for="division">Division</Label>
                                    <Input type="select" name="division" id="division" className={''+(this.state.divisionHasDanger && 'is-invalid')} onChange={this.district.bind(this)} defaultValue={this.state.division_id}>
                                      <option value="0">Select Division</option>
                                      {Array.from(this.state.divisions).map((item,index) =>
                                        <option value={item.id} key={index}>{item.name_eng}</option>
                                      )}
                                    </Input>
                                  </FormGroup>
                                </Col>
                                <Col sm="6" lg="3">
                                  <FormGroup>
                                    <Label for="district">District</Label>
                                    <Input type="select" name="district" id="district" className={''+(this.state.districtHasDanger && 'is-invalid')} onChange={this.upazila.bind(this)} defaultValue={this.state.district_id}>
                                      <option value="0">Select District</option>
                                      {Array.from(this.state.districts).map((item,index) =>
                                        <option value={item.id} key={index}>{item.name_eng}</option>
                                      )}
                                    </Input>
                                  </FormGroup>
                                </Col>
                                <Col sm="12" lg="6">
                                  <FormGroup>
                                    <Label for="address">Delivery Address</Label>
                                    <Input type="text" className="form-control" name="address" id="address" placeholder="Address"></Input>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <div className="text-center">
                                <Input type="hidden" name="cart" value={product.checkCart()} />
                                <Input type="hidden" name="category_initial" value={category.initial} />
                                <Input type="hidden" name="category_id" value={category.id} />
                                <Input type="hidden" name="user_request" value="0" />
                                <Button className="my-4" color="primary" type="submit">Submit</Button>
                              </div>
                            </Form>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </TabPane>
                )}
              </TabContent>
            </Col>
          </Row>
          {this.state.isOpen && (
            <Lightbox
              mainSrc={this.state.samples[this.state.categoryIndex][this.state.photoIndex]}
              nextSrc={this.state.samples[this.state.categoryIndex][(this.state.photoIndex + 1) % this.state.samples[this.state.categoryIndex].length]}
              prevSrc={this.state.samples[this.state.categoryIndex][(this.state.photoIndex + this.state.samples[this.state.categoryIndex].length - 1) % this.state.samples[this.state.categoryIndex].length]}
              onCloseRequest={() => this.setState({ isOpen: false })}
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex: (this.state.photoIndex + this.state.samples[this.state.categoryIndex].length - 1) % this.state.samples[this.state.categoryIndex].length,
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex: (this.state.photoIndex + 1) % this.state.samples[this.state.categoryIndex].length,
                })
              }
            />
          )}
        </div>
      </>
    );
  }
}

export default withRouter(CustomOrderForm);