import React from "react";
import { Link } from "react-router-dom";
import * as product from "./../../assets/js/product.js";

class BestSellingProductCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }
  componentDidMount(){
    
  }
  render() {
    return (
      <>
        <Link to={"/product/"+this.props.item.id}>
          <div className="media">
            <img className="align-self-start mr-2" src={product.getFirstImage(JSON.parse(this.props.item.images),this.props.item.category_id)} />
            <div className="media-body">
              <span className="headline mt-0">
                {this.props.item.name}
                {/* <span className="price">
                  <span className={this.props.item.discount && 'has-discount text-line-through pr-1'}>{product.getPrice(this.props.item.min_price,this.props.item.max_price)}</span>
                  {this.props.item.discount &&
                    <span className="discount-price">{product.getDiscountedPriceString(this.props.item.min_price,this.props.item.max_price,this.props.item.discount)}</span>
                  }
                </span> */}
              </span>
              {this.props.item.author && <span className="subtitle">By {this.props.item.author}</span>}
              {this.props.item.model && <span className="subtitle">{this.props.item.model}</span>}
            </div>
          </div>
        </Link>
      </>
    );
  }
}

export default BestSellingProductCard;