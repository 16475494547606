import React from "react";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
// core components
import Navbar from "components/Navbars/Navbar.jsx";
import Loading from "components/Navbars/Loading.jsx";
import Footer from "components/Footers/SimpleFooter.jsx";
// product page sections
import EventView from "views/events/sections/EventView.jsx";
// react redux
// import LoadingBar from 'react-redux-loading-bar';

class EventDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }
  isLoading = (loadingVal) => {
    this.setState({
      loading: loadingVal
    });
  }
  componentDidMount(){
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Navbar />
        <main ref="main">
          <Container>
            <Row>
              <Col lg="3">
              
              </Col>
              <Col lg="6">
                <EventView loading={this.isLoading} />
              </Col>
              <Col lg="3">

              </Col>
            </Row>
          </Container>
        </main>
        <Footer />
        {this.state.loading && <Loading /> }
      </>
    );
  }
}

export default EventDetails;