import React from "react";
import $ from "jquery";
// reactstrap components
import { Button } from "reactstrap";
// Owl Carousel
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

class ProductGallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
    this.setImageUrl = this.setImageUrl.bind(this);
  }
  componentDidMount(){
    
  }
  getFirstImage(images){
    if(images!=null){
      return 'https://admin.kolpobd.com'+images[0];
    }else{
      return 'https://admin.kolpobd.com/images/user-products/user-products.png';
    }
  }
  getImageUrl(image){
    return 'https://admin.kolpobd.com'+image;
  }
  setImageUrl(image){
    return function() {
      $('.preview').attr('src','https://admin.kolpobd.com'+image);
    }
  }
  render() {
    return (
      <>
        <div className="gallery">
          <div className="preview-div">
            <img className="preview" src={this.getFirstImage(this.props.images)} alt="Product Gallery" />
          </div>
          {this.props.images && this.props.images.length>1 &&
          <div>
            <OwlCarousel className="owl-theme" items={3} autoplay>
              {Array.from(this.props.images).map((image,index) =>
                <div className="item" key={index} onClick={this.setImageUrl(image)}><img src={this.getImageUrl(image)} alt={image} /></div>
              )}
            </OwlCarousel>
          </div>
          }
        </div>
      </>
    );
  }
}

export default ProductGallery;