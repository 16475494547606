import React from "react";
// reactstrap components
import { Col } from "reactstrap";

class ProductLoadingCard extends React.Component {
  constructor(props) {
    super(props);
  }
  getCount(count){
    var array = [];
    for (var i = count - 1; i >= 0; i--) {
      array.push('item');
    }
    return array;
  }
  render() {
    return (
      <>
        {this.getCount(this.props.loadingCount).map((item,index) =>
          <Col xs={this.props.xs} md={this.props.md} lg={this.props.lg} key={index}>
            <a className="product-card-link" href="#">
              <div className="product-card">
                <div className="product-tumb">
                  
                </div>
                <div className="product-details">
                  <div className="product-top-details">
                    <h4></h4>
                  </div>
                  <div className="product-bottom-details">
                  
                  </div>
                </div>
                <div className="product-loading"></div>
              </div>
            </a>
          </Col>
        )}
      </>
    );
  }
}

export default ProductLoadingCard;