import React from "react";
import $ from "jquery";
import ReactDatetime from "react-datetime";
// reactstrap components
import { Table, FormGroup, Input, Label, Row, Col, FormFeedback } from "reactstrap";

class DeliveryAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      districts: []
    };
  }
  district(e){
    fetch('https://admin.kolpobd.com/api/geo/district/'+e.target.value)
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          districts: result
        });
        $('#district').val('Select District');
      },
      (error) => {
        
      }
    );
  }
  upazila(e){
    this.props.getTotal();
  }
  collapse(item){
    $(item).click();
  }
  render() {
    return (
      <>
        <div className="cart-delivery step-item mb-2" id="deliveryAddressDiv">
          <Row form>
            <Col md="6" lg="3">
              <FormGroup>
                <Label for="name">Name</Label>
                <Input type="text" id="name" name="name" placeholder="Name" onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}/>
                <FormFeedback>Please provide name</FormFeedback>
              </FormGroup>
            </Col>
            <Col md="6" lg="3">
              <FormGroup>
                <Label for="contact">Contact Number</Label>
                <Input type="text" id="contact" name="contact" placeholder="Contact" onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}/>
                <FormFeedback>Please provide contact number</FormFeedback>
              </FormGroup>
            </Col>
            <Col md="6" lg="3">
              <FormGroup>
                <Label for="division">Division</Label>
                <Input type="select" id="division" name="division" onChange={this.district.bind(this)}>
                  <option>Select Division</option>
                  {Array.from(this.props.divisions).map((item,index) =>
                    <option value={item.id} key={index}>{item.name_eng}</option>
                  )}
                </Input>
                <FormFeedback>Please provide division</FormFeedback>
              </FormGroup>
            </Col>
            <Col md="6" lg="3">
              <FormGroup>
                <Label for="district">District</Label>
                <Input type="select" id="district" name="district" onChange={this.upazila.bind(this)}>
                  <option>Select District</option>
                  {Array.from(this.state.districts).map((item,index) =>
                    <option value={item.id} key={index}>{item.name_eng}</option>
                  )}
                </Input>
                <FormFeedback>Please provide district</FormFeedback>
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <FormGroup>
                <Label for="address">Address</Label>
                <Input type="text" id="address" name="address" placeholder="Address" onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}/>
                <FormFeedback>Please provide delivery address</FormFeedback>
              </FormGroup>
            </Col>
            <Col md="6" lg="6">
              <FormGroup>
                <Label for="institute">Institute</Label>
                <Input type="text" id="institute" name="institute" placeholder="Institute" onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}/>
                <FormFeedback>Please provide institute</FormFeedback>
              </FormGroup>
            </Col>
            <Col md="6" lg="3">
              <FormGroup>
                <Label for="preferable_time_from">Preferable delivery time</Label>
                <ReactDatetime inputProps={{ placeholder: "From", name: "preferable_time_from" }} id="preferable_time_from" name="preferable_time_from" timeFormat={true} dateFormat={false} />
              </FormGroup>
            </Col>
            <Col md="6" lg="3">
              <FormGroup>
                <Label for="preferable_time_to"><span>&nbsp;</span></Label>
                <ReactDatetime inputProps={{ placeholder: "To", name: "preferable_time_to" }} id="preferable_time_to" name="preferable_time_to" timeFormat={true} dateFormat={false} />
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <FormGroup>
                <Label for="address">Additional Note</Label>
                <Input type="text" id="note" name="note" placeholder="Note" onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}/>
                <FormFeedback>Please provide delivery note</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <div>
            <button type="button" className="btn btn-success mt-2 pull-right" onClick={this.collapse.bind(this,'.fa-dollar')}>Next <i className="fa fa-arrow-right"></i></button>
            <button type="button" className="btn btn-primary mt-2 mr-2 pull-right" onClick={this.collapse.bind(this,'.fa-list')}><i className="fa fa-arrow-left"></i> Back</button>
          </div>
        </div>
      </>
    );
  }
}

export default DeliveryAddress;