import React from "react";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
// core components
import Navbar from "components/Navbars/Navbar.jsx";
import Loading from "components/Navbars/Loading.jsx";
import Footer from "components/Footers/SimpleFooter.jsx";
// product page sections
import ProductView from "views/userproduct/sections/ProductView.jsx";
import ProductDetails from "views/userproduct/sections/ProductDetails.jsx";
import RelatedProducts from "views/userproduct/sections/RelatedProducts.jsx";
// react redux
// import LoadingBar from 'react-redux-loading-bar';

class Product extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      description: ''
    };
    this.setDescription = this.setDescription.bind(this);
  }
  isLoading = (loadingVal) => {
    this.setState({
      loading: loadingVal
    });
  }
  componentDidMount(){
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  componentDidUpdate(){
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  setDescription(description){
    this.setState({
      description: description
    });
  }
  render() {
    return (
      <>
        <Navbar />
        <main ref="main">
          <Container>
            <Row>
              <Col lg="9">
                <Row>
                  <Col lg="12">
                    <ProductView loading={this.isLoading} setDescription={this.setDescription} />
                  </Col>
                  <Col lg="12">
                    <ProductDetails description={this.state.description} />
                  </Col>
                </Row>
              </Col>
              <Col md="4" lg="3">
                <div className="products related">
                  <Row>
                    <Col lg="12">
                      <div className="products-heading">
                        <span>You may also like</span>
                      </div>
                    </Col>
                    <Col lg="12">
                      <RelatedProducts />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </main>
        <Footer />
        {this.state.loading && <Loading /> }
      </>
    );
  }
}

export default Product;