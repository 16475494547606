import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { Row, Col } from "reactstrap";

class CustomOrderCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }
  componentDidMount(){
    
  }
  getImageUrl(image){
    if(image){
      return 'https://admin.kolpobd.com'+image;
    }else{
      return 'https://admin.kolpobd.com/images/custom-order-categories/categories.png';
    }
  }
  render() {
    return (
      <>
        <div className="custom-order-card">
          <Link to="/custom-order">
            <img src={this.getImageUrl(this.props.category.image)} alt="" />
            <div className="overlay">
              <span>{this.props.category.name}</span>
            </div>
          </Link>
        </div>
      </>
    );
  }
}

export default CustomOrderCard;