import React from "react";
// reactstrap components
import { Card, CardBody, Form, Row, Col, Label, FormGroup, InputGroup, Input, Button } from "reactstrap";
import ReactDatetime from "react-datetime";
import Swal from 'sweetalert2';
import DotLoader from 'react-spinners/DotLoader';
import $ from 'jquery';

class ProfileEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      spinning: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.doneEdit = this.doneEdit.bind(this);
    this.handlePasswordSubmit = this.handlePasswordSubmit.bind(this);
  }
  componentDidMount(){

  }
  getImageUrl(images){
    return 'https://admin.kolpobd.com'+images;
  }
  handleSubmit(e){
    e.preventDefault();
    this.setState({
      spinning: true
    });
    const data = new FormData(e.target);
    fetch('https://admin.kolpobd.com/api/profile/update', {
      method: 'POST',
      body: data,
    }).then(res => res.json())
    .then(
      (result) => {
        if(result==1){
          Swal.fire({
            text: 'Updated',
            type: 'success',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            showCancelButton: false,
            timer: 1500,
            customClass: {
              container: 'toast-container'
            }
          });
          this.setState({
            spinning: false
          });
          this.props.doneEdit();
        }else{
          Swal.fire({
            text: 'Something is wrong. Please try again',
            type: 'error',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            showCancelButton: false,
            timer: 1500,
            customClass: {
              container: 'toast-container'
            }
          });
          this.setState({
            spinning: false
          });
        }
      },
      (error) => {
        Swal.fire({
          text: 'Something is wrong. Please try again',
          type: 'error',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          showCancelButton: false,
          timer: 1500,
          customClass: {
            container: 'toast-container'
          }
        });
        this.setState({
          spinning: false
        });
      }
    );
  }
  handlePasswordSubmit(e){
    e.preventDefault();
    if($('#password').val()==$('#confirm_password').val()){
      this.setState({
        spinning: true
      });
      const data = new FormData(e.target);
      fetch('https://admin.kolpobd.com/api/profile/password/update', {
        method: 'POST',
        body: data,
      }).then(res => res.json())
      .then(
        (result) => {
          console.log(result)
          if(result==1){
            Swal.fire({
              text: 'Updated',
              type: 'success',
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              showCancelButton: false,
              timer: 1500,
              customClass: {
                container: 'toast-container'
              }
            });
            this.setState({
              spinning: false
            });
            this.props.doneEdit();
          }else{
            Swal.fire({
              text: 'Old password didnt match',
              type: 'error',
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              showCancelButton: false,
              timer: 1500,
              customClass: {
                container: 'toast-container'
              }
            });
            this.setState({
              spinning: false
            });
          }
        },
        (error) => {
          Swal.fire({
            text: 'Something is wrong. Please try again.',
            type: 'error',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            showCancelButton: false,
            timer: 1500,
            customClass: {
              container: 'toast-container'
            }
          });
          this.setState({
            spinning: false
          });
        }
      );
    }else{
      alert('Confirm Password didnt match');
    }
  }
  doneEdit(){
    this.props.doneEdit();
  }
  render() {
    return (
      <>
        <Card className="bg-secondary shadow border-0 mb-4">
          {this.state.spinning && 
          <div className='sweet-loading'>
            <DotLoader css={{display: 'block',margin: '30% auto 0 auto'}} sizeUnit={"px"} size={50} color={'#f0b52e'} loading={this.state.spinning} />
          </div>}
          <CardBody>
            <h6><b>Change Password</b></h6>
            <Form onSubmit={this.handlePasswordSubmit}>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <Label><b>Old Password</b></Label>
                    <InputGroup className="input-group-alternative">
                      <Input name="old_password" placeholder="Old Password" type="password" />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label><b>New Password</b></Label>
                    <InputGroup className="input-group-alternative">
                      <Input name="password" id="password" placeholder="New Password" type="password" />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label><b>Confirm Password</b></Label>
                    <InputGroup className="input-group-alternative">
                      <Input name="confirm_password" id="confirm_password" placeholder="Confirm Password" type="password" />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Input type="hidden" name="id" defaultValue={this.props.user.id} />
              <Button className="pull-right" size="sm" color="primary" type="submit">Update</Button>
              <Button className="pull-right mr-1" size="sm" color="warning" onClick={this.doneEdit}>Cancel</Button>
            </Form>
          </CardBody>
        </Card>
        <Card className="bg-secondary shadow border-0">
          {this.state.spinning && 
          <div className='sweet-loading'>
            <DotLoader css={{display: 'block',margin: '30% auto 0 auto'}} sizeUnit={"px"} size={50} color={'#f0b52e'} loading={this.state.spinning} />
          </div>}
          <CardBody>
            <h6><b>Edit Profile</b></h6>
            <Form onSubmit={this.handleSubmit}>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <Label><b>Email</b></Label>
                    <InputGroup className="input-group-alternative">
                      <Input name="email" placeholder="Email" type="email" defaultValue={this.props.user.email} disabled />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label><b>Name</b></Label>
                    <InputGroup className="input-group-alternative">
                      <Input name="name" placeholder="Name" type="text" defaultValue={this.props.user.name} />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label><b>Contact</b></Label>
                    <InputGroup className="input-group-alternative">
                      <Input name="contact" placeholder="Contact" type="text" defaultValue={this.props.user.contact} />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label><b>Date Of Birth</b></Label>
                    <InputGroup className="input-group-alternative">
                    {this.props.user.dob ?
                      <ReactDatetime inputProps={{ placeholder: "Date Of Birth", name: "dob" }} dateFormat="YYYY-MM-DD" timeFormat={false} value={this.props.user.dob} />
                    :
                      <ReactDatetime inputProps={{ placeholder: "Date Of Birth", name: "dob" }} dateFormat="YYYY-MM-DD" timeFormat={false} />
                    }
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label><b>Father's Name</b></Label>
                    <InputGroup className="input-group-alternative">
                      <Input name="father" placeholder="Father's name" type="text" defaultValue={this.props.user.father} />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label><b>Mother's Name</b></Label>
                    <InputGroup className="input-group-alternative">
                      <Input name="mother" placeholder="Mother's Name" type="text" defaultValue={this.props.user.mother} />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label><b>Gender</b></Label>
                    <InputGroup className="input-group-alternative">
                      <Input type="select" name="gender">
                        {this.props.user.gender ? <option disabled>Select Gender</option> : <option selected disabled>Select Gender</option> }
                        {this.props.user.gender==1 ? <option value="1" selected>Male</option> : <option value="1">Male</option>}
                        {this.props.user.gender==2 ? <option value="2" selected>Female</option> : <option value="2">Female</option>}
                      </Input>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label><b>Nationality</b></Label>
                    <InputGroup className="input-group-alternative">
                      <Input name="nationality" placeholder="Nationality" type="text" defaultValue={this.props.user.nationality} />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label><b>NID</b></Label>
                    <InputGroup className="input-group-alternative">
                      <Input name="nid" placeholder="NID" type="text" defaultValue={this.props.user.nid} />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label><b>Passport</b></Label>
                    <InputGroup className="input-group-alternative">
                      <Input name="passport" placeholder="Passport" type="text" defaultValue={this.props.user.passport} />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label><b>Driving License</b></Label>
                    <InputGroup className="input-group-alternative">
                      <Input name="driving" placeholder="Driving License" type="text" defaultValue={this.props.user.driving} />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label><b>Blood Group</b></Label>
                    <InputGroup className="input-group-alternative">
                      <Input type="select" name="blood" defaultValue={this.props.user.blood}>
                        {this.props.user.blood ? <option disabled>Select Blood Group</option> : <option selected disabled>Select Blood Group</option>}
                        <option>A+</option>
                        <option>A-</option>
                        <option>B+</option>
                        <option>B-</option>
                        <option>O+</option>
                        <option>O-</option>
                        <option>AB+</option>
                        <option>AB-</option>
                      </Input>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Input type="hidden" name="id" defaultValue={this.props.user.id} />
              <Button className="pull-right" size="sm" color="primary" type="submit">Update</Button>
              <Button className="pull-right mr-1" size="sm" color="warning" onClick={this.doneEdit}>Cancel</Button>
            </Form>
          </CardBody>
        </Card>
      </>
    );
  }
}

export default ProfileEdit;