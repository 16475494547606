import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {  } from "reactstrap";

class NoData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }
  componentDidMount(){

  }
  getImageUrl(images){
    return 'https://admin.kolpobd.com'+images;
  }
  render() {
    return (
      <>
        <div className="no-data">
          <span>{this.props.msg}</span>
          <Link to={this.props.url} className="btn btn-primary btn-sm">{this.props.urlLabel}</Link>
        </div>
      </>
    );
  }
}

export default NoData;