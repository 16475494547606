import React from "react";
import Moment from 'react-moment';
// reactstrap components
import { Badge } from "reactstrap";

class EventRead extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }
  componentDidMount(){
    
  }
  getTags(tags){
    // var tags = tags.split(",");
    return tags;
  }
  getDescription(){
    return {__html: this.props.event.details};
  }
  render() {
    return (
      <>
        <div className="news">
          <p className="news-topic">
            <span className="pull-right"><i className="fa fa-calendar mr-1"></i><Moment format="D MMM, YYYY" date={this.props.event.start} /> - {this.props.event.end && <Moment format="D MMM, YYYY" date={this.props.event.end} />}</span><br/>
            {this.props.event.campus_name && <span className="pull-right"><i className="fa fa-map-marker mr-1"></i> {this.props.event.campus_name}</span>}
          </p>
          <h1 className="news-title">{this.props.event.title}</h1>
          <p className="news-tags">
            {this.props.event.tags && this.props.event.tags.split(',').map((tag,index) =>
              <Badge className="tags mr-1" color="default" key={index}>{tag}</Badge>
            )}
          </p>
          <p className="news-read" dangerouslySetInnerHTML={this.getDescription()}></p>
        </div>
      </>
    );
  }
}

export default EventRead;