import React from "react";
// reactstrap components
import { Row, Col } from "reactstrap";
import { withRouter } from "react-router";

import ProductDetailsNav from "components/Products/ProductDetailsNav.jsx";

class ProductDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prevPath : this.props.location.pathname,
      description: '',
      features: '',
      reviews: '',
      comments: '',
      package_items: ''
    };
  }
  componentDidMount(){
    this.itemCall();
  }
  componentDidUpdate(){
    if(this.state.prevPath!==this.props.location.pathname){
      this.setState({
        prevPath: this.props.location.pathname
      });
      this.itemCall();
    }
  }
  itemCall(){
    fetch('https://admin.kolpobd.com/api/product/details/'+this.props.match.params.product)
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          description: result.product.description,
          product_id: result.product_id,
          features: result.features,
          reviews: result.reviews,
          comments: result.comments,
          package_items: result.package_items
        });
      },
      (error) => {
        
      }
    );
  }
  render() {
    return (
      <>
        <section className="details mb-5">
          <Row>
            <Col lg="12">
              <ProductDetailsNav description={this.state.description} product_id={this.state.product_id} features={this.state.features} reviews={this.state.reviews} comments={this.state.comments} package_items={this.state.package_items} updateRating={this.props.updateRating} />
            </Col>
          </Row>
        </section>
      </>
    );
  }
}

export default withRouter(ProductDetails);