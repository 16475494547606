import React from "react";
// reactstrap components
import { Col } from "reactstrap";

class FeatureLoadingCard extends React.Component {
  constructor(props) {
    super(props);
  }
  getCount(count){
    var array = [];
    for (var i = count - 1; i >= 0; i--) {
      array.push('item');
    }
    return array;
  }
  render() {
    return (
      <>
        {this.getCount(this.props.loadingCount).map((item,index) =>
          <Col xs={this.props.xs} md={this.props.md} lg={this.props.lg} key={index}>
            <a className="" href="#">
              <div className="feature-value">
                <div className="product-loading"></div>
              </div>
            </a>
          </Col>
        )}
      </>
    );
  }
}

export default FeatureLoadingCard;