import React from "react";
import { Link } from "react-router-dom";

class ProductBarMore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }
  render() {
    return (
      <>
        <Link to={"/search/"+this.props.query}>
          <div className="search-result">
            <div className="media">
              <div className="media-body">
                <span className="headline mt-3 text-center">See More</span>
              </div>
            </div>
          </div>
        </Link>
      </>
    );
  }
}

export default ProductBarMore;