import React from "react";
// reactstrap components
import { Row, Col } from "reactstrap";
import { withRouter } from "react-router";
import Swal from 'sweetalert2';
import $ from "jquery";
import {Helmet} from "react-helmet";

import ProductGallery from "components/Products/ProductGallery.jsx";
import ProductShop from "components/Products/ProductShop.jsx";

class ProductView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prevPath : this.props.location.pathname,
      product: '',
      features : '',
      images : '',
      prices: '',
      wishlist: 0,
      wishlistBtnTxt: 'Add To Wishlist',
      deliveryCharges: '',
      divisions: '',
      title: null
    };
    this.wishlisted = this.wishlisted.bind(this);
  }
  componentDidMount(){
    this.itemCall();
  }
  componentDidUpdate(){
    if(this.state.prevPath!==this.props.location.pathname){
      this.props.loading(true);
      this.setState({
        prevPath: this.props.location.pathname
      });
      $('.quantity-input').val(0);
      this.itemCall();
    }
  }
  itemCall(){
    fetch('https://admin.kolpobd.com/api/product/'+this.props.match.params.product)
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          product: result.product,
          features: result.features,
          prices: result.prices,
          deliveryCharges: result.product.delivery_charges,
          divisions: result.divisions,
          title: result.product.name+' : Kolpo BD'
        });
        var images = [];
        if(result.product.images && result.product.images!='null'){
          JSON.parse(result.product.images).map((image,imageIndex) => {
            images[imageIndex] = 'https://admin.kolpobd.com'+image;
          });
        }
        this.setState({
          images: images
        });
        this.props.updateRating(result.product.rating,result.product.reviews);
        this.props.loading(false);
        this.props.setDeliveryCharges(this.state.deliveryCharges);
        var user_id = localStorage.getItem('user_id');
        if(user_id){
          fetch('https://admin.kolpobd.com/api/wishlist/check/'+this.state.product.id+'/'+user_id)
          .then(res => res.json())
          .then(
            (result) => {
              this.setState({
                wishlist: result,
              });
            },
            (error) => {
              
            }
          );
        }else{
          this.setState({
            wishlist: 0,
          });
        }
      },
      (error) => {
        
      }
    );
  }
  wishlisted(){
    var user_id = localStorage.getItem('user_id');
    if(user_id){
      fetch('https://admin.kolpobd.com/api/wishlist/add/'+this.state.product.id+'/'+user_id)
      .then(res => res.json())
      .then(
        (result) => {
          if(result==1){
            var wishlistBtnTxt = 'Remove From Wishlist';
          }else{
            var wishlistBtnTxt = 'Add To Wishlist';
          }
          this.setState({
            wishlist: result,
            wishlistBtnTxt: wishlistBtnTxt
          });
          if(result==1){
            Swal.fire({
              text: 'Added to Wishlist',
              type: 'success',
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              showCancelButton: false,
              timer: 1500,
              customClass: {
                container: 'toast-container'
              }
            });
          }else{
            Swal.fire({
              text: 'Removed from Wishlist',
              type: 'success',
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              showCancelButton: false,
              timer: 1500,
              customClass: {
                container: 'toast-container'
              }
            });
          }
          
        },
        (error) => {
          
        }
      );
    }else{
      $('#loginModalBtn').click();
    }
  }
  render() {
    return (
      <>
        {this.state.title && <Helmet>
          <title>{this.state.title}</title>
        </Helmet>}
        <section className="product">
          <Row>
            <Col lg="4" md="6">
              <ProductGallery product={this.state.product} images={this.state.images} />
            </Col>
            <Col lg="8" md="6">
              <ProductShop product={this.state.product} divisions={this.state.divisions} features={this.state.features} prices={this.state.prices} wishlist={this.state.wishlist} wishlistBtnTxt={this.state.wishlistBtnTxt} wishlisted={this.wishlisted} rating={this.props.rating} count={this.props.count} />
            </Col>
          </Row>
        </section>
      </>
    );
  }
}

export default withRouter(ProductView);