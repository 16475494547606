import React from "react";
// reactstrap components
import {  } from "reactstrap";

class StatusImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }
  componentDidMount(){
    
  }
  getImageUrl(status){
    return 'https://admin.kolpobd.com/images/order/'+status+'.png';
  }
  getStatusClass(status){
    if(status==0 && this.props.status==-1){
      return 'fa fa-check done';
    }else{
      if(status<=this.props.status){
        return 'fa fa-check done';
      }
      if(status>this.props.status){
        return 'fa fa-check';
      }
    }
  }
  render() {
    return (
      <>
        <div className="status-images-div">
          {this.props.cancel==0 ?
            <ul className="status-images">
              <li>
                <img src={this.getImageUrl(0)} /><br/>
                <i className={this.getStatusClass(0)}></i>
                <p>{this.props.status==-1 ? 'Pre-order Placed' : 'Order Placed'}</p>
              </li>
              <li>
                <img src={this.getImageUrl(1)} /><br/>
                <i className={this.getStatusClass(1)}></i>
                <p>Confirmed</p>
              </li>
              <li>
                <img src={this.getImageUrl(3)} /><br/>
                <i className={this.getStatusClass(3)}></i>
                <p>Processing</p>
              </li>
              <li>
                <img src={this.getImageUrl(4)} /><br/>
                <i className={this.getStatusClass(4)}></i>
                <p>Processed</p>
              </li>
              <li>
                <img src={this.getImageUrl(5)} /><br/>
                <i className={this.getStatusClass(5)}></i>
                <p>Delivering</p>
              </li>
              <li>
                <img src={this.getImageUrl(6)} /><br/>
                <i className={this.getStatusClass(6)}></i>
                <p>Delivered</p>
              </li>
            </ul>
          :
            <ul className="status-images">
              <li>
                <img src={this.getImageUrl('cancel')} /><br/>
                <p className="color-danger">Order Cancelled</p>
              </li>
            </ul>
          }
        </div>
      </>
    );
  }
}

export default StatusImage;