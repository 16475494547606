import React from "react";
import Moment from 'react-moment';
// reactstrap components
import { Badge } from "reactstrap";

class NewsRead extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }
  componentDidMount(){
    
  }
  getTags(tags){
    // var tags = tags.split(",");
    console.log(tags);
  }
  getDescription(){
    return {__html: this.props.news.details};
  }
  render() {
    return (
      <>
        <div className="news">
          <p className="news-topic">
            <span>{this.props.news.topic}</span>
            <span className="pull-right">{this.props.news.campus_name && <span>{this.props.news.campus_name},</span>} <Moment format="D MMM, YYYY" date={this.props.news.created_at} /></span>
          </p>
          <h1 className="news-title">{this.props.news.title}</h1>
          <p className="news-tags">
            {this.props.news.tags && this.props.news.tags.split(',').map((tag,index) =>
              <Badge className="tags mr-1" color="default" key={index}>{tag}</Badge>
            )}
          </p>
          <p className="news-read" dangerouslySetInnerHTML={this.getDescription()}></p>
        </div>
      </>
    );
  }
}

export default NewsRead;