import React from "react";
// reactstrap components
import { Row, Col } from "reactstrap";
import { withRouter } from "react-router";

import NewsGallery from "components/News/NewsGallery.jsx";
import NewsRead from "components/News/NewsRead.jsx";
import NewsComments from "components/News/NewsComments.jsx";

class NewsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prevPath : this.props.location.pathname,
      news: '',
      images: '',
      comments: [],
    };
  }
  componentDidMount(){
    this.itemCall();
  }
  componentDidUpdate(){
    if(this.state.prevPath!==this.props.location.pathname){
      this.props.loading(true);
      this.setState({
        prevPath: this.props.location.pathname
      });
      this.itemCall();
    }
  }
  itemCall(){
    fetch('https://admin.kolpobd.com/api/news/details/'+this.props.match.params.news)
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          news: result,
          images: JSON.parse(result.images)
        });
        this.props.loading(false);
        this.commentsCall();
      },
      (error) => {
        
      }
    );
  }
  commentsCall(){
    fetch('https://admin.kolpobd.com/api/news/comments/'+this.props.match.params.news)
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          comments: result
        });
      },
      (error) => {
        
      }
    );
  }
  render() {
    return (
      <>
        <section className="product">
          <NewsGallery images={this.state.images} />
          <NewsRead news={this.state.news} />
          <div className="division"></div>
          <NewsComments comments={this.state.comments} />
        </section>
      </>
    );
  }
}

export default withRouter(NewsView);