import React from "react";
import { Link } from "react-router-dom";
import * as product from "./../../assets/js/product.js";

class ProductBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }
  render() {
    return (
      <>
        <Link to={"/product/"+this.props.item.id}>
          <div className="search-result">
            <div className="media">
              <img className="align-self-start mr-3" src={product.getFirstImage(JSON.parse(this.props.item.images),this.props.item.category_id)} />
              <div className="media-body">
                <span className="headline mt-0">{this.props.item.name}</span>
                {this.props.item.author && <span className="subtitle">By {this.props.item.author}</span>}
                {this.props.item.model && <span className="subtitle">{this.props.item.model}</span>}
                <span className="price">
                    <span className={this.props.item.discount && 'has-discount text-line-through'}>{product.getPrice(this.props.item.min_price,this.props.item.max_price)}</span>
                    {this.props.item.discount &&
                      <span className="discount-price">{product.getDiscountedPriceString(this.props.item.min_price,this.props.item.max_price,this.props.item.discount)}</span>
                    }
                </span>
              </div>
            </div>
          </div>
        </Link>
      </>
    );
  }
}

export default ProductBar;