import React from "react";
import classnames from "classnames";
import Moment from 'react-moment';
import { Link } from "react-router-dom";
// reactstrap components
import { Card, CardBody, NavItem, NavLink, Nav, TabContent, TabPane, Badge, Table, Button } from "reactstrap";

class OrderDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }
  getFirstImage(images,index){
    if(images!=null){
      return 'https://admin.kolpobd.com'+images[0];
    }else{
      return 'https://admin.kolpobd.com/images/categories/'+this.props.order['items'][index]['category_id']+'.png';
    }
  }
  getItemTotal(index){
    var total = this.props.order['items'][index]['selling_price'] * this.props.order['items'][index]['quantity'];
    return '৳ '+total;
  }
  getStatus(){
    var item = this.props.order;
    var html = '';
    if(item['cancel']==1){
      return <Badge pill color="danger">Cancelled</Badge>;
    }else{
      if(item['status']==0){
        return <Badge pill color="warning">Pending</Badge>;
      }else if(item['status']==1){
        return <Badge pill color="primary">Confirmed</Badge>;
      }else if(item['status']==2){

      }else if(item['status']==3){
        return <Badge pill color="default">Processing</Badge>;
      }else if(item['status']==4){
        return <Badge pill color="default">Processed</Badge>;
      }else if(item['status']==5){
        return <Badge pill color="info">Delivering</Badge>;
      }else if(item['status']==6){
        return <Badge pill color="success">Delivered</Badge>;
      }
    }
    return '';                       
  }
  orders(){
  	this.props.orders();
  }
  render() {
    return (
      <>
        <Card className="shadow">
          <CardBody>
          	<div className="order mb-3">
          		<Button className="btn btn-default btn-sm mr-3" title="Back" onClick={this.orders.bind(this)}><i className="fa fa-arrow-left"></i> Back</Button>
          		<h6 className="d-i">Order #{this.props.order['id']}</h6>
          		<span className="ml-1">{this.getStatus()}</span>
          		<div className="order-details mt-2">
          			<span><b>Address: </b>{this.props.order['address']}, {this.props.order['division_name']}, {this.props.order['district_name']}{this.props.order['upazila_name'] && ', '+this.props.order['upazila_name']}{this.props.order['union_name'] && ', '+this.props.order['union_name']}</span>
          			<span><b>Contact: </b>{this.props.order['contact']}</span>
          		</div>
          	</div>
          	<div className="order-items cart">
          		<Table responsive>
	              <thead>
	                <tr>
  		              <th>#</th>
  		              <th>Product</th>
  		              <th>Price</th>
  		              <th>Quantity</th>
  		              <th>Total</th>
	                </tr>
	              </thead>
	              <tbody>
	              {Object.keys(this.props.order['items']).map(index =>
		            <tr key={index}>
		              	<td>{index+1}</td>
		                <td className="table-user">
		                  <Link to={"/product/"+this.props.order['items'][index]['product_id']}>
		                    <img className="avatar" src={this.getFirstImage(JSON.parse(this.props.order['items'][index]['images']),index)} />
		                    <b>{this.props.order['items'][index]['product_name']}</b> {this.props.order['items'][index]['feature_value'] && '('+this.props.order['items'][index]['feature_value']+')'}
		                  </Link>
		                </td>
		                <td>৳ {this.props.order['items'][index]['selling_price']>0 ? this.props.order['items'][index]['selling_price'] : 0}</td>
		                <td>{this.props.order['items'][index]['quantity']}</td>
		                <td>{this.getItemTotal(index)}</td>
		            </tr>
			       )}
	              </tbody>
	              <thead>
		            <tr>
		                <th></th>
		                <th className="total">SubTotal</th>
		                <th></th>
		                <th></th>
		                <th id="total" className="total">৳ {this.props.order['ammount']}</th>
		            </tr>
		          </thead>
		          <tbody>
	              	<tr>
		                <th></th>
		                <th className="total">Shipping</th>
		                <th></th>
		                <th></th>
		                <th id="total" className="total">৳ {this.props.order['delivery_charge']}</th>
		            </tr>
	              </tbody>
	              <thead>
		            <tr>
		                <th></th>
		                <th className="total">Total</th>
		                <th></th>
		                <th></th>
		                <th id="total" className="total">৳ {this.props.order['ammount']+this.props.order['delivery_charge']}</th>
		            </tr>
		          </thead>
	            </Table>
          	</div>
          </CardBody>
        </Card>
      </>
    );
  }
}

export default OrderDetails;