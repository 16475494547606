import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import * as product from "./../../assets/js/product.js";
import $ from 'jquery';
// reactstrap components
import { Row, Col } from "reactstrap";

class ProductCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }
  getCategoryName(category){
    if(category){
      var words = category.split('_');
      var name = '';
      words.map(function(word,index){
        if(name==''){
          name = word.charAt(0).toUpperCase() + word.slice(1);
        }else{
          name = name.charAt(0).toUpperCase() + name.slice(1)+' '+word.charAt(0).toUpperCase() + word.slice(1);
        }
      });
      if(category==this.props.match.params.category){
        return name;
      }
      return <span><i className="fa fa-arrow-right"></i>{name}</span>;
    }
  }
  getLink(category){
    if(this.props.match.params.sub_sub_sub_sub_sub_category){
      return '#';
    }else if(this.props.match.params.sub_sub_sub_sub_category){
      return '/products/'+this.props.match.params.category+'/'+this.props.match.params.sub_category+'/'+this.props.match.params.sub_sub_category+'/'+this.props.match.params.sub_sub_sub_category+'/'+this.props.match.params.sub_sub_sub_sub_category+'/'+category.slug;
    }else if(this.props.match.params.sub_sub_sub_category){
      return '/products/'+this.props.match.params.category+'/'+this.props.match.params.sub_category+'/'+this.props.match.params.sub_sub_category+'/'+this.props.match.params.sub_sub_sub_category+'/'+category.slug;
    }else if(this.props.match.params.sub_sub_category){
      return '/products/'+this.props.match.params.category+'/'+this.props.match.params.sub_category+'/'+this.props.match.params.sub_sub_category+'/'+category.slug;
    }else if(this.props.match.params.sub_category){
      return '/products/'+this.props.match.params.category+'/'+this.props.match.params.sub_category+'/'+category.slug;
    }else if(this.props.match.params.category){
      return '/products/'+this.props.match.params.category+'/'+category.slug;
    }
    return '/products/'+category.slug;
  }
  getImageUrl(image){
    return 'https://admin.kolpobd.com'+image;
  }
  render() {
    return (
      <>
        {(this.props.location.pathname.split('/')[1]=='products' || this.props.location.pathname.split('/')[1]=='best-selling') ?
          <div>
            {this.props.match.params.category ?
              <div className="product-hierarchy">
                <Link to={'/'}><i className="fa fa-home"></i></Link>
                <Link to={'/products'}><i className="fa fa-arrow-right"></i>Products</Link>
                <Link to={'/products/'+this.props.match.params.category}><i className="fa fa-arrow-right"></i>{this.getCategoryName(this.props.match.params.category)}</Link>
                {this.props.match.params.sub_category && <Link to={'/products/'+this.props.match.params.category+'/'+this.props.match.params.sub_category}>{this.getCategoryName(this.props.match.params.sub_category)}</Link>}
                {this.props.match.params.sub_sub_category && <Link to={'/products/'+this.props.match.params.category+'/'+this.props.match.params.sub_category+'/'+this.props.match.params.sub_sub_category}>{this.getCategoryName(this.props.match.params.sub_sub_category)}</Link>}
                {this.props.match.params.sub_sub_sub_category && <Link to={'/products/'+this.props.match.params.category+'/'+this.props.match.params.sub_category+'/'+this.props.match.params.sub_sub_category+'/'+this.props.match.params.sub_sub_sub_category}>{this.getCategoryName(this.props.match.params.sub_sub_sub_category)}</Link>}
                {this.props.match.params.sub_sub_sub_sub_category && <Link to={'/products/'+this.props.match.params.category+'/'+this.props.match.params.sub_category+'/'+this.props.match.params.sub_sub_category+'/'+this.props.match.params.sub_sub_sub_category+'/'+this.props.match.params.sub_sub_sub_sub_category}>{this.getCategoryName(this.props.match.params.sub_sub_sub_sub_category)}</Link>}
                {this.props.match.params.sub_sub_sub_sub_sub_category && <Link to={'/products/'+this.props.match.params.category+'/'+this.props.match.params.sub_category+'/'+this.props.match.params.sub_sub_category+'/'+this.props.match.params.sub_sub_sub_category+'/'+this.props.match.params.sub_sub_sub_sub_category+'/'+this.props.match.params.sub_sub_sub_sub_sub_category}>{this.getCategoryName(this.props.match.params.sub_sub_sub_sub_sub_category)}</Link>}
                <small className="ml-2">({this.props.count} Products)</small>
              </div>
            :
            <div className="product-hierarchy">
              <Link to={'/'}><i className="fa fa-home"></i></Link>
              <Link to={'/products'}><i className="fa fa-arrow-right"></i>Products</Link>
              <small className="ml-2">({this.props.count} Products)</small>
            </div>
            }
          </div>
        :
          <div>
            {this.props.match.params.feature && this.props.match.params.feature_value &&
              <div className="product-hierarchy">
                <Link to={'/'}><i className="fa fa-home"></i></Link>
                <Link to={'/feature/'+this.props.match.params.feature}><i className="fa fa-arrow-right"></i>{product.removeSlug(this.props.match.params.feature)}</Link>
                <Link to={'/feature/'+this.props.match.params.feature+'/'+this.props.match.params.feature_value}><i className="fa fa-arrow-right"></i>{product.removeSlug(this.props.match.params.feature_value)}</Link>
              </div>
            }
          </div>
        }
        
        
        {!this.props.match.params.feature && this.props.categories && Array.from(this.props.categories).length>0 &&
          <div className="categories">
            <div className="collection-header">
              <h6>Categories</h6>
            </div>
            <Row>
              {Array.from(this.props.categories).map((category,index) =>
                <div className="col-lg-2 col-md-3 col-4 category-folder-col" key={index}>
                  <Link to={this.getLink(category)} key={index}>
                    <div className="category-folder">
                      <div className="thumb">{category.image && <img src={this.getImageUrl(category.image)} />}</div>
                      <div className="header">
                        <h6>{category.name}</h6>
                      </div>
                    </div>
                  </Link>
                </div>
              )}
            </Row>
          </div>
        }
      </>
    );
  }
}

export default withRouter(ProductCategory);