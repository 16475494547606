import React from "react";
import { Link } from "react-router-dom";
import Moment from 'react-moment';
// reactstrap components
import {  } from "reactstrap";

class NewsCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }
  componentDidMount(){

  }
  getFirstImage(images){
    if(images!=null){
      return 'https://admin.kolpobd.com'+images[0];
    }
  }
  getShortDetails(summary){
    if(summary){
      return <span>{summary.substring(0, 100)}&hellip;</span>;
    }else{
      return '';
    }
    
  }
  render() {
    return (
      <>
        <div className="news-card">
          <div className="news-thumb">
            <img src={this.getFirstImage(JSON.parse(this.props.item.images))} alt="" className="d-block w-full" />
          </div>
          <div className="news-card-body">
            <div className="news-topic">
              {this.props.item.tags && this.props.item.tags.split(',').map((tag,index) =>
                <span className="badge tags" key={index}>{tag}</span>
              )}
            </div>
            <h1>{this.props.item.title}</h1>
            <p className="news-topic event-date"><Moment format="D MMM, YYYY" date={this.props.item.created_at} />{this.props.item.campus_name && <span>. {this.props.item.campus_name}</span>}</p>
            <p>{this.getShortDetails(this.props.item.summary)}</p>
          </div>
          <Link className="read-more" to={"/news/"+this.props.item.id}>Read More</Link>
        </div>
      </>
    );
  }
}

export default NewsCard;