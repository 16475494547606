import React from "react";
// reactstrap components
import { Row, Col } from "reactstrap";
import { withRouter } from "react-router";

import ProductGallery from "components/UserProducts/ProductGallery.jsx";
import ProductShop from "components/UserProducts/ProductShop.jsx";

class ProductView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prevPath : this.props.location.pathname,
      product: '',
      images : '',
      isSold: 2
    };
    this.sold = this.sold.bind(this);
  }
  componentDidMount(){
    this.itemCall();
  }
  componentDidUpdate(){
    if(this.state.prevPath!==this.props.location.pathname){
      this.props.loading(true);
      this.setState({
        prevPath: this.props.location.pathname
      });
      this.itemCall();
    }
  }
  itemCall(){
    fetch('https://admin.kolpobd.com/api/user-product/'+this.props.match.params.product)
    .then(res => res.json())
    .then(
      (result) => {
        var sold = this.state.isSold;
        if(result.product.created_by==localStorage.getItem('user_id')){
          if(result.product.sold==0){
            sold = 0;
          }else{
            sold =1;
          }
        }
        this.setState({
          product: result.product,
          images: JSON.parse(result.product.images),
          isSold: sold
        });
        this.props.setDescription(result.product.description);
        this.props.loading(false);
      },
      (error) => {
        
      }
    );
  }
  sold(){
    this.setState({
      isSold: 1
    });
  }
  render() {
    return (
      <>
        <section className="product">
          <Row>
            <Col lg="4">
              <ProductGallery product={this.state.product} images={this.state.images} />
            </Col>
            <Col lg="8">
              <ProductShop product={this.state.product} isSold={this.state.isSold} sold={this.sold} />
            </Col>
          </Row>
        </section>
      </>
    );
  }
}

export default withRouter(ProductView);