import React from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { Button, Badge, Modal, Card, CardHeader, CardBody, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, UncontrolledTooltip } from "reactstrap";
import $ from 'jquery';
import DotLoader from 'react-spinners/DotLoader';
import _ from 'lodash';
import Masonry from 'react-masonry-css';
import classnames from "classnames";
import CalendarButton from "components/Navbars/CalendarButton.jsx";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import ProductBar from "components/Navbars/ProductBar.jsx";
import ProductBarMore from "components/Navbars/ProductBarMore.jsx";
import ProductBarLoading from "components/Navbars/ProductBarLoading.jsx";
import * as product from "./../../assets/js/product.js";

class DemoNavbar extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      prevPath : this.props.location.pathname,
      isLoaded: false,
      spinning: false,
      showSearch: 0,
      categories: [],
      cartItem: 0,
      token: null,
      user: null,
      query: '',
      searchResults: [],
      discounts: [],
      searchResultsLoading: 0,
      abortController: '',
    }
    this.handleRegister = this.handleRegister.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleForgotPassword = this.handleForgotPassword.bind(this);
    this.getSearchResult = this.getSearchResult.bind(this);
    this.handleSearchResultSubmit = this.handleSearchResultSubmit.bind(this);
    this.facebookResponse = this.facebookResponse.bind(this);
    this.googleResponse = this.googleResponse.bind(this);
  }
  componentDidMount() {
    if(localStorage.getItem("user_id")){
      let user = {
        id: localStorage.getItem("user_id"),
        email: localStorage.getItem("user_email"),
        name: localStorage.getItem("user_name"),
        image: localStorage.getItem("user_image"),
        store_credit: localStorage.getItem("user_store_credit")
      };
      this.setState({
        user: user
      });
    }
    if(localStorage.getItem("categories")){
      this.setState({
        categories: JSON.parse(localStorage.getItem("categories"))
      });
    }
    if(localStorage.getItem("cartItem")){
      this.setState({
        cartItem: localStorage.getItem("cartItem")
      });
    }
    this.categoryCall();
    document.addEventListener("touchstart", function(){}, true);
    $(document).click(function(event) {
      if(!$(event.target).hasClass('menu-search-bar input') || !$(event.target).hasClass('menu-search-bar search-results')) {
        $(".search-results").hide();
      }else{
        $(".search-results").show();
      }
    });
  }
  componentDidUpdate(){
    if(this.state.prevPath!==this.props.location.pathname){
      this.setState({
        prevPath: this.props.location.pathname,
      });
      if($('.mega-menu').hasClass('show-mobile')){
        this.collapseAll();
      }
    }
  }
  collapse(e){
    $(e.target).parent().find('.fa:first-child').toggleClass('fa-angle-down').toggleClass('fa-angle-up');
    $(e.target).next('.drop-toggle').slideToggle();
  }
  collapseForIcon(e){
    e.preventDefault();
    $(e.target).toggleClass('fa-angle-down').toggleClass('fa-angle-up');
    $(e.target).parent().parent().find('.drop-toggle').slideToggle();
  }
  collapseAll(e){
    $('.mega-menu').toggleClass('show-mobile');
    $('.menu-links').slideToggle();
    $('.menu-social-bar').slideToggle();
  }
  drop(e){
    $(e.target).next('ul').slideToggle();
    $(e.target).toggleClass('fa-angle-down').toggleClass('fa-angle-up');
  }
  categoryCall(){
    var cart = product.checkCart();
    if(localStorage.getItem("user_id")){
      var user_id = localStorage.getItem("user_id");
    }else{
      var user_id = 0;
    }
    fetch('https://admin.kolpobd.com/api/category/'+cart+'/'+user_id)
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          isLoaded: true,
          categories: result.categories,
          user: result.user,
          cartItem: result.cart_count
        });
        localStorage.setItem('categories', JSON.stringify(result.categories));
        localStorage.setItem('cartItem', result.cart_count);
      },
      (error) => {
        this.setState({
          isLoaded: true,
        });
      }
    );
  }
  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };
  modalSwap(from,to){
    this.toggleModal(from);
    this.toggleModal(to);
  }
  handleLogin(e){
    e.preventDefault();
    this.setState({
      spinning: true
    });
    const data = new FormData(e.target);
    fetch('https://admin.kolpobd.com/api/login', {
      method: 'POST',
      body: data,
    }).then(res => res.json())
    .then(
      (result) => {
        if(result.token){
          this.setState({
            token: result.token,
            user: result.user
          });
          localStorage.setItem('token', this.state.token);
          localStorage.setItem('user_id', this.state.user.id);
          localStorage.setItem('user_name', this.state.user.name);
          localStorage.setItem('user_image', this.state.user.image);
          localStorage.setItem('user_email', this.state.user.email);
          localStorage.setItem('user_store_credit', this.state.user.store_credit);
          this.toggleModal("loginModal");
          this.setState({
            spinning: false
          });
        }else{
          if(result.errors){
            alert('Credentials did not match. Please try again');
          }
          this.setState({
            spinning: false
          });
        }
      },
      (error) => {
        alert('Credentials did not match. Please try again');
        this.setState({
          spinning: false
        });
      }
    );
  }
  handleRegister(e){
    e.preventDefault();
    this.setState({
      spinning: true
    });
    const data = new FormData(e.target);
    fetch('https://admin.kolpobd.com/api/register', {
      method: 'POST',
      body: data,
    }).then(res => res.json())
    .then(
      (result) => {
        if(result.token){
          this.setState({
            token: result.token,
            user: result.user
          });
          localStorage.setItem('token', this.state.token);
          localStorage.setItem('user_id', this.state.user.id);
          localStorage.setItem('user_name', this.state.user.name);
          localStorage.setItem('user_image', this.state.user.image);
          localStorage.setItem('user_email', this.state.user.email);
          localStorage.setItem('user_store_credit', this.state.user.store_credit);
          this.toggleModal("registerModal");
          this.setState({
            spinning: false
          });
        }else{
          if(result.errors){
            alert(result.errors[0]);
          }
          this.setState({
            spinning: false
          });
        }
      },
      (error) => {
        alert('Something is wrong. Please try again');
        this.setState({
          spinning: false
        });
      }
    );
  }
  handleForgotPassword(e){
    e.preventDefault();
    this.toggleModal("forgotPasswordModal");
    const data = new FormData(e.target);
    fetch('https://admin.kolpobd.com/api/sendPasswordLink', {
      method: 'POST',
      body: data,
    }).then(res => res.json())
    .then(
      (result) => {
        if(result==1){
          alert('A link has sent to your email. Please check to reset your password');
        }else if(result='not exist'){
          alert('Email is not registered. Please register');
        }else{
          alert('Something is wrong. Please Send Reset Link Again');
        }
      },
      (error) => {
        alert('Something is wrong. Please Send Reset Link Again');
      }
    );
  }
  facebookResponse(response){
    console.log(response)
  }
  googleResponse(response){
    if(response && response.profileObj && response.profileObj.email){
      var data = new FormData();
      data.append('name',response.profileObj.name);
      data.append('email',response.profileObj.email);
      data.append('provider','google');
      data.append('provider_id',response.profileObj.googleId);
      // data.append('image',response.profileObj.imageUrl);
      this.setState({
        spinning: true
      });
      fetch('https://admin.kolpobd.com/api/social', {
        method: 'POST',
        body: data,
      }).then(res => res.json())
      .then(
        (result) => {
          if(result.token){
            this.setState({
              token: result.token,
              user: result.user
            });
            localStorage.setItem('token', this.state.token);
            localStorage.setItem('user_id', this.state.user.id);
            localStorage.setItem('user_name', this.state.user.name);
            localStorage.setItem('user_image', this.state.user.image);
            localStorage.setItem('user_email', this.state.user.email);
            localStorage.setItem('user_store_credit', this.state.user.store_credit);
            this.toggleModal("loginModal");
            this.setState({
              spinning: false
            });
          }else{
            if(result.errors){
              alert('Credentials did not match. Please try again');
            }
            this.setState({
              spinning: false
            });
          }
        },
        (error) => {
          alert('Credentials did not match. Please try again');
          this.setState({
            spinning: false
          });
        }
      );
    }
  }
  handleLogout(){
    localStorage.removeItem('token');
    localStorage.removeItem('user_id');
    localStorage.removeItem('user_name');
    localStorage.removeItem('user_image');
    localStorage.removeItem('user_email');
    localStorage.removeItem('user_store_credit');
    this.setState({
      token: null,
      user: null
    });
    this.toggleModal("userModal");
    if(this.props.location.pathname=='/profile'){
      window.location.href="/";
    }
  }
  getSearchResult(e){
    e.persist();
    if (!this.debouncedFn) {
      this.debouncedFn =  _.debounce(() => {
        this.setState(
          { keyword: e.target.value },
          () => {
            this.setState({
              query: e.target.value,
              searchResults: [],
              discounts: []
            });
            if(e.target.value!=''){
              this.setState({
                searchResultsLoading: 1
              });
              let filter = {
                keyword: e.target.value,
                order: 'Relevance',
                page: 1,
                limit: 5
              }
              if(this.state.abortController){
                this.state.abortController.abort();
              }
              this.setState({ abortController: new AbortController() },() =>{
                fetch('https://admin.kolpobd.com/api/products/'+JSON.stringify(filter)+'?page='+this.state.page,{
                  method: 'GET',
                  signal: this.state.abortController.signal
                })
                .then(res => res.json())
                .then(
                  (result) => {
                    this.setState({
                      searchResults: result.products.data,
                      discounts: result.discounts,
                      searchResultsLoading: 0,
                      abortController: ''
                    });
                  },
                  (error) => {
                    console.log(error);
                  }
                );
              });
            }else{
              
            }
          }
        );
      }, 400);
    }
    this.debouncedFn();
  }
  checkQuery(e){
    // if(this.state.searchResults.length>0 && e.target.value!=''){
    //   $(".search-results").show();
    // }
  }
  getFirstName(){
    var name = this.state.user.name.split(' ');
    return name[0];
  }
  getImageUrl(image){
    return 'https://admin.kolpobd.com'+image;
  }
  handleSearchResultSubmit(e){
    e.preventDefault();
    this.props.history.push('/search/'+e.target.search.value);
  }
  render() {
    return (
      <>
        <nav id="menu-1" className="mega-menu desktopTopFixed" data-color="">
          <section className="menu-list-items">
            <ul className="menu-logo">
              <li>
                <Link to="/"><img alt="kolpobd" src={require("assets/img/brand/kolpobd.png")} /></Link>
                <div className="menu-mobile-collapse-trigger" onClick={this.collapseAll.bind()}><span></span></div>
              </li>
            </ul>
            <ul className="menu-links">
              <li className={this.props.location.pathname=='/news' && 'active'}><Link to="/news"> <i className="fa fa-newspaper-o"></i> News</Link></li>
              <li className={this.props.location.pathname=='/events' && 'active'}><Link to="/events"> <i className="fa fa-calendar"></i> Events</Link></li>
              <li className={this.props.location.pathname=='/questions' && 'active'}><Link to="/questions"> <i className="ni ni-chat-round mr-1 icon-qa"></i> Q/A</Link></li>
              <li className={this.props.location.pathname=='/payments' && 'active'}><Link to="/payments"> <i className="ni ni-money-coins mr-1 icon-qa"></i> Payments</Link></li>
            </ul>
            <ul className="menu-social-bar menu-social-bar-right">
              <li><a href="https://www.facebook.com/kolpobd.platform" target="_blank"><i className="fa fa-facebook-square"></i></a></li>
              <li><a href="#"><i className="fa fa-instagram"></i></a></li>
              <li>
                {this.state.user ?
                  <Button className="btn-sm btn-neutral btn-icon btn-nav ml-2 mt--1" color="default" type="button" onClick={() => this.toggleModal("userModal")}>
                    <span className="btn-inner--icon"><i className="ni ni-circle-08 mr-1" /></span>
                    <span className="nav-link-inner--text fw-6 ml-1">{this.getFirstName()}</span>
                  </Button>
                :
                  <Button className="btn-sm btn-neutral btn-icon btn-nav ml-3 mt--1" color="default" type="button" id="loginModalBtn" onClick={() => this.toggleModal("loginModal")}>
                    <span className="btn-inner--icon"><i className="ni ni-circle-08 mr-2" /></span>
                    <span className="nav-link-inner--text ml-1">Login</span>
                  </Button>
                }
              </li>
              {this.state.user &&
                <li>
                  <Button className="btn-sm btn-neutral btn-icon btn-nav btn-see-more ml-2 mt--1" color="default" type="button" data-placement="bottom" id="btnSeeMore">
                    <span className="btn-inner--icon"><img className="align-self-center mr-0" src={require("assets/img/icons/coin.png")} /></span>
                    <span className="nav-link-inner--text ml-1">{this.state.user.store_credit}</span>
                  </Button>
                  <UncontrolledTooltip delay={0} placement="bottom" target="btnSeeMore">Store Credit ৳ {this.state.user.store_credit}</UncontrolledTooltip>
                </li>
              }
            </ul>
            <ul className="menu-search-bar active">
              <li>
                <form onSubmit={this.handleSearchResultSubmit.bind(this)}>
                  <label>
                    <input name="search" placeholder="Search" type="text" autoComplete="off" onChange={this.getSearchResult.bind(this)} onFocus={this.checkQuery.bind(this)} /><i className="fa fa-search"></i>
                  </label>
                </form>
              </li>
              {this.state.searchResultsLoading==0 && this.state.searchResults.length>0 &&
                <li className="search-results">
                  {this.state.searchResults.map((item,index) =>
                    <ProductBar item={item} discounts={this.state.discounts} key={index} />
                  )}
                  <ProductBarMore query={this.state.query} />
                </li>
              }
              {this.state.searchResultsLoading==1 &&
                <li className="search-results">
                  <ProductBarLoading items="6" />
                </li>
              }
            </ul>
          </section>
          <section className="menu-list-items second-layer">
            <ul className="menu-links">
            {Array.from(this.state.categories).map((category) =>
              <li className={(this.props.match.params.category && this.props.match.params.category==category.slug) ? 'hoverTrigger active' : 'hoverTrigger'} key={category.id}>
                <Link to={'/products/'+category.slug}>{category.name}
                  {category.sub_categories[0] && <i className="fa fa-angle-down fa-indicator ml-1" onClick={this.collapseForIcon.bind()}></i>}
                </Link>
                {category.sub_categories[0] && <div className="mobileTriggerButton" onClick={this.collapse.bind()}></div>}
                {category.sub_categories[0] &&
                  <div className="drop-down drop-toggle grid-col-12 effect-expand-top">
                    <Masonry className="grid-container" columnClassName="grid-item" breakpointCols={{default: 4, 1400: 4, 1100: 4, 800: 3, 500: 1}}>
                    {category.sub_categories.map((sub_category) =>
                      <div className="grid-item" key={sub_category.name}>
                        <Link to={sub_category.is_feature ? '/feature/'+product.makeSlug(sub_category.name) :'/products/'+category.slug+'/'+sub_category.slug} className={sub_category.sub_sub_categories[0] ? 'grid-item-a hvr-bounce-to-left' : 'grid-item-a hvr-bounce-to-left w-100 without-sub'}>
                          <h4>{sub_category.name}</h4>
                        </Link>
                        {sub_category.sub_sub_categories[0] && <i className="fa fa-angle-down fa-indicator grid-item-i" onClick={this.drop.bind()}></i>}
                        {sub_category.sub_sub_categories[0] && 
                          <ul className="d-n">
                            {sub_category.sub_sub_categories.map((sub_sub_category) =>
                              <Link to={sub_category.is_feature ? product.makeFeatureUrl(sub_category.name,sub_sub_category.name) : '/products/'+category.slug+'/'+sub_category.slug+'/'+sub_sub_category.slug} key={sub_sub_category.name} className="hvr-bounce-to-left without-sub"><li className="fw-6">{sub_sub_category.name}</li></Link>
                            )}
                            {sub_category.is_feature && <Link to={'/feature/'+product.makeSlug(sub_category.name)} className="hvr-bounce-to-left without-sub"><li className="fw-6">See More</li></Link>}
                          </ul>
                        }
                      </div>
                    )}
                    </Masonry>
                  </div>
                }
              </li>
            )}
              <li className={this.props.location.pathname=='/user-request' && 'active'}>
                <Link to={'/user-request'}>Request Product</Link>
              </li>
              <li className={this.props.location.pathname=='/custom-order' && 'active'}>
                <Link to={'/custom-order'}>Custom Order</Link>
              </li>
              <li className={(this.props.location.pathname=='/old-stuffs' || this.props.location.pathname=='/sell') ? 'hoverTrigger active' : 'hoverTrigger'}>
                <Link to={'/old-stuffs'}>Old Stuffs <i className="fa fa-angle-down fa-indicator"></i></Link>
                <div className="mobileTriggerButton" onClick={this.collapse.bind()}></div>
                <ul className="drop-down-multilevel drop-toggle for-old-stuff effect-expand-top pl-0">
                  <li><Link to={'/old-stuffs'}>Buy</Link></li>
                  <li><Link to={'/sell'}>Sell</Link></li>
                </ul>
              </li>
            </ul>
          </section>
        </nav>

        {this.props.location.pathname!='/cart' &&
          <Link to={'/cart'} className="mobile-cart-btn">
            <i className="fa fa-shopping-cart"></i>
            <Badge className="cart-item" color="warning" id="cartItemCountFloating">{this.state.cartItem>0 && <span>{this.state.cartItem}</span>}</Badge>
          </Link>
        }

        <Modal className="modal-dialog-centered login-modal" size="sm" isOpen={this.state.loginModal} toggle={() => this.toggleModal("loginModal")}>
          {this.state.spinning && 
          <div className='sweet-loading'>
            <DotLoader css={{display: 'block',margin: '50% auto 0 auto'}} sizeUnit={"px"} size={50} color={'#f0b52e'} loading={this.state.spinning} />
          </div>}
          <div className="modal-body p-0">
            <Card className="bg-secondary shadow border-0">
              <CardHeader className="bg-white">
                <div className="text-muted text-center mb-2">
                  <small>Sign in with</small>
                </div>
                <div className="btn-wrapper text-center">
                  <FacebookLogin appId="512113849500979" autoLoad={true} authLoad={true} and reauthenticate={true} fields="name,email,picture" callback={this.facebookResponse.bind(this)} render={renderProps => (
                      <Button className="btn-icon mt-2 mb-2" color="neutral" onClick={renderProps.onClick}>
                        <span className="btn-inner--icon mr-1">
                          <img alt="Facebook Login" src={require("assets/img/icons/common/facebook.svg")}/>
                        </span>
                        <span className="btn-inner--text">Facebook</span>
                      </Button>
                    )}
                  />
                  <GoogleLogin clientId="377394890997-gu7f6b0evaa8fh8bug5eoj5lv5qo00th.apps.googleusercontent.com" buttonText="Google" autoLoad={false} onSuccess={this.googleResponse.bind(this)} onFailure={this.googleResponse.bind(this)} className="btn btn-neutral btn-google" />
                </div>
              </CardHeader>
              <CardBody className="">
                <div className="text-center text-muted mb-3">
                  <small>Or sign in with credentials</small>
                </div>
                <Form onSubmit={this.handleLogin}>
                  <FormGroup className={classnames("mb-3", { focused: this.state.emailFocused })} >
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input name="email" placeholder="Email" type="email" onFocus={e => this.setState({ emailFocused: true })} onBlur={e => this.setState({ emailFocused: false })} />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className={classnames({ focused: this.state.passwordFocused })} >
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input name="password" placeholder="Password" type="password" autoComplete="off" 
                        onFocus={e => this.setState({ passwordFocused: true })}
                        onBlur={e => this.setState({ passwordFocused: false })}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input className="custom-control-input" id=" customCheckLogin" type="checkbox"
                    />
                    <label className="custom-control-label" htmlFor=" customCheckLogin"
                    >
                      <span className="text-muted">Remember me</span>
                    </label>
                  </div>
                  <div className="text-center">
                    <Button className="mt-4 mb-1" color="primary" type="submit">Sign in</Button>
                  </div>
                  <div className="text-center mb-3">
                    <small><a href="javaScript:void(0)" onClick={this.modalSwap.bind(this,'loginModal','forgotPasswordModal')}>Forgot Password</a></small>
                  </div>
                  <div className="text-center">
                    <small>New to kolpobd? <a href="javaScript:void(0)" onClick={this.modalSwap.bind(this,'loginModal','registerModal')}>Register</a></small>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>

        <Modal className="modal-dialog-centered login-modal" size="sm" isOpen={this.state.forgotPasswordModal} toggle={() => this.toggleModal("forgotPasswordModal")}>
          {this.state.spinning && 
          <div className='sweet-loading'>
            <DotLoader css={{display: 'block',margin: '50% auto 0 auto'}} sizeUnit={"px"} size={50} color={'#f0b52e'} loading={this.state.spinning} />
          </div>}
          <div className="modal-body p-0">
            <Card className="bg-secondary shadow border-0">
              <CardHeader className="bg-white">
                <div className="text-muted text-center mb-2">
                  <small>Sign in with</small>
                </div>
                <div className="btn-wrapper text-center">
                  <FacebookLogin appId="512113849500979" autoLoad={true} authLoad={true} and reauthenticate={true} fields="name,email,picture" callback={this.facebookResponse.bind(this)} render={renderProps => (
                      <Button className="btn-icon mt-2 mb-2" color="neutral" onClick={renderProps.onClick}>
                        <span className="btn-inner--icon mr-1">
                          <img alt="Facebook Login" src={require("assets/img/icons/common/facebook.svg")}/>
                        </span>
                        <span className="btn-inner--text">Facebook</span>
                      </Button>
                    )}
                  />
                  <GoogleLogin clientId="377394890997-gu7f6b0evaa8fh8bug5eoj5lv5qo00th.apps.googleusercontent.com" buttonText="Google" autoLoad={false} onSuccess={this.googleResponse.bind(this)} onFailure={this.googleResponse.bind(this)} className="btn btn-neutral btn-google" />
                </div>
              </CardHeader>
              <CardBody className="">
                <div className="text-center text-muted mb-3">
                  <small>Forgot your password?</small>
                </div>
                <Form onSubmit={this.handleForgotPassword}>
                  <FormGroup className={classnames("mb-3", { focused: this.state.emailFocused })} >
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input name="email" placeholder="Email" type="email" onFocus={e => this.setState({ emailFocused: true })} onBlur={e => this.setState({ emailFocused: false })} />
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    <Button className="mt-4 mb-1" color="primary" type="submit">Send Reset Link</Button>
                  </div>
                  <div className="text-center mb-3">
                    <small>Already registered? <a href="javaScript:void(0)" onClick={this.modalSwap.bind(this,'forgotPasswordModal','loginModal')}>Login</a></small>
                  </div>
                  <div className="text-center">
                    <small>New to kolpobd? <a href="javaScript:void(0)" onClick={this.modalSwap.bind(this,'forgotPasswordModal','registerModal')}>Register</a></small>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>

        <Modal className="modal-dialog-centered login-modal" size="sm" isOpen={this.state.registerModal} toggle={() => this.toggleModal("registerModal")}>
          {this.state.spinning && 
          <div className='sweet-loading'>
            <DotLoader css={{display: 'block',margin: '50% auto 0 auto'}} sizeUnit={"px"} size={50} color={'#f0b52e'} loading={this.state.spinning} />
          </div>}
          <div className="modal-body p-0">
            <Card className="bg-secondary shadow border-0">
              <CardHeader className="bg-white">
                <div className="text-muted text-center mb-2">
                  <small>Register with</small>
                </div>
                <div className="btn-wrapper text-center">
                  <FacebookLogin appId="512113849500979" autoLoad={true} authLoad={true} and reauthenticate={true} fields="name,email,picture" callback={this.facebookResponse.bind(this)} render={renderProps => (
                    <Button className="btn-icon mt-2 mb-2" color="neutral" onClick={renderProps.onClick}>
                      <span className="btn-inner--icon mr-1">
                        <img alt="Facebook Login" src={require("assets/img/icons/common/facebook.svg")}/>
                      </span>
                      <span className="btn-inner--text">Facebook</span>
                    </Button>
                  )}/>
                  <GoogleLogin clientId="377394890997-gu7f6b0evaa8fh8bug5eoj5lv5qo00th.apps.googleusercontent.com" buttonText="Google" autoLoad={false} onSuccess={this.googleResponse.bind(this)} onFailure={this.googleResponse.bind(this)} className="btn btn-neutral btn-google" />
                </div>
              </CardHeader>
              <CardBody className="">
                <div className="text-center text-muted mb-3">
                  <small>Or register with credentials</small>
                </div>
                <Form onSubmit={this.handleRegister}>
                  <FormGroup className={classnames("mb-3", { focused: this.state.nameFocused })} >
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-satisfied" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input name="name" placeholder="Name" type="text" onFocus={e => this.setState({ nameFocused: true })} onBlur={e => this.setState({ nameFocused: false })} />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className={classnames("mb-3", { focused: this.state.emailFocused })} >
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input name="email" placeholder="Email" type="email" onFocus={e => this.setState({ emailFocused: true })} onBlur={e => this.setState({ emailFocused: false })} />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className={classnames({ focused: this.state.passwordFocused })} >
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input name="password" placeholder="Password" type="password" autoComplete="off" 
                        onFocus={e => this.setState({ passwordFocused: true })}
                        onBlur={e => this.setState({ passwordFocused: false })}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    <Button className="my-4" color="primary" type="submit">Register</Button>
                  </div>
                  <div className="text-center">
                    <small>Already registered? <a href="javaScript:void(0)" onClick={this.modalSwap.bind(this,'registerModal','loginModal')}>Login</a></small>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>

        {this.state.user &&
        <Modal className="modal-dialog-centered login-modal" size="sm" isOpen={this.state.userModal} toggle={() => this.toggleModal("userModal")}>
          {this.state.spinning && 
          <div className='sweet-loading'>
            <DotLoader css={{display: 'block',margin: '50% auto 0 auto'}} sizeUnit={"px"} size={50} color={'#f0b52e'} loading={this.state.spinning} />
          </div>}
          <div className="modal-body p-0">
            <Card className="bg-secondary shadow border-0 profile-card">
              <CardHeader className="bg-white">
                <img className="cover" src={this.getImageUrl('/images/banner.jpg')} alt="Product Gallery" />
                <div className="row justify-content-center">
                  <div className="col-lg-12">
                    <div className="card-profile-image">
                      <img src={this.getImageUrl(this.state.user.image)} className="rounded-circle" />
                    </div>
                  </div>
                </div>
              </CardHeader>
              <CardBody className="">
                <h4><b>{this.state.user.name}</b></h4>
                <span className="db"><i className="fa fa-envelope mr-2" />{this.state.user.email}</span>
                {this.state.user.contact && <span className="db"><i className="fa fa-phone-square mr-2" />{this.state.user.contact}</span>}
                {this.state.user.dob && <span className="db"><i className="fa fa-birthday-cake mr-2" />{this.state.user.contact}</span>}
                <Link className="btn-icon btn-3 profile-btn btn btn-outline-primary btn-sm mt-3" to={'/profile'}>
                  <span className="btn-inner--icon"><i className="fa fa-user" /></span>
                  <span className="btn-inner--text">Profile</span>
                </Link>
                <Button className="btn-icon btn-3 profile-btn" size="sm" color="danger" outline type="button" onClick={this.handleLogout.bind(this)}>
                  <span className="btn-inner--icon"><i className="fa fa-power-off" /></span>
                  <span className="btn-inner--text">Logout</span>
                </Button>
              </CardBody>
            </Card>
          </div>
        </Modal>
        }

      </>
    );
  }
}

export default withRouter(DemoNavbar);