import React from "react";
import { Link } from "react-router-dom";
import Moment from 'react-moment';
// reactstrap components
import {  } from "reactstrap";

class EventCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }
  componentDidMount(){

  }
  getFirstImage(images){
    if(images!=null){
      return 'https://admin.kolpobd.com'+images[0];
    }
  }
  getShortDetails(){
    if(this.props.item.summary){
      return this.props.item.summary.substring(0, 100);
    }else if(this.props.item.details){
      return this.props.item.details.substring(0, 100);
    }else{
      return ' ';
    }
  }
  render() {
    return (
      <>
        <Link to={"/event/"+this.props.item.id}>
        <div className="news-card event-card">
          <div className="news-thumb">
            <img src={this.getFirstImage(JSON.parse(this.props.item.images))} alt="" className="d-block w-full" />
          </div>
          <div className="news-card-body event-card-body">
            <div className="news-topic">
              {this.props.item.tags && this.props.item.tags.split(',').map((tag,index) =>
                <span className="badge tags" key={index}>{tag}</span>
              )}
            </div>
            <h1>{this.props.item.title}</h1>
            <p className="news-topic event-date"><i className="fa fa-calendar mr-1"></i> <Moment format="D MMM, YYYY" date={this.props.item.start} /> - {this.props.item.end && <Moment format="D MMM, YYYY" date={this.props.item.end} />}</p>
            {this.props.item.campus_name && <p className="news-topic event-date"><i className="fa fa-map-marker mr-1"></i><span> {this.props.item.campus_name}</span></p>}
          </div>
        </div>
        </Link>
      </>
    );
  }
}

export default EventCard;