import React from "react";
// reactstrap components
import {  } from "reactstrap";
// Owl Carousel
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

class NewsGallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }
  componentDidMount(){
    
  }
  getImageUrl(image){
    return 'https://admin.kolpobd.com'+image;
  }
  render() {
    return (
      <>
        <div className="gallery news-gallery">
          <OwlCarousel className="owl-theme" items={1} autoplay>
            {Array.from(this.props.images).map((image,index) =>
              <div className="item" key={index}><img src={this.getImageUrl(image)} alt={image} /></div>
            )}
          </OwlCarousel>
        </div>
      </>
    );
  }
}

export default NewsGallery;