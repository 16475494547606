import React from "react";
import Moment from 'react-moment';
import $ from 'jquery';
import Swal from 'sweetalert2';
// reactstrap components
import { Media, Form, Input, Button } from "reactstrap";

class ProductReviewForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: null,
      user_name: null,
      user_image: null,
      post_rating: 0,
    };
  }
  componentDidMount(){
    this.setState({
      user_id: localStorage.getItem('user_id'),
      user_name: localStorage.getItem('user_name'),
      user_image: localStorage.getItem('user_image')
    });
  }
  getImageUrl(image){
    return 'https://admin.kolpobd.com'+image;
  }
  getRating(rating){
    var star = [];
    for(var i=0;i<rating;i++){
      if(i===0){
        star[i] = <i className="fa fa-star ml-2" />;
      }else{
        star[i] = <i className="fa fa-star" />;
      }
    }
    return star;
  }
  changeRating(index,e){
    $(e.currentTarget).addClass('checked');
    $(e.currentTarget).prevAll().addClass('checked');
    $(e.currentTarget).nextAll().removeClass('checked');
    var rating = index+1;
    this.setState({
      post_rating: rating
    });
  }
  validate(data){
    if(this.state.post_rating>0){
      var rating = 1;
    }else{
      var rating = 0;
      alert('Please rate this product');
      return 0;
    }
    if(localStorage.getItem('user_id')){
      var user_id = 1;
    }else{
      var user_id = 0;
      $('#loginModalBtn').click();
    }
    if(rating==1 && user_id==1){
      return 1;
    }
    return 0;
  }
  handleSubmit(e){
    e.preventDefault();
    const data = new FormData(e.target);
    data.append('rating',this.state.post_rating);
    var valid = this.validate(data);
    if(valid==1){
      fetch('https://admin.kolpobd.com/api/reviews/store', {
        method: 'POST',
        body: data,
      }).then(res => res.json())
      .then(
        (result) => {
          if(result==0){
            alert('Something is wrong. Please try again');
          }else{
            Swal.fire({
              text: 'Thanks for your review',
              type: 'success',
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              showCancelButton: false,
              timer: 1500
            });
            localStorage.setItem('user_store_credit',result.store_credit);
            this.props.updateRating(result.total_rating,result.count);
            this.props.newReview(result);
          }
        },
        (error) => {
          alert('Something is wrong. Please try again');
        }
      );
    }
  }
  render() {
    return (
      <>
        <div className="comment">
          <Form onSubmit={this.handleSubmit.bind(this)}>
            <Media>
              <Media left href="#">
                {this.state.user_image && <Media object src={this.getImageUrl(this.state.user_image)} alt="User Image" />}
              </Media>
              <Media body>
                <Media heading>
                  {this.state.user_name && this.state.user_name }
                  <div className="rating-div">
                  Rate: 
                  {Array.from(this.getRating(5)).map((star,index) =>
                    <span className="post-rating" key={index} onClick={this.changeRating.bind(this,index)}>{star}</span>
                  )}
                  </div>
                </Media>
                <Input type="textarea" name="review" placeholder="Write your review" />
                <Input type="hidden" name="product_id" defaultValue={this.props.product_id} />
                <Input type="hidden" name="created_by" defaultValue={this.state.user_id} />
                <Button className="btn-sm pull-right mt-1" color="primary" type="submit"><span className="btn-inner--text">Post</span></Button>
              </Media>
            </Media>
          </Form>
        </div>
      </>
    );
  }
}

export default ProductReviewForm;