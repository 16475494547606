import React from "react";
import { Link } from "react-router-dom";

class NewsEmptyCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }
  render() {
    return (
      <>
        <Link to={"/news"}>
            <div className="news-card news-empty-card">
                <h1>See More <i className="fa fa-arrow-circle-right"></i></h1>
            </div>
        </Link>
      </>
    );
  }
}

export default NewsEmptyCard;