import React from "react";
// reactstrap components
import { Row, Col } from "reactstrap";
import { withRouter, Redirect } from "react-router";
import $ from 'jquery';

import ProfileCard from "components/User/ProfileCard.jsx";
import ProfileDetailsNav from "components/User/ProfileDetailsNav.jsx";
import OrderDetails from "components/User/OrderDetails.jsx";
import CustomOrderDetails from "components/User/CustomOrderDetails.jsx";
import ProfileEdit from "components/User/ProfileEdit.jsx";
import Wishlist from "components/User/Wishlist.jsx";

class UserDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prevPath : this.props.location.pathname,
      user: '',
      showProfileEdit: 0,
      showWishlist: 0,
      showOrderDetails: 0,
      showCustomOrderDetails: 0,
      orders: '',
      customOrders: '',
      userProducts: '',
      wishlist: '',
      discounts: '',
      order: '',
      customOrder: ''
    };
    this.itemCall = this.itemCall.bind(this);
    this.details = this.details.bind(this);
    this.customDetails = this.customDetails.bind(this);
    this.orders = this.orders.bind(this);
    this.edit = this.edit.bind(this);
    this.doneEdit = this.doneEdit.bind(this);
    this.wishlist = this.wishlist.bind(this);
  }
  componentDidMount(){
    if(!localStorage.getItem('user_id')){
      this.props.history.push('/');
    }else{
      this.itemCall();
    }
  }
  itemCall(){
    var user_id = localStorage.getItem('user_id');
    fetch('https://admin.kolpobd.com/api/profile/'+user_id)
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          user: result.user,
          orders: result.order_details,
          customOrders: result.custom_orders,
          userRequests: result.user_requests,
          userProducts: result.user_products,
          wishlist: result.wishlist,
          discounts: result.discounts
        });
        this.props.loading(false);
      },
      (error) => {
        
      }
    );
  }
  details(i){
    this.setState({
      showOrderDetails: 1,
      order: this.state.orders[i]
    });
    // $(window).scrollTop($('#scroll-top').offset().top-50);
  }
  customDetails(i){
    this.setState({
      showCustomOrderDetails: 1,
      customOrder: this.state.customOrders[i]
    });
    // $(window).scrollTop($('#scroll-top').offset().top-50);
  }
  orders(){
    this.setState({
      showOrderDetails: 0,
      showCustomOrderDetails: 0
    });
    // $(window).scrollTop($('#scroll-top').offset().top-50);
  }
  edit(){
    this.setState({
      showProfileEdit: 1,
      showWishlist: 0
    });
  }
  wishlist(){
    this.setState({
      showWishlist: 1,
      showProfileEdit: 0
    });
  }
  doneEdit(){
    this.setState({
      showProfileEdit: 0,
      showWishlist: 0
    });
  }
  render() {
    return (
      <>
        <div className="profile">
          <Row>
            <Col lg="4">
              <ProfileCard user={this.state.user} edit={this.edit} wishlist={this.wishlist}/>
            </Col>
            <Col lg="8" id="scroll-top">
            {this.state.showProfileEdit==0 && this.state.showWishlist==0 ?
              <div>
                {this.state.showOrderDetails==0 && this.state.showCustomOrderDetails==0 ?
                  <ProfileDetailsNav user={this.state.user} edit={this.edit} orderDetails={this.state.orders} customOrders={this.state.customOrders} userRequests={this.state.userRequests} userProducts={this.state.userProducts} details={this.details} customDetails={this.customDetails} />
                :
                  <div>
                    {this.state.showOrderDetails==1 &&
                      <OrderDetails order={this.state.order} orders={this.orders} />
                    }
                    {this.state.showCustomOrderDetails==1 &&
                      <CustomOrderDetails order={this.state.customOrder} orders={this.orders} />
                    }
                  </div>
                }
              </div>
            :
              <div>
                {this.state.showProfileEdit==1 && <ProfileEdit user={this.state.user} doneEdit={this.doneEdit} />}
                {this.state.showWishlist==1 && <Wishlist wishlist={this.state.wishlist} discounts={this.state.discounts} doneEdit={this.doneEdit} />}
              </div>
            }
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default withRouter(UserDetails);