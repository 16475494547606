import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { Row, Col } from "reactstrap";
// Owl Carousel
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import * as product from "./../../assets/js/product.js";

class FeatureCollection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      responsive:{
        0: {
          items: 3,
        },
        450: {
          items: 3,
        },
        600: {
          items: 6,
        },
        1000: {
          items: 10,
        },
      },
    };
  }
  componentDidMount(){
    
  }
  getImageUrl(image,feature_image){
    if(image){
      return 'https://admin.kolpobd.com'+image;
    }else{
      return 'https://admin.kolpobd.com'+feature_image;
    }
  }
  render() {
    return (
      <>
        <div className="collection">
          <div className="collection-header">
            <h6>{this.props.feature.feature.feature}</h6>
          </div>
          <OwlCarousel className="owl-theme" responsive={this.state.responsive} nav={true} dots={false} loop={false}>
              {Array.from(this.props.feature.feature_values).map((feature_value,index) =>
                <div className="item mr-3" key={index}>
                  <Link to={'/feature/'+product.makeSlug(this.props.feature.feature.feature)+'/'+product.makeSlug(feature_value.feature_value)}>
                    <div className="feature-value">
                      <img src={this.getImageUrl(feature_value.image,this.props.feature.feature.image)} />
                      <span>{feature_value.feature_value}</span>
                    </div>
                  </Link>
                </div>
              )}
                <div className="item mr-3">
                  <Link to={'/feature/'+product.makeSlug(this.props.feature.feature.feature)}>
                    <div className="feature-value see-more">
                      <span>See More</span>
                    </div>
                  </Link>
                </div>
          </OwlCarousel>
        </div>
      </>
    );
  }
}

export default FeatureCollection;