import React from "react";
import NewsCard from "components/News/NewsCard.jsx";
import NewsEmptyCard from "components/News/NewsEmptyCard.jsx";
// Owl Carousel
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

class NewsCollection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      responsive:{
        0: {
          items: 2,
        },
        450: {
          items: 2,
        },
        600: {
          items: 3,
        },
        1000: {
          items: 4,
        },
        1200: {
          items: 4,
        }
      },
    };
  }
  render() {
    return (
      <>
        <div className="collection">
          <div className="section-header">
            <span>Recent News</span>
          </div>
          <OwlCarousel className="owl-theme" responsive={this.state.responsive} dots={false} loop={false} autoplay>
              {Array.from(this.props.collection).map((news,index) =>
                <div className="item mr-3" key={index}>
                  <NewsCard item={news} />
                </div>
              )}
              <div className="item mr-3">
                <NewsEmptyCard />
              </div>
          </OwlCarousel>
        </div>
      </>
    );
  }
}

export default NewsCollection;