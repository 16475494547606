import React from "react";
import { Link } from "react-router-dom";

class ProductBarLoading extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }
  getCount(count){
    var array = [];
    for (var i = count - 1; i >= 0; i--) {
      array.push(i);
    }
    return array;
  }
  render() {
    return (
      <>
        {this.getCount(this.props.items).map((item)=>
          <Link to="#" key={item}>
            <div className="search-result-loading">
              
            </div>
          </Link>
        )}
      </>
    );
  }
}

export default ProductBarLoading;