import React from "react";
import classnames from "classnames";
import Moment from 'react-moment';
import { Link } from "react-router-dom";
// reactstrap components
import { Card, CardBody, Badge, Table, Button } from "reactstrap";

class CustomOrderDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }
  getFirstImage(images,index){
    if(images!=null){
      return 'https://admin.kolpobd.com'+images[0];
    }else{
      return 'https://admin.kolpobd.com/images/categories/'+this.props.order['items'][index]['category_id']+'.png';
    }
  }
  getItemTotal(index){
    var total = this.props.order['items'][index]['selling_price'] * this.props.order['items'][index]['quantity'];
    return '৳ '+total;
  }
  getStatus(item){
    var html = '';
    if(item.cancel==1){
      return <Badge pill color="danger">Cancelled</Badge>;
    }else{
      if(item.status==0){
        return <Badge pill color="warning">Pending</Badge>;
      }else if(item.status==1){
        return <Badge pill color="primary">Confirmed</Badge>;
      }else if(item.status==2){

      }else if(item.status==3){
        return <Badge pill color="default">Processing</Badge>;
      }else if(item.status==4){
        return <Badge pill color="default">Processed</Badge>;
      }else if(item.status==5){
        return <Badge pill color="info">Delivering</Badge>;
      }else if(item.status==6){
        return <Badge pill color="success">Delivered</Badge>;
      }
    }
    return '';                       
  }
  orders(){
  	this.props.orders();
  }
  check(item){
    console.log(item);
  }
  render() {
    return (
      <>
        <Card className="shadow">
          <CardBody>
          	<div className="order mb-3">
          		<Button className="btn btn-default btn-sm mr-3" title="Back" onClick={this.orders.bind(this)}><i className="fa fa-arrow-left"></i> Back</Button>
          		<h6 className="d-i">{this.props.order.category_name} Order #{this.props.order.id}</h6>
          		<span className="ml-1">{this.getStatus(this.props.order)}</span>
          		<div className="order-details mt-2">
          			<span><b>Address: </b>{this.props.order.address}{this.props.order.upazila_name && ', '+this.props.order.upazila_name}{this.props.order.union_name && ', '+this.props.order.union_name}, {this.props.order.district_name}, {this.props.order.division_name}</span>
          			<span><b>Contact: </b>{this.props.order.contact}</span>
          		</div>
          	</div>
          	<div className="order-items cart">
          		<Table responsive>
	              <thead>
	                <tr>
  		              <th>Product</th>
                    {this.props.order.features && Object.entries(JSON.parse(this.props.order.features)).map(([index,feature]) => 
                    <th>{index}</th>
                    )}
  		              <th>Quantity</th>
  		              <th>Total</th>
	                </tr>
	              </thead>
	              <tbody>
		            <tr>
		                <td><b>{this.props.order.name}</b></td>
                    {this.props.order.features && Object.entries(JSON.parse(this.props.order.features)).map(([index,feature]) => 
                    <td>{feature}</td>
                    )}
		                <td>{this.props.order.quantity}</td>
		                <td>{this.props.order.price>0 ? <span>৳ {this.props.order.price}</span> : <span>N/A</span>}</td>
		            </tr>
	              </tbody>
	              <thead>
		            <tr>
		                <th className="total">SubTotal</th>
                    <th></th>
		                <th></th>
		                <th id="total" className="total">{this.props.order.price>0 ? <span>৳ {this.props.order.price}</span> : <span>N/A</span>}</th>
		            </tr>
		          </thead>
		          <tbody>
	              	<tr>
		                <th className="total">Shipping</th>
                    <th></th>
		                <th></th>
		                <th id="total" className="total">{this.props.order.delivery_charge>0 ? <span>৳ {this.props.order.delivery_charge}</span> : <span>N/A</span>}</th>
		            </tr>
	              </tbody>
	              <thead>
		            <tr>
		                <th className="total">Total</th>
                    <th></th>
		                <th></th>
		                <th id="total" className="total">{this.props.order.price+this.props.order.delivery_charge>0 ? <span>৳ {this.props.order.price+this.props.order.delivery_charge}</span> : <span>N/A</span>}</th>
		            </tr>
		          </thead>
	            </Table>
          	</div>
          </CardBody>
        </Card>
      </>
    );
  }
}

export default CustomOrderDetails;