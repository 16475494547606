import React from "react";
import classnames from "classnames";
import Moment from 'react-moment';
import { Link } from "react-router-dom";
// reactstrap components
import { Card, CardBody, NavItem, NavLink, Nav, TabContent, TabPane, Badge, Table, Button, Row, Col, Modal, CardHeader, Form, FormGroup, Input, InputGroup } from "reactstrap";
import ProductCard from "components/UserProducts/ProductCard.jsx";
import NoData from "components/User/NoData.jsx";
import { withRouter } from "react-router";

class ProfileDetailsNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: 2,
      paymentItem: '',
      orderTable: '',
      orderPaymentsTable: ''
    };
    this.edit = this.edit.bind(this);
    this.setPayment = this.setPayment.bind(this);
  }
  componentDidUpdate(){
    
  }
  edit(){
    this.props.edit();
  }
  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };
  getPaymentStatus(item){
    var total = item.ammount + item.delivery_charge - item.store_credit - item.coupon_code_discount;
    if(!item['paid'] || item['paid']==0){
      return <Badge color="danger" pill>Pending</Badge>;
    }else if(item['paid']==total){
      return <Badge color="success" pill>Paid</Badge>;
    }else if(item['paid']>0 && item['paid']<total){
      return <Badge color="warning" pill>Partial</Badge>;
    }
    return '';
  }
  getDue(item){
    var due = item.ammount + item.delivery_charge - item.store_credit - item.coupon_code_discount;
    if(!item['paid'] || item['paid']==0){
      return due;
    }else if(item['paid']==due){
      return 0;
    }else if(item['paid']>0 && item['paid']<due){
      return due-item['paid'];
    }
    return due;
  }
  getStatus(item){
    if(item.cancel==1){
      return <Badge pill color="danger">Cancelled</Badge>;
    }else{
      if(item['status']==-1){
        return <Badge pill color="secondary">Pre Order</Badge>;
      }else if(item['status']==0){
        return <Badge pill color="warning">Pending</Badge>;
      }else if(item['status']==1){
        return <Badge pill color="primary">Confirmed</Badge>;
      }else if(item['status']==2){

      }else if(item['status']==3){
        return <Badge pill color="default">Processing</Badge>;
      }else if(item['status']==4){
        return <Badge pill color="default">Processed</Badge>;
      }else if(item['status']==5){
        return <Badge pill color="info">Delivering</Badge>;
      }else if(item['status']==6){
        return <Badge pill color="success">Delivered</Badge>;
      }
    }
    return '';
  }
  details(i){
    this.props.details(i);
  }
  customDetails(item){
    this.props.customDetails(item);
  }
  check(item){
    console.log(item);
  }
  getPrice(item){
    var price = parseInt(item.ammount) + parseInt(item.delivery_charge) - parseInt(item.store_credit) - parseInt(item.coupon_code_discount);
    return <span>৳ {price}</span>;
  }
  getCustomOrderPrice(order){
    if(order.price){
      return <span>৳ {parseInt(order.price*order.quantity)+parseInt(order.delivery_charge)}</span>;
    }
    return <span>N/A</span>;
  }
  getCustomOrderDue(item){
    if(!item.price){
      return 0;
    }
    var due = parseInt(item.price*item.quantity) + parseInt(item.delivery_charge);
    if(!item['paid'] || item['paid']==0){
      return due;
    }else if(item['paid']==due){
      return 0;
    }else if(item['paid']>0 && item['paid']<due){
      return due-item['paid'];
    }
    return due;
  }
  getCustomOrderPaymentStatus(item){
    if(!item.price){
      return <Badge color="secondary" pill>N/A</Badge>
    }
    var total = parseInt(item.price * item.quantity) + parseInt(item.delivery_charge);
    if(!item['paid'] || item['paid']==0){
      return <Badge color="danger" pill>Pending</Badge>;
    }else if(item['paid']==total){
      return <Badge color="success" pill>Paid</Badge>;
    }else if(item['paid']>0 && item['paid']<total){
      return <Badge color="warning" pill>Partial</Badge>;
    }
    return '';
  }
  toggleModal(state,item,orderTable){
    if(item && orderTable){
      this.setPayment(item,orderTable)
    }
    this.setState({
      [state]: !this.state[state]
    });
  };
  setPayment(item,orderTable){
    let orderPaymentsTable = this.state.orderPaymentsTable;
    if(orderTable=='orders'){
      orderPaymentsTable = 'order_payments';
    }else if(orderTable=='custom_orders'){
      orderPaymentsTable = 'custom_order_payments' 
    }
    this.setState({
      paymentItem: item,
      orderTable: orderTable,
      orderPaymentsTable: orderPaymentsTable
    });
  }
  getAmountDue(item){
    let due = 0;
    if(this.state.orderTable=='orders'){
      due = this.getDue(item);
    }else if(this.state.orderTable=='custom_orders'){
      due = this.getCustomOrderDue(item);
    }
    return due;
  }
  render() {
    return (
      <>
        <div className="nav-wrapper pt-0">
          <Nav className="nav-fill flex-column flex-md-row" id="tabs-icons-text" pills role="tablist" >
            <NavItem>
              <NavLink aria-selected={this.state.tabs === 2} className={classnames("mb-sm-3 mb-md-0", { active: this.state.tabs === 2 })} onClick={e => this.toggleNavs(e, "tabs", 2)} href="#orders" role="tab"><i className="fa fa-shopping-cart mr-2" />Orders {this.props.orderDetails && Object.keys(this.props.orderDetails).length && <Badge color="primary" pill>{Object.keys(this.props.orderDetails).length}</Badge>}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink aria-selected={this.state.tabs === 3} className={classnames("mb-sm-3 mb-md-0", { active: this.state.tabs === 3 })} onClick={e => this.toggleNavs(e, "tabs", 3)} href="#custom-orders" role="tab"><i className="fa fa-cogs mr-2" />Custom Orders {this.props.customOrders && Array.from(this.props.customOrders).length>0 && <Badge color="primary" pill>{Array.from(this.props.customOrders).length}</Badge>}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink aria-selected={this.state.tabs === 5} className={classnames("mb-sm-3 mb-md-0", { active: this.state.tabs === 5 })} onClick={e => this.toggleNavs(e, "tabs", 5)} href="#user-requests" role="tab"><i className="fa fa-cogs mr-2" />User Requests {this.props.userRequests && Array.from(this.props.userRequests).length>0 && <Badge color="primary" pill>{Array.from(this.props.userRequests).length}</Badge>}</NavLink>
            </NavItem>
            {this.props.location.pathname=='/profile' &&
            <NavItem>
              <NavLink aria-selected={this.state.tabs === 4} className={classnames("mb-sm-3 mb-md-0", { active: this.state.tabs === 4 })} onClick={e => this.toggleNavs(e, "tabs", 4)} href="#old-stuff" role="tab"><i className="fa fa-briefcase mr-2" />Old Stuffs {this.props.userProducts && Array.from(this.props.userProducts).length>0 && <Badge color="primary" pill>{Array.from(this.props.userProducts).length}</Badge>}</NavLink>
            </NavItem>
            }
            {this.props.location.pathname=='/profile' &&
            <NavItem>
              <NavLink aria-selected={this.state.tabs === 1} className={classnames("mb-sm-3 mb-md-0", { active: this.state.tabs === 1 })} onClick={e => this.toggleNavs(e, "tabs", 1)} href="#description" role="tab" ><i className="ni ni-align-left-2 mr-2" />Info</NavLink>
            </NavItem>
            }
          </Nav>
        </div>
        <Card className="shadow profile-tabs">
          <CardBody>
            <TabContent activeTab={"tabs" + this.state.tabs}>
              <TabPane tabId="tabs2" className="cart">
              {this.props.orderDetails && Object.keys(this.props.orderDetails).length>0 ?
                <Table responsive className="table-hover">
                  <thead>
                    <tr>
                      <th>Order No.</th>
                      <th>Order Date</th>
                      <th>Total Ammount</th>
                      <th>Payment</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                  {Object.keys(this.props.orderDetails).map(index =>
                    <tr key={index}>
                      <td><b>#{this.props.orderDetails[index]['id']}</b></td>
                      <td><Moment format="D MMM, YYYY" date={this.props.orderDetails[index]['created_at']} /></td>
                      <td>{this.getPrice(this.props.orderDetails[index])}</td>
                      <td>
                        {this.getPaymentStatus(this.props.orderDetails[index])}
                        {this.getDue(this.props.orderDetails[index])>0 && <br></br> }
                        {this.getDue(this.props.orderDetails[index])>0 && <Badge color="danger" className="mt-1" pill>Due: {this.getDue(this.props.orderDetails[index])}</Badge>}
                      </td>
                      <td>{this.getStatus(this.props.orderDetails[index])}</td>
                      <td>
                        {this.getDue(this.props.orderDetails[index])>0 && <Button className="btn btn-danger btn-sm" title="Make payment" onClick={() => this.toggleModal("paymentModal",this.props.orderDetails[index],'orders')}>Make payment</Button>}
                        <Link to={'/order/'+this.props.orderDetails[index]['id']} className="btn btn-default btn-sm" title="Details"><i className="fa fa-list"></i></Link>
                      </td>
                    </tr>
                  )}
                  </tbody>
                </Table>
              :
                <NoData msg="You didn't order anything. Browse our products" url="/products" urlLabel="Browse" />
              }
              </TabPane>
              <TabPane tabId="tabs3" className="cart">
              {this.props.customOrders && Array.from(this.props.customOrders).length>0 ?
                <Table responsive className="table-hover">
                  <thead>
                    <tr>
                      <th>Order No.</th>
                      <th>Order Date</th>
                      <th>Total Ammount</th>
                      <th>Payment</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                  {Array.from(this.props.customOrders).map((order,index) =>
                    <tr key={order.id}>
                      <td><b><span className="mr-2">#{order.initial}{order.id}</span></b></td>
                      <td><Moment format="D MMM, YYYY" date={order.created_at} /></td>
                      <td>{this.getCustomOrderPrice(order)}</td>
                      <td>
                        {this.getCustomOrderPaymentStatus(order)}
                        {this.getCustomOrderDue(order)>0 && <br></br>}
                        {this.getCustomOrderDue(order)>0 && <Badge color="danger" className="mt-1" pill>Due: {this.getCustomOrderDue(order)}</Badge>}
                      </td>
                      <td>{this.getStatus(order)}</td>
                      <td>
                        {this.getCustomOrderDue(order)>0 && <Button className="btn btn-danger btn-sm" title="Make payment" onClick={() => this.toggleModal("paymentModal",order,'custom_orders')}>Make payment</Button>}
                        <Link to={'/custom-order/'+order.id} className="btn btn-default btn-sm" title="Details"><i className="fa fa-list"></i></Link>
                      </td>
                    </tr>
                  )}
                  </tbody>
                </Table>
              :
                <NoData msg="Can't find what you're looking for? You can always custom your order" url="/custom-order" urlLabel="Custom Order" />
              }
              </TabPane>
              <TabPane tabId="tabs5" className="cart">
              {this.props.userRequests && Array.from(this.props.userRequests).length>0 ?
                <Table responsive className="table-hover">
                  <thead>
                    <tr>
                      <th>Order No.</th>
                      <th>Order Date</th>
                      <th>Total Ammount</th>
                      <th>Payment</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                  {Array.from(this.props.userRequests).map((order,index) =>
                    <tr key={order.id}>
                      <td><b><span className="mr-2">#UR{order.id}</span></b></td>
                      <td><Moment format="D MMM, YYYY" date={order.created_at} /></td>
                      <td>{this.getCustomOrderPrice(order)}</td>
                      <td>
                        {this.getCustomOrderPaymentStatus(order)}
                        {this.getCustomOrderDue(order)>0 && <br></br>}
                        {this.getCustomOrderDue(order)>0 && <Badge color="danger" className="mt-1" pill>Due: {this.getCustomOrderDue(order)}</Badge>}
                      </td>
                      <td>{this.getStatus(order)}</td>
                      <td>
                        {this.getCustomOrderDue(order)>0 && <Button className="btn btn-danger btn-sm" title="Make payment" onClick={() => this.toggleModal("paymentModal",order,'custom_orders')}>Make payment</Button>}
                        <Link to={'/user-request/'+order.id} className="btn btn-default btn-sm" title="Details"><i className="fa fa-list"></i></Link>
                      </td>
                    </tr>
                  )}
                  </tbody>
                </Table>
              :
                <NoData msg="Can't find what you're looking for? You can always custom your order" url="/custom-order" urlLabel="Custom Order" />
              }
              </TabPane>
              {this.props.location.pathname=='/profile' &&
              <TabPane tabId="tabs4" className="cart">
              {this.props.userProducts && Array.from(this.props.userProducts).length>0 ?
                <Row>
                  {Array.from(this.props.userProducts).map((item,index) =>
                    <Col md="4" lg="3" key={item.id}>
                      <ProductCard item={item} hasSoldOut="1"/>
                    </Col>
                  )}
                </Row>
              :
                <NoData msg="Have Anything Spare That You Don't Need Anymore? Sell It Here" url="/sell" urlLabel="Sell Old Stuff" />
              }
              </TabPane>
              }
              {this.props.location.pathname=='/profile' &&
              <TabPane tabId="tabs1">
                <table className="table align-items-center table-flush table-borderless profile-table mb-0">
                  <tbody className="list">
                    {this.props.user.father &&
                    <tr>
                      <th scope="row">
                        <span className="name mb-0 text-sm">Father's Name</span>
                      </th>
                      <td className="budget">
                          {this.props.user.father}
                      </td>
                    </tr>
                    }
                    {this.props.user.mother &&
                    <tr>
                      <th scope="row">
                        <span className="name mb-0 text-sm">Mother's Name</span>
                      </th>
                      <td className="budget">
                          {this.props.user.mother}
                      </td>
                    </tr>
                    }
                    {this.props.user.present_address || this.props.user.present_division_name || this.props.user.present_district_name || this.props.user.present_upazila_name || this.props.user.present_union_name &&
                    <tr>
                      <th scope="row">
                        <span className="name mb-0 text-sm">Present Address</span>
                      </th>
                      <td className="budget">
                          { this.props.user.present_address }, { this.props.user.present_division_name }, { this.props.user.present_district_name }, { this.props.user.present_upazila_name }, { this.props.user.present_union_name }
                      </td>
                    </tr>
                    }
                    {this.props.user.permanent_address || this.props.user.permanent_division_name || this.props.user.permanent_district_name || this.props.user.permanent_upazila_name || this.props.user.permanent_union_name &&
                    <tr>
                      <th scope="row">
                        <span className="name mb-0 text-sm">Permanent Address</span>
                      </th>
                      <td className="budget">
                          { this.props.user.permanent_address }, { this.props.user.permanent_division_name }, { this.props.user.permanent_district_name }, { this.props.user.permanent_upazila_name }, { this.props.user.permanent_union_name }
                      </td>
                    </tr>
                    }
                    <tr>
                      <th scope="row">
                        <span className="name mb-0 text-sm">Gender</span>
                      </th>
                      <td className="budget">
                        {this.props.user.gender==1 ? 'Male' : 'Female' }
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <span className="name mb-0 text-sm">Nationality</span>
                      </th>
                      <td className="budget">
                          { this.props.user.nationality }
                      </td>
                    </tr>
                    {this.props.user.nid &&
                    <tr>
                      <th scope="row">
                        <span className="name mb-0 text-sm">NID</span>
                      </th>
                      <td className="budget">
                          { this.props.user.nid }
                      </td>
                    </tr>
                    }
                    {this.props.user.passport &&
                    <tr>
                      <th scope="row">
                        <span className="name mb-0 text-sm">Passport No</span>
                      </th>
                      <td className="budget">
                          { this.props.user.passport }
                      </td>
                    </tr>
                    }
                    {this.props.user.driving &&
                    <tr>
                      <th scope="row">
                        <span className="name mb-0 text-sm">Driving Lisence</span>
                      </th>
                      <td className="budget">
                          { this.props.user.driving }
                      </td>
                    </tr>
                    }
                    {this.props.user.created_at &&
                    <tr>
                      <th scope="row">
                        <span className="name mb-0 text-sm">Joined</span>
                      </th>
                      <td className="budget">
                        <Moment format="D MMM, YYYY" date={this.props.user.created_at} />
                      </td>
                    </tr>
                    }
                    {this.props.user.updated_at &&
                    <tr>
                      <th scope="row">
                        <span className="name mb-0 text-sm">Last Updated</span>
                      </th>
                      <td className="budget">
                          <Moment format="D MMM, YYYY" date={this.props.user.updated_at} />
                      </td>
                    </tr>
                    }
                  </tbody>
                </table>
                <div className="profile-table-under">
                  <Button size="sm" color="primary" type="button" onClick={this.edit}>
                    <span className="btn-inner--icon mr-1"><i className="fa fa-edit" /></span>
                    <span className="btn-inner--text">Edit</span>
                  </Button>
                </div>
              </TabPane>
              }
            </TabContent>
          </CardBody>
        </Card>

        <Modal className="modal-dialog-centered login-modal" size="sm" isOpen={this.state.paymentModal} toggle={() => this.toggleModal("paymentModal")}>
          <div className="modal-body p-0">
            <Card className="bg-secondary shadow border-0">
              <CardHeader className="bg-white">
                <div className="text-muted text-center mb-2">
                  <small>Make Payment</small>
                </div>
              </CardHeader>
              <CardBody className="">
                <Form method="post" action="https://admin.kolpobd.com/pay" >
                  <FormGroup className="mb-3" >
                    <InputGroup className="input-group-alternative">
                      <Input name="amount" placeholder="Amount" type="number" min="10" max={this.getAmountDue(this.state.paymentItem)} defaultValue={this.getAmountDue(this.state.paymentItem)} required />
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    <input type="hidden" name="id" value={this.state.paymentItem.id} />
                    <input type="hidden" name="order_table" value={this.state.orderTable} />
                    <input type="hidden" name="order_payment_table" value={this.state.orderPaymentsTable} />
                    <input type="hidden" name="user_id" value={localStorage.getItem('user_id')} />
                    <Button className="mt-4 mb-1" color="primary" type="submit">Pay</Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>
      </>
    );
  }
}

export default withRouter(ProfileDetailsNav);