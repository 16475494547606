import React from "react";
import Moment from 'react-moment';
import Swal from 'sweetalert2';
import $ from 'jquery';
// reactstrap components
import { Media, Form, Input, Button } from "reactstrap";
import Answer from "components/Questions/Answer.jsx";

class Question extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: null,
      user_name: null,
      user_image: null,
      newReplies: [],
      files: []
    };
    this.newReply = this.newReply.bind(this);
  }
  componentDidMount(){
    this.setState({
      user_id: localStorage.getItem('user_id'),
      user_name: localStorage.getItem('user_name'),
      user_image: localStorage.getItem('user_image'),
    });
  }
  getImageUrl(image){
    return 'https://admin.kolpobd.com'+image;
  }
  toggleReply(e){
    $(e.target).parent().parent().find('.reply').slideToggle();
  }
  handleImages= e => { this.setState({ files: e.target.files }); }
  newReply(item){
    var items = this.state.newReplies;
    items.push(item);
    this.setState({
      newReplies: items
    });
  }
  validate(reply){
    if(reply){
      var reply = 1;
    }else{
      var reply = 0;
      alert('Please write your reply');
      return 0;
    }
    if(localStorage.getItem('user_id')){
      var user_id = 1;
    }else{
      var user_id = 0;
      $('#loginModalBtn').click();
    }
    if(reply==1 && user_id==1){
      return 1;
    }
    return 0;
  }
  deletequestion(e){
    const element = e.target;
    Swal.fire({
      title: 'Are you sure?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2dce89',
      cancelButtonColor: '#ec0c38',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        fetch('https://admin.kolpobd.com/api/questions/delete/'+this.props.question.id)
        .then(res => res.json())
        .then(
          (result) => {
            if(result==1){
              Swal.fire({
                text: 'Question Deleted',
                type: 'success',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 1500
              });
              $(element).parent().parent().parent().parent().hide();
            }else{
              Swal.fire({
                text: 'Something is wrong',
                type: 'error',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 1500
              });
            }
          },
          (error) => {
            Swal.fire({
              text: 'Something is wrong',
              type: 'error',
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              showCancelButton: false,
              timer: 1500
            });
          }
        );
      }
    });
  }
  handleSubmit(e){
    e.preventDefault();
    const data = new FormData(e.target);
    var valid = this.validate(e.target.answer.value);
    if(valid==1){
      Array.from(this.state.files).map((file) => {
        data.append('images[]', file);
      });
      data.append('created_by',localStorage.getItem('user_id'));
      fetch('https://admin.kolpobd.com/api/questions/answer', {
        method: 'POST',
        body: data,
      }).then(res => res.json())
      .then(
        (result) => {
          if(result==0){
            Swal.fire({
              text: 'Something is wrong',
              type: 'error',
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              showCancelButton: false,
              timer: 1500
            });
          }else{
            Swal.fire({
              text: 'Answer Added',
              type: 'success',
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              showCancelButton: false,
              timer: 1500
            });
            this.newReply(result);
            $('.addForm').trigger('reset');
          }
        },
        (error) => {
          Swal.fire({
            text: 'Something is wrong',
            type: 'error',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            showCancelButton: false,
            timer: 1500
          });
        }
      );
    }
  }
  check(item){
    console.log(item);
  }
  render() {
    return (
      <>
        <div className="comment">
          <Media>
            <Media left href="#">
              <Media object src={this.getImageUrl(this.props.question.image)} alt="User Image" />
            </Media>
            <Media body>
              <Media heading>
                {this.props.question.name} <small className="pull-right"><Moment format="D MMM, YYYY" date={this.props.question.created_at} /></small>
              </Media>
              <span className="comment-span db">{this.props.question.question}</span>
              <div className="mt-1 mb-1">
                {this.props.question.tags && this.props.question.tags.split(",").map((tag,index) =>
                  <span className="badge badge-dark mr-1" key={index}>{tag}</span>
                )}
              </div>
              <div className="actions">
                <a href="javascript:void(0)" onClick={this.toggleReply.bind(this)}>Answer</a>
                {localStorage.getItem('user_id')==this.props.question.created_by ? 
                <a className="ml-2" href="javascript:void(0)" onClick={this.deletequestion.bind(this)}>Delete</a> : ''}
              </div>
              {this.props.question.answers && this.props.question.answers.map((answer,index) =>
                <Answer answer={answer} key={index} />
              )}
              {this.state.newReplies.map((answer,index) =>
                <Answer answer={answer} key={index} />
              )}
              <div className={"comment reply" + (this.state.user_id ? '' : ' reply-form')}>
                <Form onSubmit={this.handleSubmit.bind(this)} className="addForm">
                  <Media>
                    <Media left href="#">
                      {this.state.user_image && <Media object src={this.getImageUrl(this.state.user_image)} alt="User Image" />}
                    </Media>
                    <Media body>
                      <Media heading>
                        {this.state.user_name && this.state.user_name}
                      </Media>
                      <Input type="textarea" name="answer" placeholder="Write your Answer" />
                      <Input type="file" className="form-control mt-1" onChange={this.handleImages} name="images" placeholder="Images" multiple />
                      <Input type="hidden" name="question_id" defaultValue={this.props.question.id} />
                      <Button className="btn-sm pull-right mt-1" color="primary" type="submit"><span className="btn-inner--text">Answer</span></Button>
                    </Media>
                  </Media>
                </Form>
              </div>
            </Media>
          </Media>
        </div>
      </>
    );
  }
}

export default Question;