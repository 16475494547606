import React from "react";
import axios from "axios";
// reactstrap components
import { Row, Col, Form, Button, Input, Label, FormGroup, Card, CardBody } from "reactstrap";
import { withRouter } from "react-router";
import $ from 'jquery';
import Swal from 'sweetalert2';
import * as product from "./../../../assets/js/product.js";

class UserRequestForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: 0,
      categories: [],
      divisions: [],
      districts: [],
      upazilas: [],
      unions: [],
      division_id: 0,
      district_id: 0,
      upazila_id: 0,
      union_id: 0,
      product_name: '',
      name: '',
      contact: '',
      files: [],
      features: '',
      quantity: 0,
      productNameHasDanger: false,
      nameHasDanger: false,
      contactHasDanger: false,
      filesHasDanger: false,
      divisionHasDanger: false,
      districtHasDanger: false,
      featureHasDanger: false,
      quantityHasDanger: false,
      photoIndex: 0,
      categoryIndex: 0,
      isOpen: false,
      samples: [],
      responsive:{
        0: {
          items: 2,
        },
        450: {
          items: 2,
        },
        600: {
          items: 3,
        },
        1000: {
          items: 4,
        },
      },
    };
    this.itemCall = this.itemCall.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount(){
    this.itemCall();
    this.props.loading(false);
  }
  itemCall(){
    fetch('https://admin.kolpobd.com/api/custom-orders/categories')
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          categories: result.categories,
          divisions: result.divisions,
        });
        this.props.loading(false);
      },
      (error) => {
        
      }
    );
  }
  district(e){
    this.setState({
      division_id: e.target.value
    });
    fetch('https://admin.kolpobd.com/api/geo/district/'+e.target.value)
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          districts: result,
          district_id: 0,
          upazila_id: 0,
          union_id: 0
        });
      },
      (error) => {
        
      }
    );
  }
  upazila(e){
    this.setState({
      district_id: e.target.value
    });
    fetch('https://admin.kolpobd.com/api/geo/upazila/'+e.target.value)
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          upazilas: result,
          upazila_id: 0,
          union_id: 0
        });
      },
      (error) => {
        
      }
    );
  }
  union(e){
    this.setState({
      upazila_id: e.target.value
    });
    fetch('https://admin.kolpobd.com/api/geo/union/'+e.target.value)
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          unions: result,
          union_id: 0
        });
      },
      (error) => {
        
      }
    );
  }
  handleImages= e => { this.setState({ files: e.target.files }); }
  handleName= e => { this.setState({ name: e.target.value }); }
  handleProductName= e => { this.setState({ product_name: e.target.value }); }
  handleContact= e => { this.setState({ contact: e.target.value }); }
  handleFeatures= e => { this.setState({ features: e.target.value }); }
  handleQuantity= e => { this.setState({ quantity: e.target.value }); }
  isValid(){
    let valid = false;
    if(this.state.product_name!='' && this.state.name!='' && this.state.contact!=''){
      this.setState({ nameHasDanger: false, productNameHasDanger: false, contactHasDanger: false });
      valid = true;
    }else{
      if(this.state.product_name==''){ this.setState({ productNameHasDanger: true }); }else{ this.setState({ productNameHasDanger: false }); }
      if(this.state.name==''){ this.setState({ nameHasDanger: true }); }else{ this.setState({ nameHasDanger: false }); }
      if(this.state.contact==''){ this.setState({ contactHasDanger: true }); }else{ this.setState({ contactHasDanger: false }); }
    }
    return valid;
  }
  handleSubmit(e){
    e.preventDefault();
    if(this.isValid()){
      var user_id = localStorage.getItem("user_id");
      if(user_id>0){
        this.props.loading(true);
        const data = new FormData(e.target);
        data.append('id', user_id);
        Array.from(this.state.files).map((file) => {
          data.append('images[]', file);
        });
        axios.post("https://admin.kolpobd.com/api/custom-orders/store", data, {
        }).then(res => {
          if(res.data!=0){
            Swal.fire({
              type: 'success',
              title: 'Request submitted succesfully. Please wait for confirmation',
              showConfirmButton: true
            });
            this.props.loading(false);
            $('#addForm').trigger('reset');
          }else{
            this.props.loading(false);
            alert('Something is wrong. Please try again');
          }
        }).catch(error => {
          this.props.loading(false);
          alert('Something is wrong. Please try again');
        });
      }else{
        $('#loginModalBtn').click();
      }
    }
  }
  getImageUrl(image){
    return 'https://admin.kolpobd.com'+image;
  }
  check(item){
    console.log(item);
  }
  getDescription(description){
    return {__html: description};
  }
  render() {
    return (
      <>
        <div className="sell">
          <Row>
            <Col lg="12">
              <Card className="shadow">
                <CardBody>
                  <h6 className="heading mb-4">Looking for something we don't have?<br/>Submit your request. We'll get back to you as soon as possible</h6>
                  <Row>
                    <Col lg="12">
                      <Form onSubmit={this.handleSubmit} className="form-with-label" id='addForm'>
                        <Row>
                          <Col sm="12" lg="12">
                            <FormGroup>
                              <Label for="product_name">Product Name</Label>
                              <Input name="product_name" id="product_name" placeholder="Product Name" type="text" onChange={this.handleProductName} className={''+(this.state.productNameHasDanger && 'is-invalid')} />
                            </FormGroup>
                          </Col>
                          <Col sm="12" lg="12">
                            <FormGroup>
                              <Label for="description">Request Description</Label>
                              <Input type="textarea" className="form-control" name="description" id="description" placeholder="Description"></Input>
                            </FormGroup>
                          </Col>
                          <Col sm="12" lg="6">
                            <FormGroup>
                              <Label for="images">Upload Files (pdf/jpg/png)</Label>
                              <Input type="file" onChange={this.handleImages} className="form-control" multiple />
                            </FormGroup>
                          </Col>
                          <Col sm="12" lg="6">
                            <FormGroup>
                              <Label for="file_url">Google Drive/OneDrive/Dropbox link</Label>
                              <Input name="file_url" id="file_url" placeholder="Google Drive/OneDrive/Dropbox File URL" type="text" />
                            </FormGroup>
                          </Col>
                          <Col sm="12" lg="6">
                            <FormGroup>
                              <Label for="name">Your Name</Label>
                              <Input name="name" placeholder="Name" type="text" className={''+(this.state.nameHasDanger && 'is-invalid')} onChange={this.handleName} />
                            </FormGroup>
                          </Col>
                          <Col sm="12" lg="6">
                            <FormGroup>
                              <Label for="contact">Your Contact</Label>
                              <Input name="contact" placeholder="Contact" type="text" className={''+(this.state.contactHasDanger && 'is-invalid')} onChange={this.handleContact} />
                            </FormGroup>
                          </Col>
                          <Col sm="6" lg="3">
                            <FormGroup>
                              <Label for="division">Division</Label>
                              <Input type="select" name="division" className={''+(this.state.divisionHasDanger && 'is-invalid')} onChange={this.district.bind(this)} defaultValue={this.state.division_id}>
                                <option value="0">Select Division</option>
                                {Array.from(this.state.divisions).map((item,index) =>
                                  <option value={item.id} key={index}>{item.name_eng}</option>
                                )}
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col sm="6" lg="3">
                            <FormGroup>
                              <Label for="district">District</Label>
                              <Input type="select" name="district" className={''+(this.state.districtHasDanger && 'is-invalid')} onChange={this.upazila.bind(this)} defaultValue={this.state.district_id}>
                                <option value="0">Select District</option>
                                {Array.from(this.state.districts).map((item,index) =>
                                  <option value={item.id} key={index}>{item.name_eng}</option>
                                )}
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col sm="12" lg="6">
                            <FormGroup>
                              <Label for="address">Delivery Address</Label>
                              <Input type="text" className="form-control" name="address" placeholder="Address"></Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <div className="text-center">
                          <Input type="hidden" name="cart" value={product.checkCart()} />
                          <Input type="hidden" name="user_request" value="1" />
                          <Input type="hidden" name="quantity" value="1" />
                          <Button className="my-4" color="primary" type="submit">Submit</Button>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default withRouter(UserRequestForm);