import React from "react";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
// core components
import Navbar from "components/Navbars/Navbar.jsx";
import Loading from "components/Navbars/Loading.jsx";
import Footer from "components/Footers/SimpleFooter.jsx";
// product page sections
import QuestionForm from "views/questions/sections/QuestionForm.jsx";
import QuestionList from "views/questions/sections/QuestionList.jsx";
// react redux
// import LoadingBar from 'react-redux-loading-bar';

class Questions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      newQuestions: [],
      suggestions: []
    };
    this.newQuestion = this.newQuestion.bind(this);
    this.suggestions = this.suggestions.bind(this);
  }
  isLoading = (loadingVal) => {
    this.setState({
      loading: loadingVal
    });
  }
  suggestions(suggestions){
    this.setState({
      suggestions: suggestions
    });
  }
  componentDidMount(){
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  newQuestion = (item) => {
    var items = this.state.newQuestions;
    items.unshift(item);
    this.setState({
      newQuestions: items
    });
  }
  render() {
    return (
      <>
        <Navbar />
        <main ref="main">
          <Container>
            <Row>
              <Col lg="2"></Col>
              <Col lg="8" className="comments">
                <h6 className="heading">Have any question? Ask here</h6>
                <QuestionForm newQuestion={this.newQuestion} suggestions={this.state.suggestions} />
                <QuestionList loading={this.isLoading} newQuestions={this.state.newQuestions} suggestions={this.suggestions} />
              </Col>
              <Col lg="2"></Col>
            </Row>
          </Container>
        </main>
        <Footer />
        {this.state.loading && <Loading /> }
      </>
    );
  }
}

export default Questions;