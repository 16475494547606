import React from "react";
// reactstrap components
import { Row, Col, Button, Input, Form, FormGroup } from "reactstrap";
import { withRouter } from "react-router";
import _ from 'lodash';
import $ from 'jquery';
import * as product from "./../../../assets/js/product.js";
import { Link } from "react-router-dom";
import FeatureLoadingCard from "components/Products/FeatureLoadingCard.jsx";

class FeatureValues extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prevPath : this.props.location.pathname,
      items : [],
      keyword: '',
      orders: ['A to Z','Popularity','Best Selling'],
      order: 'A to Z',
      loadingCount: 6,
      loadingProduct: 0
    };
    this.itemsCall = this.itemsCall.bind(this);
    this.filter = this.filter.bind(this);
    this.handleKeyWord = this.handleKeyWord.bind(this);
    this.handleOrder = this.handleOrder.bind(this);
  }
  componentDidMount(){
    if(this.props.location.pathname.split('/')[1]=='best-selling-feature'){
      this.setState(
        { order: 'Best Selling' },
        () => {
          this.itemsCall();
        }
      );
    }else{
      this.itemsCall();
    }
  }
  componentDidUpdate(){
    if(this.state.prevPath!==this.props.location.pathname){
      this.setState({
        prevPath: this.props.location.pathname,
      });
      this.itemsCall();
    }
  }
  itemsCall(loadingProduct=0){
    if(loadingProduct==1){
      this.setState({
        loadingProduct: 1
      });
    }else{
      this.props.loading(true);
    }
    let filter = {
      feature: this.props.match.params.feature,
      keyword: this.state.keyword,
      order: this.state.order,
    }
    fetch('https://admin.kolpobd.com/api/feature/'+JSON.stringify(filter))
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          items: result.feature_values,
          loadingProduct: 0
        });
        this.props.loading(false);
      },
      (error) => {
        
      }
    );
  }
  handleKeyWord(e){
    e.persist();
    if (!this.debouncedFn) {
      this.debouncedFn =  _.debounce(() => {
        this.setState(
          { keyword: e.target.value },
          () => {
            if($(window).width()>768){
              $('.filter-apply').click();
            }
          }
        );
      }, 400);
    }
    this.debouncedFn();
  }
  handleOrder(e){
    this.setState(
      {order: e.target.value},
      () => {
        if($(window).width()>768){
          $('.filter-apply').click();
        }
      }
    );    
  }
  filter(e){
    e.preventDefault();
    this.itemsCall();
    if($(window).width()<=768){
      $('.filter').fadeToggle();
    }
  }
  reset(e){
    this.setState(
      {keyword: this.state.keyword, order: 'A to Z'},
      () => {
        this.itemsCall();
        $('.filter').trigger('reset');
        window.scrollTo(0,0);
        if($(window).width()<=768){
          $('.filter').fadeToggle();
        }
      }
    );
  }
  getImageUrl(feature_image,feature_value_image){
    if(feature_value_image){
      return 'https://admin.kolpobd.com'+feature_value_image;
    }else{
      return 'https://admin.kolpobd.com'+feature_image;
    }
  }
  getCategoryName(category){
    if(category){
      var words = category.split(' ');
      var name = '';
      words.map(function(word,index){
        if(name==''){
          name = word.charAt(0).toUpperCase() + word.slice(1);
        }else{
          name = name.charAt(0).toUpperCase() + name.slice(1)+' '+word.charAt(0).toUpperCase() + word.slice(1);
        }
      });
      return name;
    }
  }
  render() {
    return (
      <>
        <section className="products">
          <Row>
            <Col md="12">
              <div className="product-hierarchy">
                <Link to={'/'}><i className="fa fa-home"></i></Link>
                <Link to={'/feature/'+this.props.match.params.feature}><i className="fa fa-arrow-right"></i>{this.getCategoryName(product.removeSlug(this.props.match.params.feature))}</Link>
              </div>
            </Col>
            <Col lg="2">
              <Form onSubmit={this.filter} className="filter" ref="filterForm">
                <div className="products-heading">
                  <span>Filter</span>
                </div>
                <FormGroup>
                  <label>By Keyword</label>
                  <Input name="keyword" className="form-control-alternative" placeholder="Keyword" type="text" defaultValue={this.state.keyword} onChange={this.handleKeyWord.bind()} />
                </FormGroup>
                <FormGroup>
                  <label>Order By</label>
                  <select className="form-control form-control-alternative" name="order" value={this.state.order} onChange={this.handleOrder.bind()}>
                    {this.state.orders.map((order,index)=>
                      <option key={index}>{order}</option>
                    )}
                  </select>
                </FormGroup>
                <FormGroup>
                  <Button color="danger" className="filter-reset pull-right" outline type="button" size="sm" onClick={()=>{this.reset()}}>Reset</Button>
                  <Button color="primary" className="filter-apply pull-right mr-1" outline type="submit" size="sm">Apply</Button>
                </FormGroup>
              </Form>
            </Col>

            <Col lg="10" className="cards feature-value-cards">
              <Row>
                {this.state.items && this.state.items.map((item) =>
                  <Col xs="6" md="4" lg="2" key={item.id}>
                    <Link to={'/feature/'+product.makeSlug(item.feature)+'/'+product.makeSlug(item.feature_value)}>
                      <div className="feature-value">
                        <img src={this.getImageUrl(item.feature_image,item.feature_value_image)} />
                        <span>{item.feature_value}</span>
                      </div>
                    </Link>
                  </Col>
                )}
                {this.state.loadingProduct==1 && <FeatureLoadingCard loadingCount={this.state.loadingCount} xs="6" md="4" lg="2" />}
              </Row>
            </Col>
          </Row>
        </section>
      </>
    );
  }
}

export default withRouter(FeatureValues);