import React from "react";
// reactstrap components
import { Row, Col } from "reactstrap";
import { withRouter } from "react-router";
import Collection from "components/Index/Collection.jsx";
import FeatureCollection from "components/Index/FeatureCollection.jsx";

class Collections extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collections : '',
      discounts: '',
      features: ''
    };
  }
  componentDidMount(){
    this.itemsCall();
  }
  itemsCall(){
    fetch('https://admin.kolpobd.com/api/site/collections')
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          collections: result.collections,
          discounts: result.discounts,
          features: result.features,
        });
      },
      (error) => {
        
      }
    );
  }
  check(item){
    console.log(item);
  }
  render() {
    return (
      <>
        <section className="collections container">
          <Row>
            <Col lg="12">
              {this.state.features && Array.from(this.state.features).map((feature,index) =>
                <FeatureCollection feature={feature} key={index} />
              )}
            </Col>
            <Col lg="12">
              {this.state.collections && Array.from(this.state.collections).map((collection,index) =>
                <Collection collection={collection} discounts={this.state.discounts} key={index} />
              )}
            </Col>
          </Row>
        </section>
      </>
    );
  }
}

export default withRouter(Collections);