import React from "react";
// reactstrap components
import { Row, Col, Button, Input, Form, FormGroup } from "reactstrap";
import { withRouter } from "react-router";
import $ from 'jquery';
import BlogCard from "components/Blog/BlogCard.jsx";

class Items extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prevPath : this.props.location.pathname,
      items : [],
      keyword: '',
    };
    this.itemsCall = this.itemsCall.bind(this);
    this.filter = this.filter.bind(this);
  }
  componentDidMount(){
    this.itemsCall();
  }
  componentDidUpdate(){
    if(this.state.prevPath!==this.props.location.pathname){
      this.setState({
        prevPath: this.props.location.pathname,
      });
      this.itemsCall();
    }
  }
  itemsCall(){
    this.props.loading(true);
    let filter = {
      keyword: this.state.keyword,
    }
    fetch('https://admin.kolpobd.com/api/blog/'+JSON.stringify(filter))
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          items: result.Items.data,
        });
        this.props.loading(false);
      },
      (error) => {
        
      }
    );
  }
  filter(e){
    e.preventDefault();
    let selectedFeatures = [];
    Array.from(e.target.features).map((feature)=>{
      if(feature.checked){
        selectedFeatures.push(feature.value);
      }
    });
    this.itemsCall(0,0,0,selectedFeatures);
  }
  reset(e){
    this.itemsCall(0,1,1);
    $('.filter').trigger('reset');
  }
  render() {
    return (
      <>
        <section className="products">
          <Row>
            <Col lg="2">
              <Form onSubmit={this.filter} className="filter" ref="filterForm">
                <div className="products-heading">
                  <span>Filter</span>
                </div>
                <FormGroup>
                  <label>Filter By Name</label>
                  <Input name="keyword" className="form-control-alternative" placeholder="Keyword" type="text" value={this.state.keyword} onChange={e => this.setState({keyword: e.target.value})} />
                </FormGroup>
                <FormGroup>
                  <Button color="danger" className="pull-right" outline type="button" size="sm" onClick={()=>{this.reset()}}>Reset</Button>
                  <Button color="primary" className="pull-right mr-1" outline type="submit" size="sm">Apply</Button>
                </FormGroup>
              </Form>
            </Col>

            <Col lg="10" className="cards">
              <Row>
                {this.state.items && this.state.items.map((item) =>
                  <Col md="4" lg="2" key={item.id}>
                    <BlogCard item={item} discounts={this.state.discounts} />
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </section>
      </>
    );
  }
}

export default withRouter(Items);