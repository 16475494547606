import React from "react";
import Moment from 'react-moment';
import Swal from 'sweetalert2';
import $ from 'jquery';
// reactstrap components
import { Media } from "reactstrap";

class ProductReview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }
  componentDidMount(){
    
  }
  getImageUrl(image){
    return 'https://admin.kolpobd.com'+image;
  }
  getRating(rating){
    var star = [];
    for(var i=0;i<rating;i++){
      if(i===0){
        star[i] = <i className="fa fa-star ml-2" />;
      }else{
        star[i] = <i className="fa fa-star" />;
      }
    }
    return star;
  }
  deleteReview(e){
    const element = e.target;
    Swal.fire({
      title: 'Are you sure?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2dce89',
      cancelButtonColor: '#ec0c38',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        fetch('https://admin.kolpobd.com/api/reviews/delete/'+this.props.review.product_id+'/'+this.props.review.id)
        .then(res => res.json())
        .then(
          (result) => {
            if(result==1){
              Swal.fire({
                text: 'Review Deleted',
                type: 'success',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 1500
              });
              $(element).parent().parent().parent().parent().hide();
            }else{
              Swal.fire({
                text: 'Something is wrong',
                type: 'error',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 1500
              });
            }
          },
          (error) => {
            Swal.fire({
              text: 'Something is wrong',
              type: 'error',
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              showCancelButton: false,
              timer: 1500
            });
          }
        );
      }
    });
  }
  render() {
    return (
      <>
        <div className="comment">
          <Media>
            <Media left href="#">
              <Media object src={this.getImageUrl(this.props.review.image)} alt="User Image" />
            </Media>
            <Media body>
              <Media heading>
                {this.props.review.name}
                {Array.from(this.getRating(this.props.review.rating)).map((star,index) =>
                  <span className="star-rating" key={index}>{star}</span>
                )}
                <small className="pull-right"><Moment format="D MMM, YYYY" date={this.props.review.created_at} /></small>
              </Media>
              {this.props.review.review}
              {localStorage.getItem('user_id')==this.props.review.created_by ? 
              <div className="actions">
                <a href="javascript:void(0)" onClick={this.deleteReview.bind(this)}>Delete</a>
              </div> : ''}
            </Media>
          </Media>
        </div>
      </>
    );
  }
}

export default ProductReview;