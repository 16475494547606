import React from "react";
// reactstrap components
import { Row, Col, Button, Input, Form, FormGroup } from "reactstrap";
import { withRouter } from "react-router";
import Question from "components/Questions/Question.jsx";

class QuestionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items : [],
      suggestions: []
    };
    this.itemsCall = this.itemsCall.bind(this);
  }
  componentDidMount(){
    this.itemsCall();
  }
  itemsCall(){
    this.props.loading(true);
    fetch('https://admin.kolpobd.com/api/questions')
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          items: result.questions.data,
          suggestions: result.suggestions
        });
        this.props.loading(false);
        this.props.suggestions(this.state.suggestions);
      },
      (error) => {
        
      }
    );
  }
  render() {
    return (
      <>
        {Array.from(this.props.newQuestions).map((item,index) =>
          <Question question={item} key={index} newReply={this.newReply} newReplies={this.state.newReplies} />
        )}
        {Array.from(this.state.items).map((item,index) =>
          <Question question={item} key={index} newReply={this.newReply} newReplies={this.state.newReplies} />
        )}
      </>
    );
  }
}

export default withRouter(QuestionList);