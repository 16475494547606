import React from "react";
// reactstrap components
import { Row, Col, Button, Input, Form, FormGroup } from "reactstrap";
import { withRouter } from "react-router";
import Slider from "nouislider";
import ScaleLoader from 'react-spinners/ScaleLoader';
import _ from 'lodash';
import $ from 'jquery';
import * as product from "./../../../assets/js/product.js";
import ProductCard from "components/Products/ProductCard.jsx";
import ProductLoadingCard from "components/Products/ProductLoadingCard.jsx";
import ProductCategory from "components/Products/ProductCategory.jsx";

class Products extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prevPath : this.props.location.pathname,
      items : [],
      priceMin: 0,
      priceMax: 9999999,
      rangeLow: 0,
      rangeHigh: 9999999,
      keyword: '',
      oldKeyword: '',
      features: [],
      categories: [],
      category: '',
      discounts: '',
      loadingProduct: 0,
      loadingCount: 6,
      page: 1,
      limit: 48,
      currentRequest: '',
      count: 0,
      hasMore: 1,
      oldSelectedFeatures: [],
      filterSpinning: 1,
      orders: ['Relevance','Popularity','Best Selling','Price (Low to High)','Price (High to Low)'],
      order: 'Relevance',
      abortController: '',
      feature: '',
      feature_value: '',
      feature_details: ''
    };
    this.itemsCall = this.itemsCall.bind(this);
    this.filter = this.filter.bind(this);
    this.handleKeyWord = this.handleKeyWord.bind(this);
    this.handleOrder = this.handleOrder.bind(this);
  }
  componentDidMount(){
    if(this.props.match.params.query){
      this.setState(
        { keyword: this.props.match.params.query },
        () => {
          this.itemsCall(1,1,0,[],1);
        }
      );
    }else{
      if(this.props.match.params.feature && this.props.match.params.feature_value){
        this.setState(
          { feature: this.props.match.params.feature, feature_value: this.props.match.params.feature_value },
          () => {
            this.itemsCall(1,1,0,[],1);
          }
        );
      }else{
        if(this.props.match.params.type && this.props.match.params.type=='products'){
          this.setState(
            { order: 'Best Selling' },
            () => {
              this.itemsCall(1,1,1,[],1);
            }
          );
        }
        this.itemsCall(1,1,1,[],1);
      }
    }
  }
  componentDidUpdate(){
    if(this.state.prevPath!==this.props.location.pathname){
      if(this.props.match.params.query){
        this.setState(
          {
            prevPath: this.props.location.pathname,
            page: 1,
            categories: [],
            keyword: this.props.match.params.query,
            feature: '',
            feature_value: '',
            feature_details: '',
          },
          () => {
            this.itemsCall(0,1,0,[],1);
            $('.filter').trigger('reset');
            window.scrollTo(0,0);
          }
        );
      }else{
        if(this.props.match.params.feature && this.props.match.params.feature_value){
          this.setState(
            {
              prevPath: this.props.location.pathname,
              page: 1,
              categories: [],
              feature: this.props.match.params.feature,
              feature_value: this.props.match.params.feature_value,
              feature_details: '',
            },
            () => {
              this.itemsCall(0,1,0,[],1);
              $('.filter').trigger('reset');
              window.scrollTo(0,0);
            }
          );
        }else{
          this.setState(
            {
              prevPath: this.props.location.pathname,
              page: 1,
              categories: [],
              feature: '',
              feature_value: '',
              feature_details: '',
            },
            () => {
              this.itemsCall(0,1,1,[],1);
              $('.filter').trigger('reset');
              window.scrollTo(0,0);
            }
          );
        }
      }
    }
  }
  itemsCall(initSlider,changeRangeHigh,resetRangeHigh,selectedFeatures=[],loading=0){
    if(loading==1){
      this.props.loading(true);
      this.setState({ features: [], priceMin: 0, priceMax: 9999999, rangeLow: 0, rangeHigh: 9999999, filterSpinning: 1 });
    }
    let filter = {
      category: this.props.match.params.category,
      sub_category: this.props.match.params.sub_category,
      sub_sub_category: this.props.match.params.sub_sub_category,
      sub_sub_sub_category: this.props.match.params.sub_sub_sub_category,
      sub_sub_sub_sub_category: this.props.match.params.sub_sub_sub_sub_category,
      sub_sub_sub_sub_sub_category: this.props.match.params.sub_sub_sub_sub_sub_category,
      keyword: this.state.keyword,
      feature: this.state.feature,
      feature_value: this.state.feature_value,
      order: this.state.order,
      rangeHigh: this.state.rangeHigh,
      rangeLow: this.state.rangeLow,
      selectedFeatures: selectedFeatures,
      page: this.state.page,
      limit: this.state.limit
    }
    if(resetRangeHigh==1){
      filter.rangeHigh = 9999999;
      filter.rangeLow = 0;
      filter.keyword = '';
      this.setState({ keyword: '', rangeLow: 0 });
    }
    if(this.state.page==1){
      this.setState({ items: [] });
    }
    this.setState({ loadingProduct: 1 });
    if(this.state.abortController){
      this.state.abortController.abort();
    }
    this.setState({ abortController: new AbortController() },() =>{
      fetch('https://admin.kolpobd.com/api/products/'+JSON.stringify(filter)+'?page='+this.state.page,{
        method: 'GET',
        signal: this.state.abortController.signal
      })
      .then(res => res.json())
      .then(
        (result) => {
          var items = this.state.items;
          items = Array.from(items).concat(result.products.data);
          this.setState({
            items: items,
            categories: result.categories,
            category: result.category,
            discounts: result.discounts,
            loadingProduct: 0,
            count: result.products.total,
            abortController: '',
            feature_details: result.feature_details
          });
          if(result.products.data.length<48){
            this.setState({ hasMore: 0 });
          }else{
            this.setState({ hasMore: 1 });
          }
          this.props.loading(false);
          if(loading==1){
            this.otherItemsCall(initSlider,changeRangeHigh);
          }
          // if(result.categories){
          //   if($(window).width()>768){
          //     let maxheight = 0;
          //     $('.categories .category-folder').each(function () {
          //         maxheight = ($(this).height() > maxheight ? $(this).height() : maxheight); 
          //     });
          //     $('.categories .category-folder').height(maxheight);
          //   }
          // }
        },
        (error) => {
          // this.setState({ loadingProduct: 0 });
        }
      );
    });
  }
  otherItemsCall(initSlider,changeRangeHigh){
    let filter = {
      category: this.props.match.params.category,
      sub_category: this.props.match.params.sub_category,
      sub_sub_category: this.props.match.params.sub_sub_category,
      sub_sub_sub_category: this.props.match.params.sub_sub_sub_category,
      sub_sub_sub_sub_category: this.props.match.params.sub_sub_sub_sub_category,
      sub_sub_sub_sub_sub_category: this.props.match.params.sub_sub_sub_sub_sub_category,
      query: this.props.match.params.query,
      feature: this.state.feature,
      feature_value: this.state.feature_value,
    }
    fetch('https://admin.kolpobd.com/api/products/others/'+JSON.stringify(filter))
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          priceMin: result.prices.min_price,
          features: result.features
        });
        if(initSlider==1){
          this.setState({ priceMax: result.prices.max_price, rangeHigh: result.prices.max_price });
          this.initSlider();
        }else{
          if(changeRangeHigh==1){
            this.setState({ priceMax: result.prices.max_price, rangeHigh: result.prices.max_price });
          }
          var slider2 = this.refs.slider2;
          slider2.noUiSlider.destroy();
          this.initSlider();
        }
        this.setState({ filterSpinning: 0 });
      },
      (error) => {
        console.log(error);
      }
    );
  }
  initSlider(){
    var slider2 = this.refs.slider2;
    Slider.create(slider2, {
      start: [this.state.rangeLow, this.state.rangeHigh],
      connect: [false, true, false],
      step: 10,
      range: { min: 0, max: parseInt(this.state.priceMax) },
    }).on(
      "update",
      function(values, handle) {
        if(this.state.rangeLow!=values[0] || this.state.rangeHigh!= values[1]){
          // if($(window).width()>768){
            this.setState(
              { rangeLow: values[0], rangeHigh: values[1] },
              () => {
                $('.filter-apply').click();
              }
            );
          // }else{
          //   this.setState({ rangeLow: values[0], rangeHigh: values[1] });
          // }
        }else{
          this.setState({ rangeLow: values[0], rangeHigh: values[1] });
        }
      }.bind(this)
    );
  }
  filter(e){
    e.preventDefault();
    let selectedFeatures = [];
    if(e.target.features){
      Array.from(e.target.features).map((feature)=>{
        if(feature.checked){
          selectedFeatures.push(feature.value);
        }
      });
    }
    if(JSON.stringify(selectedFeatures)==JSON.stringify(this.state.oldSelectedFeatures)){
      this.itemsCall(0,0,0,selectedFeatures,0);
    }else{
      this.setState(
        {page: 1, oldSelectedFeatures: selectedFeatures},
        () => {
          this.itemsCall(0,0,0,selectedFeatures,0);
        }
      );
    }
    // if($(window).width()<=768){
    //   $('.filter').fadeToggle();
    // }
  }
  reset(e){
    this.setState(
      {page: 1, order: 'Relevance', rangeHigh : 9999999, rangeLow : 0, keyword : this.props.match.params.query},
      () => {
        this.itemsCall(0,1,0,[],1);
      }
    );
    $('.filter').trigger('reset');
    window.scrollTo(0,0);
    // if($(window).width()<=768){
    //   $('.filter').fadeToggle();
    // }
  }
  collapseFilter(e){
    $('.filter').fadeToggle();
  }
  handleKeyWord(e){
    e.persist();
    if (!this.debouncedFn) {
      this.debouncedFn =  _.debounce(() => {
        this.setState(
          { keyword: e.target.value },
          () => {
            // if($(window).width()>768){
              $('.filter-apply').click();
            // }
          }
        );
      }, 400);
    }
    this.debouncedFn();
  }
  handleOrder(e){
    this.setState(
      {order: e.target.value},
      () => {
        // if($(window).width()>768){
          $('.filter-apply').click();
        // }
      }
    );    
  }
  filterOnClick(){
    // if($(window).width()>768){
      $('.filter-apply').click();
    // }
  }
  nextPage(){
    var page = this.state.page+1;
    this.setState(
      { page: page },
      () => {
        $('.filter-apply').click();
      }
    );
  }
  filterToggle(){
    if($(window).width()<=768){
      $('.filter').slideToggle();
    }
  }
  render() {
    return (
      <>
        <section className="products">
          <Row>
            {this.state.categories &&
              <Col lg="12">
                <ProductCategory category={this.state.category} categories={this.state.categories} count={this.state.count} />
              </Col>
            }
            <Col lg="2">
              {/* <div className="products-heading products-heading-mobile" onClick={this.collapseFilter.bind()}>
                <span><i className="fa fa-filter"></i></span>
              </div> */}
              <div className="products-heading" onClick={this.filterToggle}>
                <span><i className="fa fa-filter mr-1"></i>Filter</span>
              </div>
              <Form onSubmit={this.filter} className="filter" ref="filterForm" id="filterForm">
                {this.state.filterSpinning==1 &&
                <div className='form-groups sweet-loading-filter'>
                  <ScaleLoader css={{display: 'block',margin: 'auto'}} sizeUnit={"px"} height={'15'} color={'#525f7f'} loading={this.state.filterSpinning} />
                </div>}
                <div className={this.state.filterSpinning==1 ? 'form-groups d-n' : 'form-groups'}>
                  <FormGroup>
                    <label>By Keyword</label>
                    <Input name="keyword" className="" placeholder="Keyword" type="text" defaultValue={this.state.keyword} onChange={this.handleKeyWord.bind()} />
                  </FormGroup>
                  <FormGroup>
                    <label>Price Range</label>
                    <div className="price">
                      <div className="slider" ref="slider2" />
                      <Row>
                        <Col xs="6">
                          <span className="range-slider-value value-low">
                            {parseInt(this.state.rangeLow).toFixed()}
                          </span>
                        </Col>
                        <Col className="text-right" xs="6">
                          <span className="range-slider-value value-high">
                            {parseInt(this.state.rangeHigh).toFixed()}
                          </span>
                        </Col>
                      </Row>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <label>Order By</label>
                    <select className="form-control " name="order" value={this.state.order} onChange={this.handleOrder.bind()}>
                      {this.state.orders.map((order,index)=>
                        <option key={index}>{order}</option>
                      )}
                    </select>
                  </FormGroup>
                  {this.state.features && Object.keys(this.state.features).map((feature) =>
                    <FormGroup key={feature}>
                      <label>{feature}</label>
                      <div className="feature-item">
                      {this.state.features[feature].map((feature_value,index) =>
                        <div className="custom-control custom-checkbox mb-1" key={index}>
                          <input className="custom-control-input" id={feature_value.id} type="checkbox" name="features" value={feature_value.id} onChange={this.filterOnClick.bind()} />
                          <label className="custom-control-label" htmlFor={feature_value.id}>{feature_value.feature_value}</label>
                        </div>
                      )}
                      </div>
                    </FormGroup>
                  )}
                  <FormGroup>
                    <Button color="danger" className="filter-reset pull-right" outline type="button" size="sm" onClick={()=>{this.reset()}}>Reset</Button>
                    <Button color="primary" className="filter-apply pull-right mr-1" outline type="submit" size="sm">Apply</Button>
                  </FormGroup>
                </div>
              </Form>
            </Col>

            <Col lg="10" className="cards">
              <Row>
                {this.state.feature && this.state.feature_value &&
                  <Col md="12" className="author-col">
                    <div className="media author-media">
                      {this.state.feature_details && <img className="mr-3" src={product.makeImageUrl(this.state.feature_details.feature_image,this.state.feature_details.image)} />}
                      <div className="media-body">
                        <h6 className={!this.state.feature_details ? "m-0 capitalize" : (!this.state.feature_details.description && "mt-3 capitalize")}>{product.removeSlug(this.state.feature_value)}<small className="pl-1">({product.removeSlug(this.state.feature)})</small></h6>
                        {this.state.feature_details && <p className="p-0 m-0">{this.state.feature_details.description}</p>}
                      </div>
                    </div>
                  </Col>
                }
                <Col md="12">
                <div className="collection-header">
                  <h6>Products</h6>
                </div>
                  <Row>
                    {this.state.items && this.state.items.map((item) =>
                      <Col xs="6" md="4" lg="2" key={item.id}>
                        <ProductCard item={item} discounts={this.state.discounts} />
                      </Col>
                    )}
                    {this.state.loadingProduct==1 && <ProductLoadingCard loadingCount={this.state.loadingCount} xs="6" md="4" lg="2" />}
                  </Row>
                </Col>
                {this.state.hasMore==1 &&
                  <Col lg="12">
                    <Button color="primary" className="btn-load-more" type="button" onClick={this.nextPage.bind(this)}>Load more</Button>
                  </Col>
                }
              </Row>
            </Col>
          </Row>
        </section>
      </>
    );
  }
}

export default withRouter(Products);