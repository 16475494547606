import React from "react";
import Moment from 'react-moment';
import Swal from 'sweetalert2';
import $ from 'jquery';
// reactstrap components
import { Media, Form, Input, Button } from "reactstrap";
import ProductReply from "components/Products/ProductReply.jsx";

class ProductComment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: null,
      user_name: null,
      user_image: null,
      newReply: null
    };
  }
  componentDidMount(){
    this.setState({
      user_id: localStorage.getItem('user_id'),
      user_name: localStorage.getItem('user_name'),
      user_image: localStorage.getItem('user_image'),
    });
  }
  getImageUrl(image){
    return 'https://admin.kolpobd.com'+image;
  }
  toggleReply(e){
    $(e.target).parent().parent().find('.reply').slideToggle();
  }
  newReply(result){
    this.setState({
      newReply: result
    });
  }
  validate(reply){
    if(reply){
      var reply = 1;
    }else{
      var reply = 0;
      alert('Please write your reply');
      return 0;
    }
    if(localStorage.getItem('user_id')){
      var user_id = 1;
    }else{
      var user_id = 0;
      $('#loginModalBtn').click();
    }
    if(reply==1 && user_id==1){
      return 1;
    }
    return 0;
  }
  deleteComment(e){
    const element = e.target;
    Swal.fire({
      title: 'Are you sure?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2dce89',
      cancelButtonColor: '#ec0c38',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        fetch('https://admin.kolpobd.com/api/comments/delete/'+this.props.comment.id)
        .then(res => res.json())
        .then(
          (result) => {
            if(result==1){
              Swal.fire({
                text: 'Comment Deleted',
                type: 'success',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 1500
              });
              $(element).parent().parent().parent().parent().hide();
            }else{
              Swal.fire({
                text: 'Something is wrong',
                type: 'error',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 1500
              });
            }
          },
          (error) => {
            Swal.fire({
              text: 'Something is wrong',
              type: 'error',
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              showCancelButton: false,
              timer: 1500
            });
          }
        );
      }
    });
  }
  handleSubmit(e){
    e.preventDefault();
    const data = new FormData(e.target);
    var valid = this.validate(e.target.reply.value);
    var success = 0;
    if(valid==1){
      fetch('https://admin.kolpobd.com/api/replies/store', {
        method: 'POST',
        body: data,
      }).then(res => res.json())
      .then(
        (result) => {
          if(result==0){
            alert('Something is wrong. Please try again');
          }else{
            Swal.fire({
              text: 'Reply Added',
              type: 'success',
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              showCancelButton: false,
              timer: 1500
            });
            this.newReply(result);
            success = 1;
          }
        },
        (error) => {
          alert('Something is wrong. Please try again');
        }
      );
      if(success==1){
        $(e.target).trigger('reset');
      }
    }
  }
  render() {
    return (
      <>
        <div className="comment">
          <Media>
            <Media left href="#">
              <Media object src={this.getImageUrl(this.props.comment.image)} alt="User Image" />
            </Media>
            <Media body>
              <Media heading>
                {this.props.comment.name} <small className="pull-right"><Moment format="D MMM, YYYY" date={this.props.comment.created_at} /></small>
              </Media>
              {this.props.comment.comment}
              <div className="actions">
                <a href="javascript:void(0)" onClick={this.toggleReply.bind(this)}>Reply</a>
                {localStorage.getItem('user_id')==this.props.comment.created_by ? 
                <a className="ml-2" href="javascript:void(0)" onClick={this.deleteComment.bind(this)}>Delete</a> : ''}
              </div>
              {this.props.comment.replies.map((reply,index) =>
                <ProductReply reply={reply} key={index} />
              )}
              {this.state.newReply && <ProductReply reply={this.state.newReply} />}
              <div className={"comment reply" + (this.state.user_id ? '' : ' reply-form')}>
                <Form onSubmit={this.handleSubmit.bind(this)}>
                  <Media>
                    <Media left href="#">
                      {this.state.user_image && <Media object src={this.getImageUrl(this.state.user_image)} alt="User Image" />}
                    </Media>
                    <Media body>
                      <Media heading>
                        {this.state.user_name && this.state.user_name }
                      </Media>
                      <Input type="textarea" name="reply" placeholder="Write your reply" />
                      <Input type="hidden" name="comment_id" defaultValue={this.props.comment.id} />
                      <Input type="hidden" name="created_by" defaultValue={this.state.user_id} />
                      <Button className="btn-sm pull-right mt-1" color="primary" type="submit"><span className="btn-inner--text">Reply</span></Button>
                    </Media>
                  </Media>
                </Form>
              </div>
            </Media>
          </Media>
        </div>
      </>
    );
  }
}

export default ProductComment;