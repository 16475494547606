import React from "react";
// reactstrap components
// core components
import Navbar from "components/Navbars/Navbar.jsx";
import Loading from "components/Navbars/Loading.jsx";
import Footer from "components/Footers/SimpleFooter.jsx";
// category page sections
import Items from "views/blog/sections/Items.jsx";
// react redux
// import LoadingBar from 'react-redux-loading-bar';

class Blog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }
  isLoading = (loadingVal) => {
    this.setState({
      loading: loadingVal
    });
  }
  componentDidMount(){
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Navbar />
        <main ref="main">
          <Items loading={this.isLoading} />
        </main>
        <Footer />
        {this.state.loading && <Loading /> }
      </>
    );
  }
}

export default Blog;